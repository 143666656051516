import React from 'react';

import { ICard } from 'src/cards/model/card.types';
import { MoneyView } from 'src/components/MoneyView';
import { Link } from 'src/paths';
import { Icon } from 'src/ui-kit/Icon';
import { Icons } from 'src/ui-kit/Icons';

interface IProps {
    card: ICard;
}

export const AccountWidget = ({ card }: IProps) => {
    return (
        <div className="card-block bg-primary">
            <div className="card-main">
                <div className="card-button dropdown">
                    <button
                        type="button"
                        className="btn btn-link btn-icon"
                        data-toggle="dropdown"
                    >
                        <Icon
                            icon={Icons.cog}
                            size="big"
                            color="--white"
                        />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                        <Link
                            to="/"
                            className="dropdown-item"
                        >
                            <Icon
                                icon={Icons.edit}
                                className="md hydrated"
                            />
                            Edit
                        </Link>
                        <Link
                            to="/"
                            className="dropdown-item"
                        >
                            <Icon
                                icon={Icons.closeOutline}
                                className="md hydrated"
                            />
                            Remove
                        </Link>
                        <Link
                            to="/"
                            className="dropdown-item"
                        >
                            <Icon
                                icon={Icons.arrowUpCircleOutline}
                                className="md hydrated"
                            />
                            Upgrade
                        </Link>
                    </div>
                </div>
                <div className="balance">
                    <span className="label">Баланс</span>
                    <h1 className="title">
                        <MoneyView money={card.balance} />
                    </h1>
                </div>
                <div className="in">
                    <div className="card-number">
                        <span className="label">Номер карты</span>
                        •••• •••• •••• 5555{/*{account.cardNumber}*/}
                    </div>
                    <div className="bottom">
                        <div className="card-expiry">
                            <span className="label">Название</span>
                            {card.name}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
