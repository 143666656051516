import { dateTime, relativeDateTime } from 'src/common/date';
import { TimeStamp } from 'src/libs/time';

export function RelativeDateTime({
    children,
    baseTime,
}: {
    children: TimeStamp;
    baseTime?: TimeStamp | Date;
}): JSX.Element {
    const time = dateTime(children);
    const relative = relativeDateTime(children, baseTime);

    if (!time) {
        return <i>(нет даты)</i>;
    }

    return <span title={time}>{relative}</span>;
}
