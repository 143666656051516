import { TimeStamp } from 'src/libs/time';
import { Money } from 'src/models/money';
import { SuiteID } from 'src/suite/types/suite.types';
import { ObjectID } from 'src/utils/object-id/object-id.types';

export type BillID = ObjectID<'BillID'>;

export enum BillStatus {
    /** Чек только создан, но еще не запрошен. Состояние по умолчанию */
    Created = 'created',
    /** Данные запрошены, но еще не получены */
    Pending = 'pending',
    /** Данные запрошены и такого чека не найдено */
    NotFound = 'not-found',
    /** Данные чека успешно получены и раскодированы */
    Found = 'found',
    /** Данные получены, но их не удалось раскодировать */
    InvalidData = 'invalid-data',
    /** Не удалось запросить данные */
    FailedToFetch = 'failed-to-fetch',
}

export enum BillOperationType {
    /** получение средств от покупателя (клиента) "приход" */
    Приход = 1,
    /** возврат покупателю (клиенту) средств, полученных от него */
    ВозвратПрихода = 2,
    /**  "возврат прихода", выдача средств покупателю (клиенту) "расход" */
    Расход = 3,
    /**  получение средств от покупателя (клиента), выданных ему, "возврат расхода" */
    ВозвратРасхода = 4,
}

export interface IBill {
    id: BillID;
    status: BillStatus;
    error?: {
        lastTry: TimeStamp;
        message?: string;
    };
    suiteId: SuiteID;
    /** @deprecated use rawQrCode */
    raw?: string;
    rawQrCode: string;
    /** @deprecated use normalizedQrCode */
    normalized?: string;
    normalizedQrCode: string;

    /** @deprecated use operationType */
    type?: BillOperationType;
    operationType: BillOperationType;
    /** @deprecated use dateTime */
    ts?: TimeStamp;
    dateTime: TimeStamp;
    /** @deprecated use totalSum */
    amount?: Money;
    totalSum: Money;

    details: IBillDetails;
    items: IBillItem[];

    rawData?: IProverkaChekaResponse;
}

export interface IBillDetails {
    /** Организация */
    orgName: string; // user
    /** ИНН организации */
    orgINN: string; // userInn
    /** Название магазина */
    retailPlace: string;
    /** Адрес магазина */
    retailPlaceAddress: string;
    /** Кассир */
    operator: string; // operator
    /** Смена */
    shiftNumber: number; // shiftNumber
    /** Чек */
    requestNumber: number;

    /** Сумма наличными */
    cashTotalSum: Money;
    /** Сумма безналом */
    ecashTotalSum: Money;
    /** НДС итога чека со ставкой 20% */
    nds20?: Money; // nds18
    /** НДС итога чека со ставкой 10% */
    nds10?: Money; // nds
    /** НДС итога чека со ставкой 0% */
    nds0?: Money; // nds0
    /** НДС не облагается */
    noNds?: Money; // ndsNo

    /** Регистрационный номер контрольно-кассового терминала */
    kktRegId: string;
    /** ФН, Фискальный Накопитель */
    fiscalDriveNumber: string;
    /** ФД, Фискальный Документ */
    fiscalDocumentNumber: number;
    /** ФПД, Фискальный Признак Документа */
    fiscalSign: number;
}

export interface IBillItem {
    /** Наименование товара */
    name: string;
    /** Стоимость единицы товара */
    price: Money;
    /** Количество товара */
    quantity: number;
    /** Сумма */
    sum: Money;
}

/**
 * Содержимое qr-code
 *
 * https://base.garant.ru/71640610/f7ee959fd36b5699076b35abf4f52c5c/
 * п. 62
 */
export interface IQrCodePayload {
    /** Дата и время осуществления расчета в формате ГГГГММДДТЧЧММ */
    t: string;

    /** Сумма расчета в рублях и копейках, разделенных точкой */
    s: string;

    /** Заводской номер фискального накопителя */
    fn: string;

    /** Порядковый номер фискального документа, нулями не дополняется */
    i: string;

    /** Фискальный признак документа, нулями не дополняется */
    fp: string;

    /** Признак расчета */
    n: string;
}

export interface IProverkaChekaResponse {
    code: number;
    data: {
        json: object;
    };
}
