import { ChangeEvent } from 'react';

import { useField } from '@flatom/forms-react';

import { BaseField, IBaseFieldProps } from './BaseField';

export const DateField = (props: IBaseFieldProps) => {
    const { name } = props;
    const field = useField(name);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => field.handleChange(e.target.value);

    return (
        <BaseField {...props}>
            <input
                id={field.path}
                name={field.path}
                value={field.value}
                onChange={onChange}
                onFocus={field.handleFocus}
                onBlur={field.handleBlur}
                disabled={!field.enabled}
                type="date"
            />
        </BaseField>
    );
};
