import { RouteProps } from 'react-router';

import { CategoriesCreatePage } from 'src/categories/pages/Create';
import { CategoriesEditPage } from 'src/categories/pages/Edit';
import { CategoriesListPage } from 'src/categories/pages/List';
import { CategoriesShowPage } from 'src/categories/pages/Show';
import { CategoriesViewPage } from 'src/categories/pages/View';
import { categories } from 'src/paths/categories';

export const categoriesRoutes: RouteProps[] = [
    {
        path: categories.list(),
        exact: true,
        component: CategoriesListPage,
    },
    {
        path: categories.edit(),
        exact: true,
        component: CategoriesEditPage,
    },
    {
        path: categories.create(),
        exact: true,
        component: CategoriesCreatePage,
    },
    {
        path: categories.show(),
        exact: true,
        component: CategoriesShowPage,
    },
    {
        path: categories.view(),
        exact: true,
        component: CategoriesViewPage,
    },
    {
        path: categories.view(),
        exact: true,
        component: CategoriesViewPage,
    },
];
