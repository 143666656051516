import { TimeStamp } from 'src/libs/time';
import { Money } from 'src/models/money';
import { SuiteID } from 'src/suite';
import { DTO } from 'src/utils/dto';

import { BillID, BillStatus, IBill } from '../types';

export function decodeBillDto(dto: DTO<IBill>): IBill {
    const rawQrCode = dto.rawQrCode || dto.raw;
    const normalizedQrCode = dto.normalizedQrCode || dto.normalized;
    const dateTime = (dto.dateTime || dto.ts) as TimeStamp;
    const totalSum = Money.fromJSON(dto.totalSum || dto.amount);

    return {
        id: dto.id as BillID,
        status: dto.status as BillStatus,
        suiteId: dto.suiteId as SuiteID,
        raw: rawQrCode,
        rawQrCode,
        normalized: normalizedQrCode,
        normalizedQrCode,
        type: dto.operationType || dto.type,
        operationType: dto.operationType || dto.type,
        ts: dateTime,
        dateTime,
        amount: totalSum,
        totalSum,
        details: {
            ...dto.details,
            cashTotalSum: Money.fromJSON(dto.details?.cashTotalSum),
            ecashTotalSum: Money.fromJSON(dto.details?.ecashTotalSum),
            nds20: Money.fromJSON(dto.details?.nds20),
            nds10: Money.fromJSON(dto.details?.nds10),
            nds0: Money.fromJSON(dto.details?.nds0),
            noNds: Money.fromJSON(dto.details?.noNds),
        },
        items: dto.items.map((item) => ({
            name: item.name,
            price: Money.fromJSON(item.price),
            quantity: item.quantity,
            sum: Money.fromJSON(item.sum),
        })),
        rawData: dto.rawData,
    };
}

export function encodeBillDto(bill: IBill): DTO<IBill> {
    return {
        id: bill.id,
        status: bill.status,
        suiteId: bill.suiteId,
        raw: bill.rawQrCode,
        rawQrCode: bill.rawQrCode,
        normalized: bill.normalizedQrCode,
        normalizedQrCode: bill.normalizedQrCode,
        type: bill.operationType,
        operationType: bill.operationType,
        ts: bill.dateTime,
        dateTime: bill.dateTime,
        amount: bill.totalSum.toJSON(),
        totalSum: bill.totalSum.toJSON(),
        details: {
            ...bill.details,
            cashTotalSum: bill.details?.cashTotalSum.toJSON(),
            ecashTotalSum: bill.details?.ecashTotalSum.toJSON(),
            nds20: bill.details?.nds20?.toJSON(),
            nds10: bill.details?.nds10?.toJSON(),
            nds0: bill.details?.nds0?.toJSON(),
            noNds: bill.details?.noNds?.toJSON(),
        },
        items: bill.items.map((item) => ({
            name: item.name,
            price: item.price.toJSON(),
            quantity: item.quantity,
            sum: item.sum.toJSON(),
        })),
        rawData: bill.rawData,
    };
}
