import { createToken } from '@flatom/core';

export const objectIDServiceToken = createToken<IObjectIdService>('ObjectIDService');

/**
 * Unique identifier
 *
 * Hex string with delimiters like "61c1a-720b6-0de1-25b4" where:
 * 10 # timestamp in 1/256 seconds
 * 1  # version
 * 7  # incrementing counter, initialized to a random value
 */
export type ObjectID<T extends string = string> = string & { __brand: 'ObjectID'; __type: T };

export interface IObjectIdService {
    generate<T extends ObjectID = ObjectID>(): T;
}
