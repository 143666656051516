import { declareEffects } from '@flatom/core';

import { Storage } from './storage.atom';
import { storageToken } from './storage.types';

export const storageEffects = declareEffects('storageEffect:', {
    async getEstimate({ getService, dispatch }, _: void): Promise<void> {
        const service = getService(storageToken);

        const estimate = await service.getEstimate();

        dispatch(Storage.a.setEstimate(estimate));
    },
});
