import dateFnsFormat from 'date-fns/format';
import ru from 'date-fns/locale/ru';

import { TimeStamp } from 'src/libs/time';

const formats = {
    numberDateTime: 'dd.MM.yyyy HH:mm',
    standardDateTime: 'dd MMMM yyyy HH:mm',
};

export type DateTimeFormat = keyof typeof formats;

export function dateTime(timestamp: TimeStamp, format?: DateTimeFormat): string {
    if (!timestamp) {
        return '';
    }

    const formatString = formats[format] || formats['standardDateTime'];

    return dateFnsFormat(new Date(timestamp * 1000), formatString, { locale: ru });
}
