import { createStore } from '@flatom/core';
import { connectReduxDevtools } from '@flatom/debug';

import { AccountGrips } from 'src/atoms/account-grips/account-grips.atom';
import { Accounts } from 'src/atoms/accounts/accounts.atom';
import { BackupService } from 'src/atoms/backup/backup.service';
import { backupToken } from 'src/atoms/backup/backup.types';
import { Client } from 'src/atoms/client/client.atom';
import { MonthGrips } from 'src/atoms/month-grips/month-grips.atom';
import { Months } from 'src/atoms/months/months.atom';
import { billsServiceToken } from 'src/bills/model/bills.service';
import { BillsService } from 'src/bills/services/bills.service';
import { CardsAtom } from 'src/cards/model/card.atom';
import { cardsCacheServiceToken } from 'src/cards/model/cards-cache.service';
import { cardsServiceToken } from 'src/cards/model/cards.service';
import { CategoriesAtom } from 'src/categories/model/categories.atom';
import { categoriesServiceToken } from 'src/categories/model/categories.service';
import { CategoriesService } from 'src/categories/services/categories.service';
import { db } from 'src/db';
import { CardsCacheService } from 'src/services/cards/cards-cache.service';
import { CardsService } from 'src/services/cards/cards.service';
import { ObjectIdService } from 'src/utils/object-id/object-id.service';
import { objectIDServiceToken } from 'src/utils/object-id/object-id.types';

import { StorageService, storageToken } from '../pages/main-menu/storage';

import { history, historyToken } from './history';

export const store = createStore();

store.subscribe(Client, () => null);
store.subscribe(CardsAtom, () => null);
store.subscribe(CategoriesAtom, () => null);

store.subscribe(Accounts, () => null);
store.subscribe(Months, () => null);
store.subscribe(AccountGrips, () => null);
store.subscribe(MonthGrips, () => null);

connectReduxDevtools(store);

store.resolver.set(historyToken, history);
store.resolver.set(backupToken, new BackupService(db));
store.resolver.set(storageToken, new StorageService());
store.resolver.set(cardsServiceToken, new CardsService());
store.resolver.set(cardsCacheServiceToken, new CardsCacheService());
store.resolver.set(categoriesServiceToken, new CategoriesService());
store.resolver.set(billsServiceToken, new BillsService());
store.resolver.set(objectIDServiceToken, new ObjectIdService());
