import { Atom, AtomWithActionCreators, declareAction, declareAtom, isActionCreator } from '@flatom/core';
import { Reducers } from '@flatom/core/dist/atom.types';

export function declareMetaAtom<TState extends { id: string }, TActions = Record<string, unknown>>(
    atom: AtomWithActionCreators<TState, TActions>,
) {
    return <TMetaActions = Record<string, unknown>>(
        metaActions?: Reducers<TState, TMetaActions>,
    ): AtomWithActionCreators<Record<TState['id'], TState>, TActions & TMetaActions> => {
        const result = declareAtom<Record<TState['id'], TState>>(atom.key, {} as Record<TState['id'], TState>)((on) => {
            atom.relatedAtoms.forEach(processTarget(atom, on));
            atom.discoveredActions.forEach(processTarget(atom, on));
        }, metaActions as any);

        result.a = result.actions = { ...atom.actions, ...result.actions };

        return result as any;
    };
}

function processTarget(atom, on) {
    return (target: Atom<any> | string) => {
        if (typeof target === 'string') target = declareAction(target) as any;

        return on(target, (metaState, payload) => reducer(metaState, payload, atom, target));
    };
}

function reducer(metaState, payload, atom, target) {
    const newMetaState = { ...metaState };
    let isChanged = false;

    if (isActionCreator(target)) target = target.getType();

    if (payload && payload.id) {
        const key = payload.id;
        const state = atom(newMetaState[key] || { id: payload.id }, {
            type: target,
            payload,
        });

        if (newMetaState[key] !== state) {
            newMetaState[key] = state;
            isChanged = true;
        }
    } else {
        Object.keys(newMetaState).forEach((key) => {
            const state = atom(newMetaState[key] || { id: payload.id }, {
                type: target,
                payload,
            });

            if (newMetaState[key] !== state) {
                newMetaState[key] = state;
                isChanged = true;
            }
        });
    }

    return isChanged ? newMetaState : metaState;
}
