import format from 'date-fns/format';
import { ru } from 'date-fns/locale';

import { DayDate, MonthDate } from 'src/domain/date.types';

export function getMonthName(month?: MonthDate): string {
    if (month) return format(new Date(month), 'MMMM');
    else return 'month';
}

export function getDayDate(date: Date = new Date()): DayDate {
    return (date.getFullYear() +
        '-' +
        (date.getMonth() < 9 ? '0' : '') +
        (date.getMonth() + 1) +
        '-' +
        (date.getDate() <= 9 ? '0' : '') +
        date.getDate()) as DayDate;
}

export function formatDateMonthYear(month: MonthDate | DayDate): string {
    return format(new Date(month), 'LLLL yyyy', { locale: ru });
}

export function formatDateDayMonth(date: DayDate): string {
    return format(new Date(date), 'd MMMM', { locale: ru });
}

export function formatDateDayLong(date: DayDate): string {
    return format(new Date(date), 'PPP', { locale: ru });
}
