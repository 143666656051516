import { declareAtom } from '@flatom/core';
import { assoc } from 'sprout-data';

import { CardListAtom } from 'src/cards/model/card.atom';
import { CardID, ICard } from 'src/cards/model/card.types';

export interface IAccountsPageState {
    props: {
        account?: string;
    };
    currentID?: CardID;
    current?: ICard;
    cards: ICard[];
}

export const CurrentPageAtom = declareAtom<IAccountsPageState>('cards/pages/current', {
    props: {},
    currentID: undefined,
    current: undefined,
    cards: [],
})(
    {
        init(state, payload: { account: string }) {
            return calculateCurrent(assoc(state, 'props', payload));
        },
        propsChange(state, payload: { account: string }) {
            return calculateCurrent(assoc(state, 'props', payload));
        },
        destroy(state, _: void) {
            return state;
        },
    },
    (on) => [
        on(CardListAtom, (state, cards) => {
            return calculateCurrent({
                ...state,
                cards,
            });
        }),
    ],
);

function calculateCurrent(state: IAccountsPageState): IAccountsPageState {
    if (!state.cards.length) {
        return assoc(state, ['currentID'], undefined);
    }

    if (state.currentID !== state.props.account) {
        const currentID = (state.props.account as CardID) || state.currentID;
        let current = state.cards.find((item) => item.id === currentID);

        if (!current) {
            current = state.cards[0];
        }

        if (current) {
            state = {
                ...state,
                current,
                currentID: current.id,
            };
        }
    }

    return state;
}
