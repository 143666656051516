import { BlockedError, VersionError } from './errors';
import { promisify } from './promisify';
import { IScheme, MigrationWithVersion } from './scheme';

export interface MigrationQueueItem {
    version: number;
    migrations: MigrationWithVersion[];
}

export async function openDB(dbName: string, schemes: ReadonlyArray<IScheme>): Promise<IDBDatabase> {
    const currentVersion = await getCurrentVersion(dbName);

    const { migrations, targetVersion } = buildMigrationQueue(schemes, currentVersion);

    if (targetVersion < currentVersion) {
        throw new VersionError(targetVersion, currentVersion);
    }

    if (targetVersion > currentVersion) {
        for (const migration of migrations) {
            if (migration.version <= currentVersion) continue;

            console.log('apply ' + migration.version);

            try {
                await applyMigration(dbName, schemes, migration);
            } catch (error) {
                console.error(error);

                if (!(error instanceof VersionError)) {
                    throw error;
                }
            }

            console.log('complete apply ' + migration.version);
        }

        if (!migrations.length) {
            await applyMigration(dbName, schemes, { migrations: [], version: targetVersion });
        }
    }

    return promisify(window.indexedDB.open(dbName, targetVersion), {
        success: ({ resolve, event }) => {
            resolve(event.target.result);
        },
        upgradeneeded: ({ reject }) => reject(new Error('Can not upgrade after apply migrations')),
        error: ({ event, reject }) => {
            if (event.target.error && event.target.error.name === 'VersionError') {
                return reject(new VersionError(event.target.error));
            }

            reject(event.target.error);
        },
    });
}

/**
 * Применение миграций одной версии к базе данных
 * @param dbName
 * @param schemes
 * @param migration
 */
async function applyMigration(
    dbName: string,
    schemes: ReadonlyArray<IScheme>,
    migration: MigrationQueueItem,
): Promise<void> {
    const req = window.indexedDB.open(dbName, migration.version);

    // debugger;
    return promisify(req, {
        success: ({ event, resolve }) => {
            // debugger;
            event.target.result.close();
            resolve(void 0);
        },
        upgradeneeded: ({ event, resolve, reject }) => {
            // debugger;
            const db = event.target.result as IDBDatabase;
            const tx = event.target.transaction as IDBTransaction;

            updateSchemes(db, schemes);

            resolve(
                Promise.all(
                    migration.migrations.map((item) => {
                        console.log(`[db scheme] execute ${item.version} on ${item.collection}`);
                        console.log('TX mode', tx.mode);

                        return item(item.collection && tx.objectStore(item.collection), tx, db);
                    }),
                ).finally(() => {
                    console.log('close db');
                    db.close();
                }),
            );
        },
        error: ({ event, reject, resolve }) => {
            // debugger;
            if (event.target.error && event.target.error.name === 'VersionError') {
                return reject(new VersionError(event.target.error));
            }

            reject(event.target.error);
        },
        blocked: ({ event, reject }) => {
            console.log('blocked readyStatus ');
            // debugger;
            reject(new BlockedError());
        },
    });
}

/**
 * Обновление структуры сторов
 * @param db
 * @param schemes
 */
function updateSchemes(db: IDBDatabase, schemes: ReadonlyArray<IScheme>): string[] {
    const objectStoreNames = Array.from(db.objectStoreNames);

    return schemes.map((scheme) => {
        if (scheme.collection && !objectStoreNames.includes(scheme.collection)) {
            db.createObjectStore(scheme.collection, { keyPath: scheme.keyPath, autoIncrement: scheme.autoIncrement });
        }

        return scheme.collection;
    });
}

/**
 * Подготовка очереди миграций
 * @param schemes
 * @param currentVersion
 */
export function buildMigrationQueue(
    schemes: ReadonlyArray<IScheme>,
    currentVersion: number,
): {
    migrations: MigrationQueueItem[];
    targetVersion: number;
} {
    const migrations = schemes.flatMap((scheme) => scheme.migrations);

    migrations.sort((a, b) => a.version - b.version);

    const queue: MigrationQueueItem[] = [];
    let lastQueueItem: MigrationQueueItem = {
        migrations: [],
        version: 0,
    };

    for (const migration of migrations) {
        if (migration.version !== lastQueueItem.version) {
            lastQueueItem = {
                version: migration.version,
                migrations: [migration],
            };
            queue.push(lastQueueItem);
        } else {
            lastQueueItem.migrations.push(migration);
        }
    }

    return { migrations: queue, targetVersion: lastQueueItem.version || 1 };
}

/**
 * Возвращает текущую версию БД. Т.к. используемый метод есть не везде,
 * то если метод недоступен то возвращаем 0
 * @param dbName
 * @return версия БД. 0 если базы нет или невозможно получить в этом браузере
 */
function getCurrentVersion(dbName: string): Promise<number> {
    const indexedDB = window.indexedDB as { databases?(): Promise<{ name: string; version: number }[]> };

    if ('databases' in indexedDB) {
        return indexedDB
            .databases()
            .then((list) => list.find((item) => item.name === dbName))
            .then((db) => (db ? db.version : 0));
    } else {
        return Promise.resolve(0);
    }
}
