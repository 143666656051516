import { declareAction } from '@flatom/core';

import { UUID } from 'src/models/common/common.types';
import { MonthLegacy } from 'src/models/month/month-legacy.class';

const NS = 'months';

export const loadMonths = declareAction<UUID[]>(NS + ':loadMonths');
export const loadMonthsSuccess = declareAction<MonthLegacy[]>(NS + ':loadMonths success');
export const loadMonthsFailed = declareAction<{ ids: UUID[]; error: any }>(NS + ':loadMonths failed');

export const saveMonths = declareAction<MonthLegacy[]>(NS + ':saveMonths');
export const saveMonthsSuccess = declareAction<UUID[]>(NS + ':saveMonths success');
export const saveMonthsFailed = declareAction<{ ids: UUID[]; error: any }>(NS + ':saveMonths failed');
