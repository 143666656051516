import { declareAtom } from '@flatom/core';
import { Map } from 'immutable';

import { loadMonthsSuccess, saveMonths } from './months.actions';
import { IMonthsState } from './months.types';

export const Months = declareAtom<IMonthsState>(
    ['months'],
    Map(),
)((on) => ({
    load: [
        on(loadMonthsSuccess, (months, items) => {
            return items.reduce((acc, item) => acc.set(item.id, item), months);
        }),
    ],
    save: [
        on(saveMonths, (state, months) => {
            return months.reduce((acc, month) => acc.set(month.id, month), state);
        }),
    ],
}));
