import { declareEffect } from '@flatom/core';
import { format } from 'date-fns/esm';

import { DialogAtom } from 'src/dialog/model/dialog.atom';
import { openConfirm } from 'src/dialog/model/dialog.effects';

import { Backup } from './backup.atom';
import { backupToken } from './backup.types';

const NS = 'backupEffect:';

export const runBackup = declareEffect<void>(NS + 'runBackup', async ({ dispatch, getService }) => {
    const backupService = getService(backupToken);
    const date = format(new Date(), 'yyyy-MM-dd');

    dispatch(Backup.a.runBackup(date));

    try {
        const data = await backupService.createBackupFileContent();
        const backup = new Blob([data], { type: 'application/4wallet+octet-stream' });
        const content = URL.createObjectURL(backup);

        await new Promise((resolve) => setTimeout(resolve, 500));

        dispatch(
            Backup.a.backupReady({
                content,
                size: backup.size,
            }),
        );
    } catch (error) {
        dispatch(Backup.a.loaded());
        dispatch(
            DialogAtom.a.warning({
                title: 'Ошибка при создании резервной копии',
            }),
        );
    }
});

export const restoreFromFile = declareEffect<File>(NS + ':restoreFromFile', async ({ getService, dispatch }, file) => {
    const backup = getService(backupToken);
    const confirm = await dispatch(
        openConfirm({
            title: 'Восстановление резервной копии',
            text: 'Все текущие данные будут безвозвратно удалены',
        }),
    );

    if (!confirm) return;

    const content = await file.arrayBuffer();

    await backup.restoreBackupFromJson(new Uint8Array(content));

    window.location.reload();
});
