import React, { useCallback } from 'react';

import { useHistory } from 'react-router';

import { useViewAtom } from 'src/libs/flatom/view-atom';
import { paths } from 'src/paths';
import { LoaderBlock } from 'src/ui-kit/LoaderBlock';

import { CurrentPageAtom } from './current.atom';

const KEY = 'current-account';

export const CurrentPage = () => {
    const history = useHistory();
    const params: { account?: string } = {
        account: localStorage.getItem(KEY) || '',
    };
    const { currentID } = useViewAtom(CurrentPageAtom, params);

    const chooseAccountHandler = useCallback(
        (id) => {
            localStorage.setItem(KEY, id);
            history.replace(paths.account.view(id));
        },
        [history],
    );

    if (currentID) {
        chooseAccountHandler(currentID);
    }

    return <AutoPageComponent />;
};

export const AutoPageComponent = () => {
    return <LoaderBlock />;
};
