import { RouteProps } from 'react-router';

import { TransactionsPage } from 'src/pages/TransactionsPage';
import { transactions } from 'src/paths/transactions';
import {
    TransactionAddPage,
    TransactionScanQrPage,
    TransactionUpdatePage,
    TransactionViewPage,
} from 'src/transactions/pages';

export const transactionRoutes: RouteProps[] = [
    {
        path: transactions.add(),
        component: TransactionAddPage,
    },
    {
        path: transactions.scan(),
        component: TransactionScanQrPage,
    },
    {
        path: transactions.update(),
        component: TransactionUpdatePage,
    },
    {
        path: transactions.view(),
        component: TransactionViewPage,
    },
    {
        path: transactions.list(),
        component: TransactionsPage,
    },
];
