import React from 'react';

import { useAtom } from '@flatom/react';
import { useRouteMatch } from 'react-router';

import { CategoryIcon } from 'src/components/CategoryIcon';
import { Header } from 'src/components/Header';
import { transactionTypeNameRu } from 'src/domain/transaction.types';
import { UUID } from 'src/libs/uuid';
import { Link, paths } from 'src/paths';
import { HeaderAction } from 'src/ui-kit/HeaderAction';
import { Icons } from 'src/ui-kit/Icons';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';

import { CategoriesAtom } from '../model';
import { CategoryID } from '../types';

interface IParams {
    categoryId: CategoryID;
}

export const CategoriesViewPage = () => {
    const { categoryId } = useRouteMatch<IParams>().params;
    const { list, loading } = useAtom(CategoriesAtom);

    const category = list.find((item) => item.id === categoryId);
    const parent = category?.parent ? list.find((item) => item.id === category.parent) : undefined;

    if (loading)
        return (
            <>
                <Header
                    title="Категория"
                    back
                />
                <Main>Loading</Main>
                <NavBar />
            </>
        );

    if (!category)
        return (
            <>
                <Header
                    title="Категория"
                    back
                />
                <Main>Категория не найдена</Main>
                <NavBar />
            </>
        );

    const canEdit = UUID.isNotNull(category.parent);

    return (
        <>
            <Header
                title={`Категория ${category.name}`}
                back
            >
                {canEdit && (
                    <HeaderAction
                        icon={Icons.cog}
                        link={paths.categories.edit(categoryId)}
                    />
                )}
            </Header>
            <Main>
                <div className="section mt-2 mb-2">
                    <div
                        className="card inner"
                        style={{ padding: 16 }}
                    >
                        <div className="listed-detail mt-3">
                            <div className="icon-wrapper">
                                <div className="iconbox">
                                    <CategoryIcon
                                        icon={category.icon}
                                        size="large"
                                    />
                                    {/*<Icon icon={Icons.arrowForwardOutline} role="img" className="md hydrated"/>*/}
                                </div>
                            </div>
                        </div>

                        <ul className="listview flush transparent simple-listview no-space mt-3">
                            <li>
                                <strong>Название</strong>
                                <span>{category.name}</span>
                            </li>
                            <li>
                                <strong>Категория</strong>
                                {parent ? (
                                    <Link to={paths.categories.show(parent.id)}>{parent.name}</Link>
                                ) : (
                                    <span>-</span>
                                )}
                            </li>
                            <li>
                                <strong>Тип операции по умолчанию</strong>
                                <span>{transactionTypeNameRu[category.defaultTxType]}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                {UUID.isNull(category.parent) && (
                    <ul className="listview image-listview inset mt-2">
                        <li key={category.id}>
                            <Link
                                to={paths.categories.create(category.id)}
                                className="item"
                            >
                                <div className="in">
                                    <div>Добавить подкатегорию</div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                )}
            </Main>
            <NavBar />
        </>
    );
};
