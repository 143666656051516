import React from 'react';

import { Form } from 'react-final-form';

import { ICategory, IRootCategory } from 'src/categories/types';
import { AccountField } from 'src/components/inputs/AccountField';
import { CategoryField } from 'src/components/inputs/CategoryField';
import { DayDateField } from 'src/components/inputs/DayDateField';
import { MoneyField } from 'src/components/inputs/MoneyField';
import { TextField } from 'src/components/inputs/TextField';
import { TxTypeField } from 'src/components/inputs/TxTypeField';
import { IAccount } from 'src/models/account-dto/account.types';
import { ITransactionForm, TransactionType } from 'src/models/transaction/transaction.types';

interface IProps {
    accounts: IAccount[];
    categories: IRootCategory[];
    value: ITransactionForm;
    onSubmit: (value: ITransactionForm) => void;
    validate: (value: ITransactionForm) => Partial<{ [key in keyof ITransactionForm]: string }>;
}

export const TransactionForm = ({ accounts, categories, onSubmit, value, validate }: IProps) => {
    const categoriesList: ICategory[] = [];

    categories.forEach((root) => {
        categoriesList.push(root, ...root.children.map((item) => ({ ...item, name: root.name + ' / ' + item.name })));
    });

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={value}
            validate={validate}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                >
                    <MoneyField
                        name="amount"
                        label="Сумма"
                        hint="This is amount"
                        placeholder="Enter amount"
                    />
                    <CategoryField
                        name="category"
                        label="Категория"
                        categories={categoriesList}
                    />
                    <TxTypeField
                        name="type"
                        label=""
                        types={[TransactionType.Income, TransactionType.Expense]}
                    />
                    <TextField
                        name="title"
                        label="Описание"
                    />
                    <DayDateField
                        name="date"
                        label="Дата"
                        component="input"
                    />
                    <AccountField
                        name="account"
                        label="Карта"
                        accounts={accounts}
                    />
                    <button
                        type="submit"
                        className="btn btn-primary rounded mr-1"
                    >
                        Сохранить
                    </button>
                </form>
            )}
        />
    );
};
