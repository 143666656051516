import React from 'react';

import { useAtom } from '@flatom/react';
import { useRouteMatch } from 'react-router';

import { CategoriesAtom } from 'src/categories/model/categories.atom';
import { CategoryID } from 'src/categories/types';
import { Header } from 'src/components/Header';
import { MoneyView } from 'src/components/MoneyView';
import { DayDate } from 'src/domain/date.types';
import { transactionTypeNameRu } from 'src/domain/transaction.types';
import { useTransaction } from 'src/hooks';
import { UUID } from 'src/models/common/common.types';
import { formatDateDayLong } from 'src/models/dates';
import { Link, paths } from 'src/paths';
import { HeaderAction } from 'src/ui-kit/HeaderAction';
import { Icon } from 'src/ui-kit/Icon';
import { Icons } from 'src/ui-kit/Icons';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';

interface IParams {
    accountId: UUID;
    date: DayDate;
    txId: UUID;
}

export const TransactionViewPage = () => {
    const { accountId, date, txId } = useRouteMatch<IParams>().params;

    const { map: categories } = useAtom(CategoriesAtom);

    const tx = useTransaction(accountId, date, txId);

    if (!tx) return <div>Not found</div>;

    const category = categories.get(tx.categoryId as CategoryID);

    return (
        <>
            <Header
                title="Детали операции"
                back
                href={paths.account.monthFocusTx(tx.account.id, tx.date as any, tx.id)}
            >
                <HeaderAction
                    link={paths.transactions.update(tx.account.id, tx.date, tx.id)}
                    icon={Icons.settingsOutline}
                />
            </Header>
            <Main>
                <div className="section mt-2 mb-2">
                    <div
                        className="card inner mb-2"
                        style={{ padding: 16 }}
                    >
                        <div className="listed-detail mt-3">
                            <div className="icon-wrapper">
                                <div className="iconbox">
                                    <Icon
                                        icon={Icons.arrowForwardOutline}
                                        role="img"
                                        className="md hydrated"
                                    />
                                </div>
                            </div>
                            <h3 className="text-center mt-2">{transactionTypeNameRu[tx.type]}</h3>
                        </div>

                        <ul className="listview flush transparent simple-listview no-space mt-3">
                            <li>
                                <strong>Сумма</strong>
                                <span>
                                    <MoneyView money={tx.amount} />
                                </span>
                            </li>
                            <li>
                                <strong>Категория</strong>
                                <Link to={paths.categories.view(category.id)}>{category.name}</Link>
                            </li>
                            <li>
                                <strong>Описание</strong>
                                <span>{tx.title || 'No title'}</span>
                            </li>
                            <li>
                                <strong>Карта</strong>
                                <span>
                                    <Link to={paths.account.view(tx.account.id)}>{tx.account.name}</Link>
                                </span>
                            </li>
                            <li>
                                <strong>Дата</strong>
                                <span>{formatDateDayLong(tx.date)}</span>
                            </li>
                            <li>
                                <strong>Чек</strong>
                                {!tx.qrCode && (
                                    <span>
                                        <i>(нет чека)</i>
                                    </span>
                                )}
                                {tx.qrCode && <Link to={paths.bills.view(tx.qrCode)}>Просмотреть чек</Link>}
                            </li>
                        </ul>
                    </div>
                </div>
            </Main>
            <NavBar newTxDate={tx.date} />
        </>
    );
};
