import React, { useEffect, useState } from 'react';

import { db } from 'src/db';

interface IStatistics {
    accounts: number;
    bills: number;
    categories: number;
}

function useStatistics() {
    const [stat, setStat] = useState<IStatistics>({
        accounts: 0,
        bills: 0,
        categories: 0,
    });

    useEffect(() => {
        db.getStatistics().then(setStat as any);
    }, []);

    return stat;
}

export const StatisticsPart = () => {
    const { accounts, bills, categories } = useStatistics();

    return (
        <>
            <div className="listview-title mt-1">Статистика</div>
            <ul
                className="listview image-listview text inset"
                style={{ marginLeft: '0', marginRight: '0' }}
            >
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Карты</div>
                            <span className="text-muted">{accounts}</span>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="action item">
                        <div className="in">
                            <div>Счета</div>
                            <span className="text-muted">{bills}</span>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Категории</div>
                            <span className="text-muted">{categories}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </>
    );
};
