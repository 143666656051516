import { Collection } from './collection';
import { DBStatus, DBStatusListener } from './db.types';
import { declareSchema, IScheme, Migrations, RootMigration } from './scheme';
import { openDB } from './upgrade';

export class IndexedDB {
    public readonly schemes: ReadonlyArray<IScheme>;

    get status(): DBStatus {
        return this._status;
    }

    public db: IDBDatabase | null = null;

    private listeners: DBStatusListener[] = [];

    private _status = DBStatus.Initializing;

    constructor(
        public readonly dbName: string,
        schemes: ReadonlyArray<IScheme>,
        migrations: {
            [key: string]: RootMigration;
        } = {},
    ) {
        if (!window.indexedDB) throw new Error('IndexedDB not supported');

        schemes.forEach((scheme) => {
            if (!scheme) throw new Error('Collection name cannot be empty');
            const exists = schemes.find((item) => item !== scheme && item.collection === scheme.collection);

            if (exists)
                throw new Error(
                    `Collection "${scheme.collection}" used in different schemes.\nYou can use collection only in one scheme`,
                );
        });

        const rootFakeScheme = this.createRootSchema(migrations);

        this.schemes = [rootFakeScheme, ...schemes];

        console.log(`[db] Create DB instance, count of models: ${schemes.length}`);
    }

    public transaction<T>(scheme: IScheme<T>): Collection<T> {
        if (this._status !== DBStatus.Ready && this._status !== DBStatus.Migrating)
            throw new Error('Database not ready');

        return this.getAccessor(scheme);
    }

    public addStatusListener(fn: DBStatusListener): void {
        this.listeners.push(fn);
        setTimeout(() => fn(this.status));
    }

    public removeStatusListener(fn: DBStatusListener): void {
        this.listeners = this.listeners.filter((item) => item !== fn);
    }

    public open(): Promise<IDBDatabase> {
        this.setStatus(DBStatus.Initializing);

        return openDB(this.dbName, this.schemes).then(
            (db) => {
                console.log('[db] Successfully open DB');
                this.db = db;
                this.setStatus(DBStatus.Ready);

                return db;
            },
            (event) => {
                console.error('[db] Failed to open DB', event);
                this.setStatus(DBStatus.Failed);

                return event.error;
            },
        );
    }

    public close(): Promise<void> {
        return new Promise((resolve) => {
            this.setStatus(DBStatus.Closed);
            this.db.close();
            resolve();
        });
    }

    public getStatistics(): Promise<Record<string, number>> {
        return new Promise<Record<string, number>>((resolve, reject) => {
            if (this._status !== DBStatus.Ready) {
                return reject('Database not ready');
            }

            const storeNames = Array.from(this.db.objectStoreNames);
            const tx = this.db.transaction(storeNames);
            const stat: Record<string, number> = {};

            tx.onerror = (e) => reject(e);

            tx.oncomplete = () => {
                console.log('complete', stat);
                resolve(stat);
            };

            storeNames.forEach((storeName) => {
                const store = tx.objectStore(storeName);
                const request = store.count();

                request.onerror = () => reject(`Failed to fetch count of objectStore "${storeName}"`);

                request.onsuccess = (e: any) => {
                    console.log(e);
                    stat[storeName] = e.target.result;
                };
            });
        });
    }

    private setStatus(status: DBStatus): void {
        console.log(`[db] Change DB status to "${DBStatus[status]}"`);
        if (status === this._status) return;
        console.log(`[db] Change DB status from "${DBStatus[this._status]}" to "${DBStatus[status]}"`);
        this._status = status;
        this.listeners.forEach((fn) => fn(status));
    }

    private getAccessor(scheme: IScheme): Collection {
        if (this.db) {
            return new Collection(this.db, scheme);
        } else {
            throw new Error('[db] Cannot create accessor for closed database');
        }
    }

    private createRootSchema(migrations: { [key: string]: RootMigration }): IScheme<Record<string, unknown>> {
        const migs: Migrations = {};

        Object.keys(migrations).forEach((key) => (migs[key] = (_, tx, db) => migrations[key](db, tx)));

        return declareSchema('', {}, migs);
    }
}
