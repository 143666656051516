import { MonthDate } from 'src/domain/date.types';
import { Packer } from 'src/libs/packable/packable';
import { extendSummaryPacker, IPeriodSummary, SyncStatus, UUID } from 'src/models/common/common.types';
import { IDay } from 'src/models/day/day.class';

export interface IMonthBrief {
    id: UUID;
    month: MonthDate;
    summary: IPeriodSummary;
    prevMonths: UUID[];
    prevVersions: UUID[];
    dataHash: string;
}

export const monthBriefPacker = Packer.maybe(
    Packer.forObject<IMonthBrief>({
        id: String,
        month: String,
        summary: extendSummaryPacker,
        prevMonths: [String],
        prevVersions: [String],
        dataHash: String,
    }),
);

export interface IMonth extends IMonthBrief {
    syncStatus: SyncStatus;
    timestamp: number;
    account: UUID;
    days: IDay[];
}
