/**
 * @module bills/model
 */

import { createToken } from '@flatom/core';

import { DTO } from 'src/utils/dto';

import { BillID, IBill, IProverkaChekaResponse } from '../types';

export const billsServiceToken = createToken<IBillsService>('BillsService');

export interface IBillsService {
    getById(id: BillID): Promise<DTO<IBill>>;

    getByNormalizedData(data: string): Promise<DTO<IBill>>;

    insert(dto: DTO<IBill>): Promise<BillID>;

    fetchBillData(qrCode: string, token: string): Promise<IProverkaChekaResponse>;

    saveBill(bill: IBill): Promise<void>;
}
