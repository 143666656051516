import { declareSchema } from 'src/libs/indexed-db/scheme';
import { DTO } from 'src/utils/dto';

import { IBill } from '../types';

export const BillsScheme = declareSchema<DTO<IBill>>(
    'bills',
    {
        keyPath: 'id',
    },
    {
        async v20220220_2002(objectStore) {
            objectStore.clear();
            objectStore.createIndex('normalized', 'normalized', { unique: true });
        },
    },
);
