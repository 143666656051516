import { useEffect } from 'react';

import { AtomWithActionCreators } from '@flatom/core';
import { useAction, useAtom } from '@flatom/react';

interface ViewAtomActions<T extends object = {}> {
    init: T;
    propsChange: T;
    destroy: void;
}

export function useViewAtom<T, Props extends object = {}>(
    atom: AtomWithActionCreators<T, ViewAtomActions<Props>>,
    props: any,
): T {
    const state = useAtom(atom);
    const init = useAction(atom.a.init);
    const propsChange = useAction(atom.a.propsChange);
    const destroy = useAction(atom.a.destroy);

    props = { __key: 'props', ...props };

    useEffect(() => {
        init(props);
        propsChange(props);

        return () => {
            destroy();
        };
    }, []);

    useEffect(
        () => {
            propsChange(props);
        },
        Object.keys(props)
            .sort()
            .map((key) => props[key]),
    );

    return state;
}
