import { declareAtom } from '@flatom/core';

import { AccountGrips } from 'src/atoms/account-grips/account-grips.atom';
import { Accounts } from 'src/atoms/accounts/accounts.atom';
import { CategoriesAtom, IRootCategory } from 'src/categories';
import { AccountDTO } from 'src/models/account-dto/account.class';
import { AccountGrip } from 'src/models/account-grip/grip.class';
import { ITransactionForm } from 'src/models/transaction/transaction.types';

export interface ITransactionAddPageState {
    currentAccount: AccountGrip | null;
    data: ITransactionForm;
    accounts: AccountDTO[];
    categories: IRootCategory[];
}

export const TransactionAddPageAtom = declareAtom<ITransactionAddPageState>('transactions/pages/add')(
    {
        prefill(state, data: Partial<ITransactionForm>) {
            return {
                ...state,
                data: { ...state.data, ...data },
            };
        },
    },
    (on) => [
        on(AccountGrips, (state, { current }) => {
            return {
                ...state,
                currentAccount: current,
            };
        }),
        on(Accounts, (state, { accounts }) => {
            return {
                ...state,
                accounts: Array.from(accounts.values()),
            };
        }),
        on(CategoriesAtom, (state, { tree }) => {
            return {
                ...state,
                categories: tree,
            };
        }),
    ],
);
