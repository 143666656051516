import { ReactNode } from 'react';

import block from 'bem-css-modules';

import styles from './Main.module.scss';

interface IProps {
    fullWidth?: boolean;
    children: ReactNode | Element[];
    paddingTop?: boolean;
}

const b = block(styles, 'main');

export const Main = ({ children, fullWidth, paddingTop }: IProps) => {
    return <main className={b({ fullWidth, paddingTop })}>{children}</main>;
};
