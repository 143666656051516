import { createToken } from '@flatom/core';

import { CategoryID, ICategoryDTO } from '../types';

export const categoriesServiceToken = createToken<ICategoriesService>('CategoriesService');

export interface ICategoriesService {
    getAll(): Promise<ICategoryDTO[]>;

    insert(dto: ICategoryDTO): Promise<CategoryID>;

    update(dto: ICategoryDTO): Promise<CategoryID>;
}
