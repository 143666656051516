import { ActionCreator, PayloadActionCreator, Store } from '@flatom/core';

import { BillsScheme } from 'src/bills/services/bills.scheme';
import { CategoriesScheme } from 'src/categories/services/categories.scheme';
import { IndexedDB } from 'src/libs/indexed-db';

import { AccountScheme, MonthScheme } from './schemes';

export const db = new IndexedDB('finman', [CategoriesScheme, AccountScheme, MonthScheme, BillsScheme], {
    v20211215_221801(db) {
        try {
            db.deleteObjectStore('new-categories');
        } catch (_) {}
    },
});

const handlers: { [action: string]: (payload: unknown, store: Store) => void } = {};

export function addActionHandler<T>(
    action: ActionCreator<T> | PayloadActionCreator<T>,
    handler: (payload: T, store: Store) => void,
) {
    if (handlers[action.getType()]) throw new Error(`Can not add new handler for action "${action.getType()}"`);

    handlers[action.getType()] = handler as (payload: unknown, store: Store) => void;
}

export function initIndexedDB(store: Store) {
    store.subscribe((action) => {
        console.log(action);

        if (handlers[action.type]) handlers[action.type](action.payload, store);
    });
}
