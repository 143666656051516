import { Map } from 'immutable';
import { v1 as uuidGenerator } from 'uuid';

import { Packable, PackableClass } from 'src/libs/packable/decorator';
import { Packer } from 'src/libs/packable/packable';
import { ISummary, UUID } from 'src/models/common/common.types';
import { Money } from 'src/models/money';
import { MonthLegacy } from 'src/models/month/month-legacy.class';
import { IMonthBrief, monthBriefPacker } from 'src/models/month/month-legacy.types';
import { addSummary, EMPTY_SUMMARY } from 'src/models/transaction/transactions.utils';

import { IAccount } from './account.types';
import { findChain, RequiredMonthsError, updateMonthChain } from './chain.utils';

/**
 * Contains information about Account
 */
@PackableClass((data) => new AccountDTO(data))
export class AccountDTO implements IAccount, ISummary {
    @Packable(String) public readonly id: UUID = '';
    @Packable(String) public readonly name: string = '';
    @Packable(Money) public readonly balance: Money = Money.create(0, 'RUB');
    @Packable(Money) public readonly income: Money = Money.create(0, 'RUB');
    @Packable(Money) public readonly expense: Money = Money.create(0, 'RUB');
    @Packable(String) public readonly categoriesBlockId: string = 'default';
    @Packable(monthBriefPacker) public readonly head: IMonthBrief | null = null;
    @Packable([monthBriefPacker]) public readonly months: ReadonlyArray<Readonly<IMonthBrief>> = [];
    public readonly fullMonths = Map<UUID, MonthLegacy>();

    private constructor(account: Partial<AccountDTO>) {
        return Object.assign(this, account);
    }

    public static create(name: string, id?: UUID): AccountDTO {
        return new AccountDTO({
            id: id || uuidGenerator(),
            name,
        });
    }

    public static fromJSON(data: any): AccountDTO {
        return Packer.get(AccountDTO).decode(data);
    }

    public static toJSON(account: AccountDTO): any {
        return account.toJSON();
    }

    public toJSON(): any {
        return Packer.get(AccountDTO).encode(this);
    }

    public UNSAFE_forceSetHead(head: MonthLegacy, months: MonthLegacy[]): AccountDTO {
        const { chain, completed } = findChain(head, months);

        if (!completed) {
            const last = chain.pop();
            const required = [...last!.prevMonths, ...last!.prevVersions].filter(
                (id) => !months.find((item) => item.id !== id),
            );

            throw new RequiredMonthsError(...required);
        }

        const { income, expense, balance } = chain.reduce((acc, item) => addSummary(acc, item.summary), EMPTY_SUMMARY);

        return new AccountDTO({
            ...this,
            head,
            months: chain.map(MonthLegacy.getBrief),
            balance,
            income,
            expense,
        });
        // throw new Error('Not implements');
        // throw new RequiredMonthsError(['123123123']);
    }

    public updateHead(head: MonthLegacy, additions: MonthLegacy[] = []): AccountDTO {
        const chain = updateMonthChain(head, additions, this.months);

        const { income, expense, balance } = chain.reduce((acc, item) => addSummary(acc, item.summary), EMPTY_SUMMARY);

        return new AccountDTO({
            ...this,
            head: MonthLegacy.getBrief(head),
            months: chain.map(MonthLegacy.getBrief),
            balance,
            income,
            expense,
        });
    }

    public checkChain(): boolean {
        if (!this.head && this.months.length === 0) return true;

        const { chain, completed } = findChain(this.head!, this.months);

        if (!completed) return false;

        if (chain.length !== this.months.length) return false;

        return true;
    }
}
