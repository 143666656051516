export function eventLogger<T extends { addEventListener: (eventName: string, cb: (event: Event) => void) => void }>(
    emitter: T,
    emitterName: string,
    eventName: string | string[],
) {
    if (Array.isArray(eventName)) eventName.forEach((name) => eventLogger(emitter, emitterName, name));
    else
        emitter.addEventListener(eventName, (event) => {
            console.log(`[eventLogger] Emitter: "${emitterName}", event "${eventName}":`, event);
        });
}
