export enum DBStatus {
    Initializing,
    Upgrading,
    Migrating,
    Ready,
    Closed,
    Failed,
    Blocked,
}

export interface DBStatusListener {
    (status: DBStatus): void;
}
