import { declareAtom } from '@flatom/core';

import { UUID } from 'src/libs/uuid';

import { CategoryID, ICategory, ICategoryDTO, IRootCategory } from '../types';

import { defaultCategory, ICategoriesState } from './categories.types';

export const CategoriesAtom = declareAtom<ICategoriesState>('categories', {
    loading: true,
    list: [],
    tree: [],
    map: new Map(),
})({
    setList(state, dtoList: ICategoryDTO[]) {
        const list = dtoList
            .map((item) => ({ id: UUID.create(item.id), ...item } as ICategory))
            .filter((item) => UUID.isNotNull(item.id));

        const map = new Map(list.map((item) => [item.id, item]));

        map.set(UUID.null as CategoryID, defaultCategory);
        list.unshift(defaultCategory);

        const tree: IRootCategory[] = list
            .filter((item) => UUID.isNull(item.parent))
            .map((item) => ({ ...item, children: [] }));

        list.forEach((category) => {
            if (UUID.isNull(category.parent)) return;
            const parent = tree.find((item) => item.id === category.parent);

            if (!parent) {
                throw new Error(`Category "${category.name}" (${category.id}) has invalid parent "${category.parent}"`);
            }

            parent.children.push(category);
        });

        return {
            loading: false,
            list,
            tree,
            map,
        };
    },
});
