import { Action } from '@flatom/core';
import { put } from 'redux-saga/effects';

import { updateAccountGrip } from 'src/atoms/account-grips/account-grips.actions';
import { DayDate } from 'src/domain/date.types';
import { dayDateToMonth } from 'src/models/common/date.utils';
import { Money } from 'src/models/money';
import {
    addTransactionSuccess,
    updateTransaction,
    updateTransactionSuccess,
} from 'src/models/transaction/transaction.actions';
import { ITransactionForm, TransactionType } from 'src/models/transaction/transaction.types';

import { sagaLauncher } from '../saga-launcher';
import { AccountUtils } from '../utils/account.saga';
import { MonthUtils } from '../utils/month.saga';

sagaLauncher.onAction(updateTransaction, transactionUpdateSaga);

function* transactionUpdateSaga(action: Action<ITransactionForm>) {
    console.log('*** updateTransactionSaga started');
    const payload: ITransactionForm = action.payload;
    const account = yield* AccountUtils.select(payload.account);
    const month = yield* MonthUtils.get(account, dayDateToMonth(payload.date as DayDate));

    console.log('updateTransactionSaga', month.id, month.prevVersions);

    const foundTx = month
        .getDay(action.payload.lastTxData.date as DayDate)
        .transactions.find((item) => item.id === payload.id);

    const removedTx = foundTx.setType(TransactionType.Removed);
    const oldDay = month.getDay(payload.lastTxData.date as DayDate).upsertTransaction(removedTx);

    const monthWithoutOld = month.updateDay(oldDay);

    const tx = foundTx
        .setAmount(Money.create(payload.amount, 'RUB'))
        .setType(payload.type)
        .setCategory(payload.category)
        .setTitle(payload.title);

    const day = monthWithoutOld.getDay(payload.date as DayDate).upsertTransaction(tx);

    const updatedMonth = monthWithoutOld.updateDay(day);

    console.log('updatedMonth', updatedMonth.id, updatedMonth.prevVersions);

    const value = yield* AccountUtils.update(account, updatedMonth);

    console.log('*** addTransactionSaga complete');
    yield put(updateAccountGrip(value));
    yield put(
        addTransactionSuccess({
            ...payload,
            id: tx.id,
        }),
    );
    yield put(updateTransactionSuccess(payload));

    return value;
}
