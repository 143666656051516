import React, { useState } from 'react';

import { useAction, useAtom } from '@flatom/react';
import { useHistory, useRouteMatch } from 'react-router';

import { updateCategory } from 'src/categories/model/categories.effects';
import { Header } from 'src/components/Header';
import { TransactionType } from 'src/domain/transaction.types';
import { Link, paths } from 'src/paths';
import { store } from 'src/store';
import { Card } from 'src/ui-kit/Card';
import { HeaderAction } from 'src/ui-kit/HeaderAction';
import { Icon } from 'src/ui-kit/Icon';
import { Icons } from 'src/ui-kit/Icons';
import { Main } from 'src/ui-kit/Main';
import { Section } from 'src/ui-kit/Section';
import { NavBar } from 'src/widgets/NavBar';

import { CategoryForm } from '../components/Form';
import { CategoriesAtom } from '../model/categories.atom';
import { CategoryID, ICategoryForm } from '../types';

interface IParams {
    categoryId: CategoryID;
}

export const CategoriesEditPage = () => {
    const { categoryId } = useRouteMatch<IParams>().params;
    const history = useHistory();

    const { loading, list } = useAtom(CategoriesAtom);

    const category = list.find((item) => item.id === categoryId);

    const [data, setData] = useState({
        id: categoryId,
        name: category?.name,
        icon: category?.icon || 'default',
        parent: category?.parent,
        defaultTxType: category?.defaultTxType || TransactionType.Expense,
    } as ICategoryForm);

    const submitHandler = async (data: ICategoryForm) => {
        console.log(data);
        setData(data);
        await store.dispatch(updateCategory(data));
        history.goBack();
    };

    const handleRemove = useAction(() => ({ type: 'REMOVE CATEGORY', categoryId } as any), [categoryId]);

    function validate(data: ICategoryForm) {
        const errors: Partial<{ [key in keyof ICategoryForm]: string }> = {};

        if (data.name.length < 3) {
            errors.name = 'Invalid name';
        }

        return errors;
    }

    const title = 'Редактирование категории';

    if (loading) {
        return (
            <>
                <Header
                    title={title}
                    back
                />
                <Main>Loading</Main>
                <NavBar />
            </>
        );
    }

    if (!category) {
        return (
            <>
                <Header
                    title={title}
                    back
                />
                <Main>Категория не найдена</Main>
                <NavBar />
            </>
        );
    }

    return (
        <>
            <Header
                title={title}
                back
            >
                <Link to={paths.categories.edit(categoryId)}>
                    <Icon icon={Icons.save} />
                </Link>
                <HeaderAction
                    icon={Icons.remove}
                    onClick={handleRemove}
                />
            </Header>
            <Main>
                <Section full>
                    <Card>
                        <CategoryForm
                            value={data}
                            onSubmit={submitHandler}
                            validate={validate}
                            categories={list}
                        />
                    </Card>
                </Section>
            </Main>
            <NavBar />
        </>
    );
};
