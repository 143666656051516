import { Action } from '@flatom/core';
import { put, fork } from 'redux-saga/effects';

import { updateMonthGripSuccess } from 'src/atoms/month-grips/month-grips.actions';
import { loadMonthsSuccess } from 'src/atoms/months/months.actions';
import { MonthLegacy } from 'src/models/month/month-legacy.class';

import { sagaLauncher } from '../saga-launcher';
import { AccountUtils } from '../utils/account.saga';

sagaLauncher.onAction(loadMonthsSuccess, updateMonthGripsSaga);

export function* updateMonthGripsSaga(action: Action<MonthLegacy[]>) {
    console.log('update MonthLegacy grips saga');
    const months = action.payload;

    for (const month of months) yield fork(updateMonthGripSaga, month);
}

export function* updateMonthGripSaga(month: MonthLegacy) {
    console.log(`update Month grip saga for ${month.month}`);
    console.log('123456');

    const accountGrip = yield* AccountUtils.getGrip(month.account);

    console.log('123');
    const grip = accountGrip.makeMonth(month);

    console.log('234');

    yield put(updateMonthGripSuccess(grip));
    console.log('345');
}
