import React from 'react';

import { useAtom } from '@flatom/react';
import { useRouteMatch } from 'react-router';

import { Header } from 'src/components/Header';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';

import { CategoriesAtom } from '../model/categories.atom';
import { CategoryID } from '../types';

import { CategoriesSubListPage } from './SubList';
import { CategoriesViewPage } from './View';

interface IParams {
    categoryId: CategoryID;
}

export const CategoriesShowPage = () => {
    const { categoryId } = useRouteMatch<IParams>().params;
    const { tree, loading } = useAtom(CategoriesAtom);

    const category = tree.find((item) => item.id === categoryId);

    if (loading)
        return (
            <>
                <Header
                    title="Категории"
                    back
                />
                <Main>Loading</Main>
                <NavBar />
            </>
        );

    if (category && category.children.length) {
        return <CategoriesSubListPage />;
    } else {
        return <CategoriesViewPage />;
    }
};
