import { Action } from '@flatom/core';
import { put, take } from 'redux-saga/effects';

import { updateAccountGrips } from 'src/atoms/account-grips/account-grips.actions';
import { loadAccounts, loadAccountsFailed, loadAccountsSuccess } from 'src/atoms/accounts/accounts.actions';
import { refreshAll } from 'src/common/domain/effects';
import { AccountDTO } from 'src/models/account-dto/account.class';

import { sagaLauncher } from '../saga-launcher';

sagaLauncher.onAction(refreshAll, refreshSaga);

export function* refreshSaga(action: Action<null>) {
    console.log('*** refreshSaga started');

    yield put(loadAccounts());
    const resultAction = yield take([loadAccountsSuccess.getType(), loadAccountsFailed.getType()]);

    if (resultAction.type === loadAccountsFailed.getType()) return;

    const accounts: AccountDTO[] = (resultAction as any).payload.accounts;

    yield put(updateAccountGrips(accounts));
}
