import { v1 as uuidGenerator } from 'uuid';

import { Packable, PackableClass } from 'src/libs/packable/decorator';
import { Packer } from 'src/libs/packable/packable';
import { UUID } from 'src/models/common/common.types';
import { Money } from 'src/models/money';

import { ITransaction, TransactionType } from './transaction.types';

@PackableClass((data) => new Transaction(data))
export class Transaction implements ITransaction {
    @Packable(String) public id: UUID = '';
    @Packable(Money) public amount: Money = Money.create(0, 'RUB');
    @Packable(Number) public type: TransactionType = TransactionType.Removed;
    @Packable(String) public category: UUID | '' = '';
    @Packable(String) public title: string | '' = '';
    @Packable(String) public qrCode: string | '' = '';
    @Packable(Number) public createdAt: number = 0;
    @Packable(Number) public updatedAt: number = 0;

    private constructor(data: unknown) {
        Object.assign(this, data);
    }

    public static createWithID(id: UUID, type?: TransactionType, amount?: Money): Transaction;
    public static createWithID(
        id: UUID,
        type: TransactionType,
        amount: string | number,
        currencyName: string,
    ): Transaction;
    public static createWithID(
        id: UUID,
        type: TransactionType = TransactionType.Removed,
        amount?: string | number | Money,
        currencyName?: string,
    ): Transaction {
        const tx = new Transaction({
            id,
            amount: Money.create(0, 'RUB'),
            type,
            category: '',
            title: '',
            createdAt: 0,
            updatedAt: 0,
            qrCode: '',
        });

        if (amount) return tx.setAmount(amount as any, currencyName as any);
        else return tx;
    }

    public static create(type?: TransactionType, amount?: Money): Transaction;
    public static create(type: TransactionType, amount: string | number, currencyName: string): Transaction;
    public static create(
        type: TransactionType = TransactionType.Removed,
        amount?: string | number | Money,
        currencyName?: string,
    ): Transaction {
        const id = uuidGenerator();

        const tx = new Transaction({
            id,
            amount: Money.create(0, 'RUB'),
            type,
            category: '',
            title: '',
            createdAt: 0,
            updatedAt: 0,
            qrCode: '',
        });

        if (amount) {
            return tx.setAmount(amount as any, currencyName as any);
        } else {
            return tx;
        }
    }

    public static fromJSON(data: any): Transaction {
        // TODO больше валидации
        return Packer.get(Transaction).decode(data);
    }

    public static toJSON(tx: Transaction): any {
        return tx.toJSON();
    }

    public toJSON(): unknown {
        return Packer.get(Transaction).encode(this);
    }

    public setAmount(money: Money): Transaction;
    public setAmount(amount: string | number, currencyName: string): Transaction;
    public setAmount(amount: string | number | Money, currencyName?: string): Transaction {
        let money: Money;

        if ((typeof amount === 'string' || typeof amount === 'number') && typeof currencyName === 'string') {
            money = Money.create(amount, currencyName);
        } else {
            money = Money.from(amount as string | Money);
        }

        return new Transaction({
            ...this,
            amount: money,
        });
    }

    public setType(type: TransactionType): Transaction {
        return new Transaction({
            ...this,
            type,
        });
    }

    public setCategory(category: UUID | null): Transaction {
        return new Transaction({
            ...this,
            category,
        });
    }

    public setTitle(title: string | ''): Transaction {
        return new Transaction({
            ...this,
            title,
        });
    }

    public setQrCode(qrCode: string | ''): Transaction {
        return new Transaction({
            ...this,
            qrCode,
        });
    }

    public setCreatedAt(createdAt: number): Transaction {
        return new Transaction({
            ...this,
            createdAt,
        });
    }

    public setUpdatedAt(updatedAt: number): Transaction {
        return new Transaction({
            ...this,
            updatedAt,
        });
    }
}
