import { declareSchema } from 'src/libs/indexed-db/scheme';
import { AccountDTO } from 'src/models/account-dto/account.class';
import { IAccount } from 'src/models/account-dto/account.types';

export const AccountScheme = declareSchema<IAccount>(
    'accounts',
    {
        keyPath: 'id',
    },
    {
        v20220219_2007(store) {
            const defaultAccount = AccountDTO.create('Default');

            store.add(defaultAccount.toJSON());
        },
    },
);
