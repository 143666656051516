import { DayDate } from 'src/domain/date.types';
import { UUID } from 'src/models/common/common.types';
import { ITransactionForm } from 'src/models/transaction/transaction.types';

import { addQueryString, baseUrl } from './config';

export const transactions = {
    list: () => {
        return `${baseUrl}/transactions`;
    },
    add: (params?: Partial<ITransactionForm>) => {
        return `${baseUrl}/transaction/add` + addQueryString(params);
    },
    scan: (params?: Partial<ITransactionForm>) => {
        return `${baseUrl}/transaction/scan` + addQueryString(params);
    },
    view: (accountId: UUID = ':accountId', date = ':date' as DayDate, txId: UUID = ':txId') => {
        return `${baseUrl}/accounts/${accountId}/day/${date}/${txId}`;
    },
    update: (accountId: UUID = ':accountId', date = ':date' as DayDate, txId: UUID = ':txId') => {
        return `${baseUrl}/accounts/${accountId}/day/${date}/${txId}/update`;
    },
};
