import React from 'react';

import { useAtom } from '@flatom/react';
import { useHistory, useRouteMatch } from 'react-router';

import { AccountGrips } from 'src/atoms/account-grips/account-grips.atom';
import { Accounts } from 'src/atoms/accounts/accounts.atom';
import { CategoriesAtom } from 'src/categories/model/categories.atom';
import { Header } from 'src/components/Header';
import { DayDate } from 'src/domain/date.types';
import { useTransaction } from 'src/hooks';
import { UUID } from 'src/models/common/common.types';
import { dispatchAndWaitResult } from 'src/models/helper';
import {
    updateTransaction,
    updateTransactionFailed,
    updateTransactionSuccess,
} from 'src/models/transaction/transaction.actions';
import { ITransactionForm } from 'src/models/transaction/transaction.types';
import { paths } from 'src/paths';
import { Card } from 'src/ui-kit/Card';
import { HeaderAction } from 'src/ui-kit/HeaderAction';
import { Icons } from 'src/ui-kit/Icons';
import { Main } from 'src/ui-kit/Main';
import { Section } from 'src/ui-kit/Section';
import { NavBar } from 'src/widgets/NavBar';
import { TransactionForm } from 'src/widgets/TransactionForm';

interface IParams {
    accountId: UUID;
    date: DayDate;
    txId: UUID;
}

export const TransactionUpdatePage = () => {
    const { accountId, date, txId } = useRouteMatch<IParams>().params;

    const tx = useTransaction(accountId, date, txId);

    const history = useHistory();

    const { current: currentAccount } = useAtom(AccountGrips);
    const { accounts } = useAtom(Accounts);
    const { tree: categories } = useAtom(CategoriesAtom);

    if (!tx) return <div>Loading</div>;

    const data: ITransactionForm = {
        id: tx.id,
        title: tx.title,
        type: tx.type,
        amount: tx.amount.amount,
        date: tx.date,
        account: tx.account.id,
        category: tx.categoryId,
    };

    const submitHandler = (form: ITransactionForm) => {
        console.log(form);

        dispatchAndWaitResult(
            updateTransaction({
                ...form,
                account: currentAccount.id,
                lastTxData: data,
            }),
            updateTransactionSuccess,
            updateTransactionFailed,
        ).then(
            () => {
                history.goBack();
                setTimeout(
                    () => history.replace(paths.transactions.view(currentAccount.id, form.date as DayDate, form.id)),
                    20,
                );
            },
            (data) => {
                alert(data.message);
                console.error(data);
            },
        );
    };

    const removeHandler = () => {
        alert('remove');
    };

    function validate(data: ITransactionForm) {
        const errors: Partial<{ [key in keyof ITransactionForm]: string }> = {};

        // if (!data.account)
        //     errors.account = 'Required';
        console.log(data);
        console.log(errors);

        return errors;
    }

    return (
        <>
            <Header
                back
                title="Обновление операции"
            >
                <HeaderAction
                    onClick={removeHandler}
                    icon={Icons.remove}
                />
            </Header>
            <Main>
                <Section full>
                    <Card>
                        <TransactionForm
                            value={data}
                            onSubmit={submitHandler}
                            validate={validate}
                            accounts={Array.from(accounts.values())}
                            categories={categories}
                        />
                    </Card>
                </Section>
            </Main>
            <NavBar />
        </>
    );
};
