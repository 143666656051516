export class BlockedError extends Error {
    constructor() {
        super('Database was blocked');
    }
}

export class VersionError extends Error {
    public readonly targetVersion: number;
    public readonly currentVersion: number;

    constructor(message: ErrorEvent);
    constructor(targetVersion: number, currentVersion: number);
    constructor(targetVersion: number | ErrorEvent, currentVersion?: number) {
        if (typeof targetVersion === 'object') {
            const message = targetVersion.message;

            const [v1, v2] = message.match(/\d+/g);

            targetVersion = +v1;
            currentVersion = +v2;

            if (currentVersion < targetVersion) {
                [currentVersion, targetVersion] = [targetVersion, currentVersion];
            }
        }

        super(`The requested version (${targetVersion}) is less than the existing version (${currentVersion}).`);
        this.targetVersion = targetVersion;
        this.currentVersion = currentVersion;
    }
}

export class UpgradeStoreError extends Error {
    constructor(public readonly storeName: string, public readonly originalError: Error) {
        super(`Failed to upgrade "${storeName}": ${originalError.name}: ${originalError.message}`);
    }
}
