import { DBStatus, IndexedDB } from 'src/libs/indexed-db';

import { IBackupService } from './backup.types';

interface IDBBackupJson {
    version: number;
    data: Record<string, unknown>;
    objectStores: Record<string, IObjectStoreConfig>;
}

interface IObjectStoreConfig {
    keyPath: string | string[];
    autoIncrement: boolean;
}

export class BackupService implements IBackupService {
    constructor(private readonly idb: IndexedDB) {}

    public async createBackupFileContent(): Promise<Uint8Array> {
        const json = await this.backupToJson();
        const header = '#!4wallet.app?v=1.0\n';
        const data = header + JSON.stringify(json) + '\n\n';

        return new TextEncoder().encode(data);
    }

    public async restoreBackupFromJson(data: Uint8Array): Promise<void> {
        const dbName = this.idb.dbName;

        await this.idb.close();

        let json = new TextDecoder().decode(data);
        const headerLength = json.indexOf('\n');

        json = json.substr(headerLength + 1);
        const backupJson = JSON.parse(json);

        const objectStoreNames = Object.keys(backupJson.objectStores);

        await this.dropDatabase(dbName);

        const idbDatabase = await this.openDatabase(dbName, backupJson.version, (idb) => {
            objectStoreNames.forEach((storeName) =>
                idb.createObjectStore(storeName, backupJson.objectStores[storeName]),
            );
        });

        return new Promise((resolve, reject) => {
            /** @type string[] */

            const transaction = idbDatabase.transaction(objectStoreNames, 'readwrite');

            transaction.addEventListener('error', reject);

            const importObject = backupJson.data;

            for (const storeName of Object.keys(importObject)) {
                let count = 0;

                if (!importObject[storeName].length) {
                    delete importObject[storeName];

                    continue;
                }

                for (const toAdd of importObject[storeName]) {
                    const request = transaction.objectStore(storeName).add(toAdd);

                    request.addEventListener('success', () => {
                        count++;

                        if (count === importObject[storeName].length) {
                            // Added all objects for this store
                            delete importObject[storeName];
                            console.log('complete: ' + storeName);
                            console.log('in queue: ' + Object.keys(importObject).join(', '));

                            if (Object.keys(importObject).length === 0) {
                                // Added all object stores
                                resolve();
                            }
                        }
                    });
                }
            }
        });
    }

    openDatabase(dbName: string, version: number, upgradeneeded = (db: IDBDatabase) => null): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const openRequest = window.indexedDB.open(dbName, version);

            openRequest.addEventListener('success', (event: any) => resolve(event.target.result));
            openRequest.addEventListener('error', (event: any) => reject(event));
            openRequest.addEventListener(
                'upgradeneeded',
                (event: any) => upgradeneeded && upgradeneeded(event.target.result),
            );
        });
    }

    private dropDatabase(dbName: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const req = indexedDB.deleteDatabase(dbName);

            req.onsuccess = function () {
                resolve();
            };

            req.onerror = function (e) {
                reject(e.target);
            };

            req.onblocked = function () {
                reject('blocked');
            };
        });
    }

    private backupToJson(): Promise<IDBBackupJson> {
        const idbDatabase = this.db;

        return new Promise((resolve, reject) => {
            const objectStoreNames = Array.from(idbDatabase.objectStoreNames);
            const exportObject = {};
            const result: IDBBackupJson = {
                version: idbDatabase.version,
                data: exportObject,
                objectStores: {},
            };

            if (objectStoreNames.length === 0) {
                return resolve(result);
            }

            const transaction = idbDatabase.transaction(objectStoreNames, 'readonly');

            transaction.addEventListener('error', reject);

            for (const storeName of objectStoreNames) {
                const objectStore = transaction.objectStore(storeName);

                result.objectStores[storeName] = {
                    keyPath: objectStore.keyPath,
                    autoIncrement: objectStore.autoIncrement,
                };

                const allObjects = [];

                objectStore.openCursor().addEventListener('success', (event: any) => {
                    const cursor = event.target.result;

                    if (cursor) {
                        // Cursor holds value, put it into store data
                        allObjects.push(cursor.value);
                        cursor.continue();
                    } else {
                        // No more values, store is done
                        exportObject[storeName] = allObjects;

                        // Last store was handled
                        if (objectStoreNames.length === Object.keys(exportObject).length) {
                            resolve(result);
                        }
                    }
                });
            }
        });
    }

    private get db() {
        if (this.idb.status !== DBStatus.Ready) throw new Error('Database not ready');

        return this.idb.db;
    }
}
