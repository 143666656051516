import { declareAtom, declareEffect } from '@flatom/core';

import { billEffects, createBill, CreateBillStatus, ICreateResult, BillOperationType, IBill } from 'src/bills';
import { openNotification } from 'src/dialog';
import { TransactionType } from 'src/domain/transaction.types';
import { getDayDate } from 'src/models/dates';
import { paths } from 'src/paths';
import { historyToken } from 'src/store';

export enum ScanQrCodeStatus {
    NoCode = 'no-code',
    InvalidFiscalCode = 'invalid-fiscal-code',
    CodeAlreadyExists = 'already-exists',
}

interface IScanQrPageState {
    status: ScanQrCodeStatus;
    data: string;
    bill?: IBill;
    message?: string;
}

const NS = 'ScanQrPage';

export const ScanQrPageAtom = declareAtom<IScanQrPageState>(NS, {
    status: ScanQrCodeStatus.NoCode,
    data: '',
})({
    setData(state, data: string) {
        if (state.data === data) {
            return state;
        }

        return {
            ...state,
            data,
        };
    },
    alreadyExists(state, _: void) {
        return {
            ...state,
            status: ScanQrCodeStatus.CodeAlreadyExists,
        };
    },
    setBill(state, bill: IBill) {
        return {
            ...state,
            bill,
        };
    },
    setInvalid(state, message: string) {
        return {
            status: ScanQrCodeStatus.InvalidFiscalCode,
            data: state.data,
            message,
        };
    },
    noCode(state, _: void) {
        if (!state.data && state.status === ScanQrCodeStatus.NoCode) {
            return state;
        }

        return {
            ...state,
            status: ScanQrCodeStatus.NoCode,
            data: '',
        };
    },
});

export const processQrCode = declareEffect<string>(
    NS + ':process',
    async ({ getService, dispatch, getState }, data): Promise<CreateBillStatus> => {
        if (getState(ScanQrPageAtom).data === data) {
            // Код не изменился с последнего сканирования, никаких действий не требуется
            return;
        }

        const history = getService(historyToken);

        await dispatch(ScanQrPageAtom.a.setData(data));

        const { status, bill }: ICreateResult = await dispatch(createBill(data));

        switch (status) {
            case CreateBillStatus.Created:
                const params = new URLSearchParams(history.location.search);

                dispatch(billEffects.fetchBill({ bill }));

                history.replace(
                    paths.transactions.add({
                        account: params.get('account'),
                        amount: bill.amount.amount,
                        type:
                            bill.type === BillOperationType.ВозвратПрихода
                                ? TransactionType.Income
                                : TransactionType.Expense,
                        date: getDayDate(new Date(bill.ts * 1000)),
                        qrCode: bill.id,
                    }),
                );

                return CreateBillStatus.Created;
            case CreateBillStatus.AlreadyExists:
                dispatch(ScanQrPageAtom.a.alreadyExists());
                dispatch(openNotification({ title: 'Этот чек уже имеется в базе' }));
                dispatch(ScanQrPageAtom.a.setInvalid('Этот чек уже имеется в базе'));

                return CreateBillStatus.AlreadyExists;
            case CreateBillStatus.Invalid:
                dispatch(openNotification({ title: 'Некорректный чек' }));
                dispatch(ScanQrPageAtom.a.setInvalid('Некорректный чек'));

                return CreateBillStatus.Invalid;
            case CreateBillStatus.UnknownError:
                dispatch(ScanQrPageAtom.a.setInvalid('Неизвестная ошибка'));

                return CreateBillStatus.UnknownError;
        }
    },
);
