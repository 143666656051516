export function fileSizeToHuman(size: number): string {
    if (!Number.isFinite(size)) {
        return '';
    }

    let symbol = 'b';
    let value = size;

    if (size < 1024) {
        symbol = 'b';
        value = size;
    } else if (size < 1_048_576) {
        symbol = 'Kb';
        value = size / 1024;
    } else if (size < 1_073_741_824) {
        symbol = 'Mb';
        value = size / 1_048_576;
    } else {
        symbol = 'Gb';
        value = size / 1_073_741_824;
    }

    return (Math.floor(value * 10) / 10).toString() + symbol;
}
