import React, { useEffect } from 'react';

import { useAction } from '@flatom/react';
import { useParams } from 'react-router';

import { Header } from 'src/components/Header';
import { useViewAtom } from 'src/libs/flatom/view-atom';
import { LoaderBlock } from 'src/ui-kit/LoaderBlock';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';

import { BillNativeView } from '../../components/BillNativeView';
import { billEffects } from '../../model';
import { IBill } from '../../types';

import { IParams, ViewBillPageAtom } from './view.atom';

export function ViewBillPage() {
    const params = useParams<IParams>();
    const loadBill = useAction(billEffects.loadBill);

    const { loading, bill } = useViewAtom(ViewBillPageAtom, params);

    useEffect(() => {
        loadBill(params.billId);
    }, []);

    return (
        <ViewBillPageComponent
            bill={bill}
            loading={loading}
        />
    );
}

interface IProps {
    loading: boolean;
    bill?: IBill;
}

export function ViewBillPageComponent({ bill, loading }: IProps) {
    if (loading || !bill) {
        return (
            <>
                <Header title="Карты" />
                <Main>
                    <LoaderBlock />
                </Main>
            </>
        );
    }

    return (
        <>
            <Header title="Просмотр чека" />
            <Main paddingTop>
                <BillNativeView bill={bill} />
            </Main>
            <NavBar />
        </>
    );
}
