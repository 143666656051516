import { declareEffects } from '@flatom/core';
import { actions } from '@flatom/forms';

import { DayDate } from 'src/domain/date.types';
import { dateToDayDate, isMonthDate, isMonthEqual } from 'src/models/common/date.utils';
import { addTransaction } from 'src/models/transaction/transaction.actions';
import { ITransactionForm } from 'src/models/transaction/transaction.types';
import { paths } from 'src/paths';
import { history } from 'src/store';

import { TransactionAddPageAtom } from './AddPage.atom';

export const transactionsAddPageEffects = declareEffects(TransactionAddPageAtom, {
    submit({ dispatch }, data: ITransactionForm) {
        dispatch(addTransaction(data));
        history.replace(paths.account.month(data.account, data.date as any));
    },
    prefill({ dispatch }, data: ITransactionForm) {
        if (isMonthDate(data.date)) {
            const currentDate = dateToDayDate(new Date());

            if (isMonthEqual(data.date, currentDate)) {
                data.date = currentDate;
            } else {
                data.date = (data.date + '-01') as DayDate;
            }
        }

        dispatch(
            actions.setValue({
                path: 'add-tx',
                value: data,
            }),
        );
    },
});
