import { isValid, parse } from 'date-fns';
import { format } from 'date-fns/fp';

import { BillOperationType, IQrCodePayload } from '../types';

export interface IQrCode {
    raw: string;
    normalized: string;
    timestamp: number;
    amount: string;
    fn: string;
    i: number;
    fp: string;
    type: BillOperationType;
}

export function decodeQrCode(uri: string): IQrCode | undefined {
    if (!uri) {
        return;
    }

    const data = getSearchParams(uri);

    if (!isQrCodePayload(data)) {
        return;
    }

    const baseDate = new Date();

    baseDate.setSeconds(0);
    let date = parse(data.t, "yyyyLLdd'T'HHmm", baseDate);

    if (!isValid(date)) {
        date = parse(data.t, "yyyyLLdd'T'HHmmss", baseDate);
    }

    if (!isValid(date)) {
        return;
    }

    let fn = data.fn;

    if (fn.length < 16) fn = '0'.repeat(16 - fn.length) + fn;

    const normalized = {
        t: format("yyyyLLdd'T'HHmmss", date),
        s: data.s,
        fn,
        i: +data.i,
        fp: data.fp,
        n: +data.n,
    };

    return {
        raw: uri,
        timestamp: Math.floor(date.getTime() / 1000),
        amount: data.s,
        fn,
        i: +data.i,
        fp: data.fp,
        type: +data.n as BillOperationType,
        normalized: requiredKeys.map((key) => `${key}=${normalized[key]}`).join('&'),
    };
}

function getSearchParams(uri: string): unknown {
    const searchParams: Record<string, string> = {};
    const searchParamsString = getSearchString(uri);

    searchParamsString.split('&').forEach((part) => {
        const [key, value] = part.split('=');

        searchParams[key] = value;
    });

    return searchParams;
}

function getSearchString(url: string) {
    const delimiter = url.indexOf('?');

    if (delimiter === -1) return url;

    return url.substring(delimiter + 1);
}

const requiredKeys = ['t', 's', 'fn', 'i', 'fp', 'n'];

function isQrCodePayload(data: unknown): data is IQrCodePayload {
    return requiredKeys.every((key) => typeof data[key] === 'string');
}
