import { createSyncValidator } from '@flatom/forms';

import { Money } from 'src/models/money';

export const moneyValidator = createSyncValidator('money', (min?: Money, max?: Money) => (value: Money) => {
    if (!min) {
        return value.subunits
            ? null
            : {
                  required: {},
              };
    }

    if (value.isLessThen(min)) {
        return {
            min: {
                actual: value.toString(),
                min: min.toString(),
            },
        };
    }

    if (max && value.isMoreThen(max)) {
        return {
            max: {
                actual: value.toString(),
                max: max.toString(),
            },
        };
    }

    return null;
});
