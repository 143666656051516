import React from 'react';

import block from 'bem-css-modules';

import { DateTime, RelativeDateTime } from 'src/components/date';
import { Money } from 'src/models/money';

import { BillStatus, IBill } from '../types';
import { billStatusTitle, operationTypeTitle } from '../utils';

import styles from './BillNativeView.module.scss';

interface IProps {
    bill: IBill;
}

const b = block(styles, 'BillNativeView');
const bi = block(styles, 'item');

export function BillNativeView({ bill }: IProps): JSX.Element {
    const { details, status, error, dateTime, totalSum, items, operationType } = bill;

    if (status !== BillStatus.Found) {
        return (
            <div className={b()}>
                <div className={b('billTitle')}>
                    <span>Кассовый чек.</span> <span>{operationTypeTitle[operationType]}</span>
                </div>
                <div className={b('center')}>
                    <DateTime format="numberDateTime">{dateTime}</DateTime>
                </div>
                <div className={b('status')}>{billStatusTitle[status]}</div>
                {error && (
                    <div className={b('error')}>
                        <div className={b('errorMessage')}>{error.message}</div>
                        <div className={b('errorLastTry')}>
                            <span>Последняя попытка</span>
                            <RelativeDateTime>{error.lastTry}</RelativeDateTime>
                        </div>
                    </div>
                )}
                <ul className={b('items')}>
                    {items.map((item, index) => (
                        <li
                            key={index}
                            className={bi()}
                        >
                            <div className={bi('index')}>{index + 1}.</div>
                            <div className={bi('name')}>{item.name}</div>
                            <div className={bi('price')}>
                                {item.quantity} x {item.price.toString()}
                            </div>
                            <div className={bi('sum')}>{item.sum.toString()}</div>
                        </li>
                    ))}
                </ul>
                <div className={b('totalSum')}>
                    <div className={b('tagName', { uppercase: true })}>Итого:</div>
                    <div className={b('value')}>{totalSum.toString()}</div>
                </div>
            </div>
        );
    }

    return (
        <div className={b()}>
            <div className={b('retailPlace')}>{details.retailPlace}</div>
            <div className={b('address')}>{details.orgName}</div>
            <div className={b('address')}>{details.retailPlaceAddress}</div>
            <div className={b('billTitle')}>
                <span>Кассовый чек.</span> <span>{operationTypeTitle[operationType]}</span>
            </div>
            <div className={b('center')}>
                <DateTime format="numberDateTime">{dateTime}</DateTime>
            </div>
            {details.requestNumber && <div className={b('center')}>Чек № {details.requestNumber}</div>}
            {details.shiftNumber && <div className={b('center')}>Смена № {details.shiftNumber}</div>}
            {details.operator && <div className={b('center')}>Кассир {details.operator}</div>}
            <ul className={b('items')}>
                {items.map((item, index) => (
                    <li
                        key={index}
                        className={bi()}
                    >
                        <div className={bi('index')}>{index + 1}.</div>
                        <div className={bi('name')}>{item.name}</div>
                        <div className={bi('price')}>
                            {item.quantity} x {item.price.toString()}
                        </div>
                        <div className={bi('sum')}>{item.sum.toString()}</div>
                    </li>
                ))}
            </ul>
            <div className={b('totalSum')}>
                <div className={b('tagName', { uppercase: true })}>Итого:</div>
                <div className={b('value')}>{totalSum.toString()}</div>
            </div>
            <div className={b('subSum')}>
                <div className={b('tagName')}>Наличные</div>
                <div className={b('value')}>{details.cashTotalSum.toString()}</div>
            </div>
            <div className={b('subSum', { withSpace: true })}>
                <div className={b('tagName')}>Карта</div>
                <div className={b('value')}>{details.ecashTotalSum.toString()}</div>
            </div>
            {Money.isEmpty(details.nds20) || (
                <div className={b('subSum')}>
                    <div className={b('tagName')}>НДС 20%</div>
                    <div className={b('value')}>{details.nds20.toString()}</div>
                </div>
            )}
            {Money.isEmpty(details.nds10) || (
                <div className={b('subSum')}>
                    <div className={b('tagName')}>НДС 10%</div>
                    <div className={b('value')}>{details.nds10.toString()}</div>
                </div>
            )}
            {Money.isEmpty(details.nds0) || (
                <div className={b('subSum')}>
                    <div className={b('tagName')}>НДС 0%</div>
                    <div className={b('value')}>{details.nds0.toString()}</div>
                </div>
            )}
            {Money.isEmpty(details.noNds) || (
                <div className={b('subSum')}>
                    <div className={b('tagName')}>Без НДС</div>
                    <div className={b('value')}>{details.noNds.toString()}</div>
                </div>
            )}
            <div className={b('tagList')}>
                <div>ККТ {details.kktRegId}</div>
                <div>ФН {details.fiscalDriveNumber}</div>
                <div>ФП {details.fiscalSign}</div>
                <div>ФД {details.fiscalDocumentNumber}</div>
                <div>ИНН {details.orgINN}</div>
            </div>
        </div>
    );
}
