import { declareAtom } from '@flatom/core';

import { IConfirmDialog, IDialogState, INotifyDialog } from './dialog.types';

const initialState: IDialogState = {
    show: false,
    title: '',
    text: '',
    buttons: [],
    action: '',
};

export const DialogAtom = declareAtom<IDialogState>(
    'dialog',
    initialState,
)({
    close(_1, _2: void) {
        return initialState;
    },
    confirm(state, payload: IConfirmDialog) {
        const { title, text = '', yesButton = 'Да', noButton = 'Нет' } = payload;

        return {
            ...initialState,
            show: true,
            title,
            text,
            buttons: [
                {
                    name: 'yes',
                    label: yesButton,
                },
                {
                    name: 'no',
                    label: noButton,
                },
            ],
        };
    },
    notify(state, { title, text, okButton }: INotifyDialog) {
        return {
            ...initialState,
            show: true,
            title,
            text: '',
            buttons:
                okButton === false
                    ? []
                    : [
                          {
                              label: okButton || 'Ok',
                              name: 'ok',
                          },
                      ],
        };
    },
    warning(state, { title, text, okButton }: INotifyDialog) {
        return {
            ...initialState,
            show: true,
            title,
            text: '',
            buttons:
                okButton === false
                    ? []
                    : [
                          {
                              label: okButton || 'Ok',
                              name: 'ok',
                          },
                      ],
        };
    },
    action(state, action: string) {
        return {
            ...state,
            action,
        };
    },
});
