/**
 * @module bills/model
 */

import { declareEffect, declareEffects } from '@flatom/core';

import { openNotification } from 'src/dialog/model/dialog.effects';
import { TimeStamp } from 'src/libs/time';
import { ScanQrPageAtom } from 'src/transactions/pages/ScanQrPage.atom';
import { objectIDServiceToken } from 'src/utils/object-id/object-id.types';

import { BillID, BillStatus, IBill } from '../types';
import { createBillByQrCode, decodeBillDto, parseProverkaChekaCom } from '../utils';

import { BillAtom } from './bill.atom';
import { billsServiceToken } from './bills.service';

const NS = 'bills/effects';

export enum CreateBillStatus {
    Created,
    AlreadyExists,
    Invalid,
    UnknownError,
}

export interface ICreateResult {
    status: CreateBillStatus;
    billID?: BillID;
    bill?: IBill;
    error?: unknown;
}

export const billEffects = declareEffects(NS, {
    async loadBill({ dispatch, getService }, billId: BillID): Promise<IBill> {
        const service = getService(billsServiceToken);

        return service.getById(billId).then(
            (dto) => {
                const bill = decodeBillDto(dto);

                dispatch(BillAtom.actions.setBill(bill));

                return bill;
            },
            (err) => {
                dispatch(BillAtom.actions.notFound());

                throw err;
            },
        );
    },
    async fetchBill(
        { dispatch, getService },
        { bill, force }: { bill: IBill; force?: boolean },
    ): Promise<IBill | null> {
        const token = localStorage.getItem('proverkacheka_token');

        if (!token) {
            return null;
        }

        const service = getService(billsServiceToken);

        await service.saveBill({ ...bill, status: BillStatus.Pending });
        await dispatch(billEffects.loadBill(bill.id));

        if (!bill.rawData || bill.status !== BillStatus.Found || force) {
            try {
                const rawData = await service.fetchBillData(bill.rawQrCode, token);

                bill = {
                    ...bill,
                    rawData,
                };
                await service.saveBill(bill);
            } catch (e) {
                bill = {
                    ...bill,
                    status: BillStatus.FailedToFetch,
                };
                await service.saveBill(bill);

                dispatch(
                    openNotification({
                        title: 'Не удалось получить детальные данные чека',
                        text: 'Чек будет сохранен, данные можно будет запросить позже',
                    }),
                );

                return;
            }
        }

        const rawData = bill.rawData;

        if (rawData.code !== 1) {
            const message =
                [
                    'Чек некорректен',
                    'Данные чека получены',
                    'Данные чека пока не получены',
                    'Превышено кол-во запросов',
                    'Ожидание перед повторным запросом ранее неудачно запрошенного чека',
                ][rawData.code] || 'Данные не получены';

            bill = {
                ...bill,
                status: BillStatus.FailedToFetch,
                error: {
                    lastTry: Math.floor(new Date().getTime() / 1000) as TimeStamp,
                    message,
                },
            };
            await service.saveBill(bill);

            dispatch(
                openNotification({
                    title: message,
                }),
            );

            await dispatch(billEffects.loadBill(bill.id));

            return bill;
        }

        try {
            const { items, details } = parseProverkaChekaCom(rawData);

            bill = {
                ...bill,
                status: BillStatus.Found,
                error: undefined,
                items,
                details,
                rawData,
            };
            await service.saveBill(bill);
        } catch (e) {
            bill = {
                ...bill,
                status: BillStatus.InvalidData,
            };
            await service.saveBill(bill);

            alert('Failed to decode bill');
        }

        await dispatch(billEffects.loadBill(bill.id));

        return bill;
    },
});

export const createBill = declareEffect(
    NS + ':createBill',
    async ({ getService, dispatch }, raw: string): Promise<ICreateResult> => {
        const oid = getService(objectIDServiceToken);
        const service = getService(billsServiceToken);

        const id = oid.generate<BillID>();

        const { bill, dto } = createBillByQrCode(id, raw);

        if (!bill) {
            return {
                status: CreateBillStatus.Invalid,
            };
        }

        try {
            await service.insert(dto);

            return {
                status: CreateBillStatus.Created,
                billID: id,
                bill,
            };
        } catch (error) {
            if (error instanceof Error && error.name === 'ConstraintError') {
                return {
                    status: CreateBillStatus.AlreadyExists,
                };
            } else {
                openNotification({
                    title: 'error',
                });

                return {
                    status: CreateBillStatus.UnknownError,
                    error,
                };
            }
        }
    },
);

export const noCodeFound = declareEffect(NS + ':noCode', async ({ dispatch }) => {
    await dispatch(ScanQrPageAtom.a.noCode());

    return dispatch(openNotification({ title: 'Не найден QR Code на изображении' }));
});

export const noCameras = declareEffect(NS + ':noCameras', async ({ dispatch }) => {
    return dispatch(openNotification({ title: 'Не доступа к камере' }));
});
