import React, { ReactNode } from 'react';

import { useField } from '@flatom/forms-react';
import block from 'bem-css-modules';

import { Icon } from '../Icon';
import { Icons } from '../Icons';
import { Showable } from '../Showable';

import styles from './BaseField.module.scss';

const b = block(styles, 'field');

export interface IBaseFieldProps {
    name: string;
    label: string;
    clearable?: boolean;
    placeholder?: string;
    hint?: string;
}

export const BaseField = ({
    name,
    label,
    placeholder,
    hint,
    children,
    clearable,
}: IBaseFieldProps & { children: ReactNode }) => {
    const field = useField(name);

    const onClearInput = () => {
        alert('clear');
    };

    const showError = field.invalid && field.touched;

    return (
        <div className={b({ error: showError && !field.focused, focused: field.focused })}>
            <div className={b('input-wrapper')}>
                <label
                    className={b('label')}
                    htmlFor={field.path}
                >
                    {label}
                </label>
                {children}
                {clearable && (
                    <i
                        className="clear-input"
                        onClick={onClearInput}
                    >
                        <Icon
                            icon={Icons.clear}
                            className="md hydrated"
                        />
                    </i>
                )}
            </div>
            <div className={b('error', { show: showError })}>
                <Showable show={showError}>{field.firstErrorMessage}</Showable>
                &nbsp;
            </div>
            <div className={b('hint', { error: showError })}>{hint}&nbsp;</div>
        </div>
    );
};
