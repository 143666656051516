import { DayDate } from 'src/domain/date.types';
import { Money } from 'src/models/money';

import { IDayGrip, ITransactionGrip } from './grip.types';
import { TransactionGrip } from './transaction-grip.class';

export abstract class AbstractDayGrip implements IDayGrip {
    readonly balance: Money;
    readonly balanceOnEnd: Money;
    readonly balanceOnStart: Money;
    readonly date: DayDate;
    readonly expense: Money;
    readonly income: Money;
    readonly transactions: TransactionGrip[];

    protected constructor(date: DayDate, balanceOnStart: Money, gripTransactions: ITransactionGrip[]) {
        // base
        this.date = date;
        this.transactions = gripTransactions.map((item) => new TransactionGrip(item));

        // balance
        this.balanceOnStart = balanceOnStart;
        this.balance = this.transactions.reduce((sum, tx) => sum.add(tx.changeAmount), Money.create(0, 'RUB'));
        this.balanceOnEnd = this.balanceOnStart.add(this.balance);
        this.income = this.transactions
            .filter((tx) => tx.changeAmount.isPositive())
            .reduce((sum, tx) => sum.add(tx.changeAmount), Money.create(0, 'RUB'));
        this.expense = this.transactions
            .filter((tx) => tx.changeAmount.isNegative())
            .reduce((sum, tx) => sum.add(tx.changeAmount), Money.create(0, 'RUB'))
            .negative();

        // собрать информацию с транзакций, заполнить categories, accounts(?), balance, balanceOnEnd
    }
}
