import { AccountID } from 'src/domain/account.types';
import { AbstractGrip } from 'src/models/abstract-grip/grip.class';
import { IMonthGripBrief } from 'src/models/abstract-grip/grip.types';
import { IAccount } from 'src/models/account-dto/account.types';
import { Money } from 'src/models/money';
import { IMonth, IMonthBrief } from 'src/models/month/month-legacy.types';

import { AccountMonthGrip } from './month-grip.class';

export class AccountGrip extends AbstractGrip {
    public readonly account: IAccount;

    constructor(account: IAccount) {
        super([account]);

        this.id = account.id as AccountID;
        this.account = account;
        this.name = account.name;

        if (!account.head) return;

        this.lastMonthDate = account.head.month;
        this.firstMonthDate = account.months[account.months.length - 1].month;
        this.months = account.months.map(makeMonthGripBriefFromMonthBrief);
        this.balance = account.head.summary.balanceOnEnd;
        this.income = account.months.reduce((sum, month) => sum.add(month.summary.income), Money.create(0, 'RUB'));
        this.expense = account.months.reduce((sum, month) => sum.add(month.summary.expense), Money.create(0, 'RUB'));
    }

    public makeMonth(month: IMonth): AccountMonthGrip {
        return new AccountMonthGrip(month.summary.balanceOnStart, month, this.account);
    }
}

function makeMonthGripBriefFromMonthBrief(month: IMonthBrief): IMonthGripBrief {
    return {
        id: month.id,
        month: month.month,
        balanceOnStart: month.summary.balanceOnStart,
        balanceOnEnd: month.summary.balanceOnEnd,
        balance: month.summary.balance,
        income: month.summary.income,
        expense: month.summary.expense,
    };
}
