import React, { useEffect } from 'react';

import { FormValidators, ValidationErrorMessages } from '@flatom/forms';
import { useForm } from '@flatom/forms-react';
import { useAtom, useActions } from '@flatom/react';
import { useLocation } from 'react-router';

import { CardID } from 'src/cards/model/card.types';
import { CategoryID } from 'src/categories';
import { Header } from 'src/components/Header';
import { useCameraStatus } from 'src/hooks';
import { Card } from 'src/ui-kit/Card';
import { Main } from 'src/ui-kit/Main';
import { Section } from 'src/ui-kit/Section';
import { moneyValidator } from 'src/utils/validators/money';
import { addTransactionFormSchema } from 'src/widgets/AddTransaction.form';
import { AddTransactionForm } from 'src/widgets/AddTransactionForm';
import { NavBar } from 'src/widgets/NavBar';

import { buildTransaction } from '../utils/add.helper';

import { TransactionAddPageAtom } from './AddPage.atom';
import { transactionsAddPageEffects } from './AddPage.effects';

const errorMessages: ValidationErrorMessages = {
    required: 'Поле обязательно к заполнению',
    children: 'Пожалуйста, заполните все поля',
    min: 'Значение не может быть меньше {min}',
    max: 'Значение не может быть больше {max}',
};

const formValidators: FormValidators = [moneyValidator];

export const TransactionAddPage = () => {
    const { hasCamera } = useCameraStatus();
    const params = new URLSearchParams(useLocation().search);
    const { accounts, categories } = useAtom(TransactionAddPageAtom);
    const { submit, prefill } = useActions(transactionsAddPageEffects);

    const form = useForm(addTransactionFormSchema, formValidators, errorMessages);

    useEffect(() => {
        const tx = buildTransaction(params);

        prefill({
            title: tx.title,
            type: tx.type,
            amount: tx.amount,
            date: tx.date,
            account: tx.account as CardID,
            category: tx.category as CategoryID,
            qrCode: tx.qrCode,
        });
    }, []);

    if (!accounts || !categories)
        return (
            <>
                <Header
                    title="Добавление операции"
                    back
                />
                <Main>
                    <Section full>
                        <Card>Loading...</Card>
                    </Section>
                </Main>
            </>
        );

    return (
        <>
            <Header
                title="Добавление операции"
                back
            />
            <Main>
                <Section full>
                    <Card>
                        <AddTransactionForm
                            form={form}
                            onSubmit={submit}
                            accounts={accounts}
                            categories={categories}
                        />
                    </Card>
                </Section>
            </Main>
            <NavBar
                scanQr={hasCamera}
                account={form.get('account').value}
                active="new-tx"
            />
        </>
    );
};
