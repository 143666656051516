interface Handlers {
    [key: string]: (actions: { event: any; resolve: (r) => void; reject: (e) => void }) => void;
}

export function promisify<T = any>(request: IDBRequest, handlers: Handlers): Promise<T> {
    let events: string[];
    let finalHandlers: { [key: string]: (event: any) => void };

    return new Promise<T>((resolve, reject) => {
        finalHandlers = {
            success: (event: any) => resolve(event.target),
            blocked: (error) => reject(new Error('[db] Database blocked')),
            error: (event: any) => {
                reject(event.target.error);
            },
        };

        Object.keys(handlers).forEach((key) => {
            finalHandlers[key] = (event) => handlers[key]({ event, resolve, reject });
        });

        events = Object.keys(finalHandlers);
        events.forEach((event) => request.addEventListener(event, finalHandlers[event]));
    }).finally(() => {
        events.forEach((event) => request.removeEventListener(event, finalHandlers[event]));
    });
}
