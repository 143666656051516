import { declareEffect } from '@flatom/core';
import { v4 } from 'uuid';

import { TransactionType } from 'src/domain/transaction.types';

import { CategoryID, ICategoryDTO, ICategoryForm } from '../types';

import { CategoriesAtom } from './categories.atom';
import { categoriesServiceToken } from './categories.service';

const NS = 'categories-effects';

export const loadCategories = declareEffect<{ checkDefault: boolean } | void>(
    NS + ':loadCategories',
    async ({ getService, dispatch }, options) => {
        const { checkDefault = false } = options || {};
        const service = getService(categoriesServiceToken);
        const list = await service.getAll();

        if (checkDefault) {
            dispatch(checkDefaultCategories());
        }

        return dispatch(CategoriesAtom.a.setList(list));
    },
);

export const addCategory = declareEffect(
    NS + ':addCategory',
    async ({ getService, getState, dispatch }, payload: ICategoryForm) => {
        const service = getService(categoriesServiceToken);
        const { list } = getState(CategoriesAtom);

        let id: string;
        const checkDuplicates = () => list.find((item) => item.id === id);

        do {
            id = v4();
        } while (checkDuplicates());

        const category: ICategoryDTO = {
            id,
            name: payload.name,
            parent: payload.parent || null,
            icon: payload.icon || 'default',
            defaultTxType: payload.defaultTxType || TransactionType.Expense,
            isInitial: false,
        };

        await service.insert(category);

        await dispatch(loadCategories());

        const { list: result } = getState(CategoriesAtom);

        return result.find((item) => item.id === id);
    },
);

export const updateCategory = declareEffect(
    NS + ':updateCategory',
    async ({ getService, getState, dispatch }, payload: ICategoryForm) => {
        const service = getService(categoriesServiceToken);
        const { list } = getState(CategoriesAtom);

        const category = list.find((item) => item.id === payload.id);

        if (!category) {
            throw new Error('Failed to update category which does not exists');
        }

        const parent = list.find((item) => item.id === payload.parent);

        if (payload.parent && !parent) {
            throw new Error('Invalid parent category');
        }

        category.name = payload.name;
        category.parent = payload.parent ? parent.id : null;
        category.icon = payload.icon || 'default';
        category.defaultTxType = payload.defaultTxType || TransactionType.Expense;

        await service.update(category);

        return dispatch(loadCategories());
    },
);

export const checkDefaultCategories = declareEffect(
    NS + ':checkDefaultCategories',
    async ({ getService, dispatch }) => {
        const categoriesService = getService(categoriesServiceToken);
        const { defaultCategories } = await import('../services/default-categories');
        const currentCategories = await categoriesService.getAll();
        const currentIds = currentCategories.map((item) => item.id);

        await Promise.all(
            defaultCategories.map((item): Promise<void | CategoryID> => {
                if (currentIds.includes(item.id)) {
                    return Promise.resolve();
                }

                return categoriesService.insert(item);
            }),
        );

        return dispatch(loadCategories());
    },
);
