import { useEffect } from 'react';

export function useScrollTo(prefix: string, elementId: string, dep: any) {
    useEffect(() => {
        if (!elementId) return;

        const id = prefix + elementId;
        const el = document.getElementById(id);

        if (el)
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
    }, [prefix, elementId, dep]);
}
