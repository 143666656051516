import { declareAtom } from '@flatom/core';

import { setOnlineStatus } from './client.actions';
import { IClientState } from './client.types';

declare global {
    interface Window {
        appVersion: string;
        appReleaseTimestamp: string;
    }
}

const [version, hexVersion] = (window.appVersion ?? '0.0.0-dev').split('-');
const releaseDate = new Date(window.appReleaseTimestamp);

export const Client = declareAtom<IClientState>(['client'], {
    online: false,
    version,
    hexVersion,
    releaseDate,
})((on) => ({
    online: [on(setOnlineStatus, (state, online) => ({ ...state, online }))],
}));
