import React from 'react';

import { useAtom } from '@flatom/react';

import { CardListAtom } from 'src/cards/model/card.atom';
import { Header } from 'src/components/Header';
import { MoneyView } from 'src/components/MoneyView';
import { Link, paths } from 'src/paths';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';

import { BackupPart } from './BackupPart';
import { StatisticsPart } from './Statistics';
import { StoragePart } from './storage';
import { VersionPart } from './VersionPart';

export const MainMenu = () => {
    const cards = useAtom(CardListAtom);

    return (
        <>
            <Header
                title="Меню"
                back
            />
            <Main>
                <div className="listview-title mt-1">Карты</div>
                <ul
                    className="listview text inset"
                    style={{ marginLeft: '0', marginRight: '0' }}
                >
                    {cards.map((card) => (
                        <li key={card.id}>
                            <div className="item">
                                <div className="in">
                                    <div>{card.name}</div>
                                    <span className="text-muted">
                                        <MoneyView money={card.balance} />
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))}
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    <Link to={paths.account.create()}>Добавить карту</Link>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <StatisticsPart />

                <BackupPart />

                <StoragePart />

                <VersionPart />

                {/*<div className="listview-title mt-1">Notifications</div>*/}
                {/*<ul className="listview image-listview text inset">*/}
                {/*    <li>*/}
                {/*        <div className="item">*/}
                {/*            <div className="in">*/}
                {/*                <div>*/}
                {/*                    Payment Alert*/}
                {/*                    <div className="text-muted">*/}
                {/*                        Send notification when new payment received*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="custom-control custom-switch">*/}
                {/*                    <input type="checkbox" className="custom-control-input" id="customSwitch4"/>*/}
                {/*                    <label className="custom-control-label" htmlFor="customSwitch4"/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <a href="#" className="item">*/}
                {/*            <div className="in">*/}
                {/*                <div>Notification Sound</div>*/}
                {/*                <span className="text-primary">Beep</span>*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*</ul>*/}

                {/*<div className="listview-title mt-1">Profile Settings</div>*/}
                {/*<ul className="listview image-listview text inset">*/}
                {/*    <li>*/}
                {/*        <a href="#" className="item">*/}
                {/*            <div className="in">*/}
                {/*                <div>Change Username</div>*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <a href="#" className="item">*/}
                {/*            <div className="in">*/}
                {/*                <div>Update E-mail</div>*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <a href="#" className="item">*/}
                {/*            <div className="in">*/}
                {/*                <div>Address</div>*/}
                {/*                <span className="text-primary">Edit</span>*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <div className="item">*/}
                {/*            <div className="in">*/}
                {/*                <div>*/}
                {/*                    Private Profile*/}
                {/*                </div>*/}
                {/*                <div className="custom-control custom-switch">*/}
                {/*                    <input type="checkbox" className="custom-control-input" id="customSwitch2"/>*/}
                {/*                    <label className="custom-control-label" htmlFor="customSwitch2"/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </li>*/}
                {/*</ul>*/}

                {/*<div className="listview-title mt-1">Security</div>*/}
                {/*<ul className="listview image-listview text mb-2 inset">*/}
                {/*    <li>*/}
                {/*        <a href="#" className="item">*/}
                {/*            <div className="in">*/}
                {/*                <div>Update Password</div>*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <div className="item">*/}
                {/*            <div className="in">*/}
                {/*                <div>*/}
                {/*                    2 Step Verification*/}
                {/*                </div>*/}
                {/*                <div className="custom-control custom-switch">*/}
                {/*                    <input type="checkbox" className="custom-control-input" id="customSwitch3"/>*/}
                {/*                    <label className="custom-control-label" htmlFor="customSwitch3"/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <a href="#" className="item">*/}
                {/*            <div className="in">*/}
                {/*                <div>Log out all devices</div>*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*</ul>*/}
            </Main>
            <NavBar />
        </>
    );
};
