import { useEffect, useState } from 'react';

import { DBStatus } from 'src/libs/indexed-db';
import { BlockedError } from 'src/libs/indexed-db/errors';

import { db } from './db';

export function useDBReady(onReady: () => void, onFail: (error: Error) => void) {
    console.log('[db hook] useDBReady');
    const [, setStatus] = useState(DBStatus.Initializing);

    const onChangeStatusEvent = (status: DBStatus) => {
        console.log(`[db hook] ${DBStatus[status]}`);
        setStatus(status);
        // if (status === DBStatus.Ready) onReady();
        if (status === DBStatus.Blocked) onFail(new BlockedError());
    };

    useEffect(() => {
        db.addStatusListener(onChangeStatusEvent);
        db.open().then(
            () => onReady(),
            (error) => onFail(error),
        );

        return () => db.removeStatusListener(onChangeStatusEvent);
    }, []);

    return db.status;
}
