import React from 'react';

import { useAtom } from '@flatom/react';
import { useRouteMatch } from 'react-router';

import { CategoryIcon } from 'src/components/CategoryIcon';
import { Header } from 'src/components/Header';
import { Link, paths } from 'src/paths';
import { HeaderAction } from 'src/ui-kit/HeaderAction';
import { Icon } from 'src/ui-kit/Icon';
import { Icons } from 'src/ui-kit/Icons';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';

import { CategoriesAtom } from '../model/categories.atom';
import { CategoryID } from '../types';

interface IParams {
    categoryId: CategoryID;
}

export const CategoriesSubListPage = () => {
    const { loading, tree } = useAtom(CategoriesAtom);
    const { categoryId } = useRouteMatch<IParams>().params;

    const root = tree.find((item) => item.id === categoryId);

    if (loading)
        return (
            <>
                <Header
                    title="Категории"
                    back
                >
                    <Link to="/">
                        <Icon icon={Icons.addOutline} />
                    </Link>
                </Header>
                <Main>Loading</Main>
            </>
        );

    if (!root)
        return (
            <>
                <Header
                    title="Категории"
                    back
                />
                <Main>Category not found</Main>
                <NavBar />
            </>
        );

    return (
        <>
            <Header
                title={`Категория ${root.name}`}
                back
            >
                <HeaderAction
                    icon={Icons.cog}
                    link={paths.categories.edit(categoryId)}
                />
                <Link to={paths.categories.create(root.id)}>
                    <Icon icon={Icons.addOutline} />
                </Link>
            </Header>
            <Main>
                <ul className="listview image-listview inset mt-2">
                    {root.children.map((category) => (
                        <li key={category.id}>
                            <Link
                                to={paths.categories.view(category.id)}
                                className="item"
                            >
                                <div className="icon-box">
                                    <CategoryIcon icon={category.icon} />
                                </div>
                                <div className="in">
                                    <div>{category.name}</div>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </Main>
            <NavBar />
        </>
    );
};
