export const baseUrl = '';

export function addQueryString(params: { [key: string]: any }): string {
    if (!params) return '';
    if (!Object.keys(params).length) return '';

    Object.keys(params).forEach((key) => params[key] == null && delete params[key]);

    return '?' + new URLSearchParams(params).toString();
}
