import { useAction, useAtom } from '@flatom/react';

import { loadMonths } from 'src/atoms/months/months.actions';
import { Months } from 'src/atoms/months/months.atom';
import { DayDate, MonthDate } from 'src/domain/date.types';
import { AccountMonthGrip } from 'src/models/account-grip/month-grip.class';
import { UUID } from 'src/models/common/common.types';
import { dayDateToMonth } from 'src/models/common/date.utils';

import { useAccount } from './accounts';

export function useMonth(accountId: UUID, dayDate: MonthDate | DayDate) {
    const account = useAccount(accountId);
    const months = useAtom(Months);
    const loadMonth = useAction(loadMonths);

    const monthDate = dayDateToMonth(dayDate);

    if (!account) return null;

    const foundMonthBrief = account.account.months.find((item) => item.month === monthDate);

    if (!foundMonthBrief) return null;

    const monthData = months.get(foundMonthBrief.id);

    if (!monthData) {
        loadMonth([foundMonthBrief.id]);

        return null;
    }

    return new AccountMonthGrip(monthData.summary.balanceOnStart, monthData, account.account);
}

export function useTransaction(accountId: UUID, dayDate: DayDate, txId: UUID) {
    const month = useMonth(accountId, dayDate);

    if (!month) return null;

    const day = month.days.find((item) => item.date === dayDate);

    if (!day) return null;

    return day.transactions.find((item) => item.id === txId);
}
