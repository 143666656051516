import { IOptions } from 'src/ui-kit/inputs/SelectField';

import { ICategory, IRootCategory } from '../types';

export function rootCategoriesToOptionList(roots: IRootCategory[]): IOptions {
    const categories: ICategory[] = [];

    roots.forEach((root) => {
        categories.push(root, ...root.children.map((item) => ({ ...item, name: root.name + ' / ' + item.name })));
    });

    return categories.map((cat) => ({
        value: cat.id,
        label: cat.name,
    }));
}
