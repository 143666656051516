import React, { useEffect } from 'react';

import { useActions, useAtom } from '@flatom/react';

import { FileSize } from 'src/components/FileSize';

import { Storage } from './storage.atom';
import { storageEffects } from './storage.effects';

export const StoragePart = () => {
    const { dataSize, appSize, totalUsage } = useAtom(Storage);

    const effects = useActions(storageEffects);

    useEffect(() => {
        effects.getEstimate();
    }, [effects]);

    return (
        <>
            <div className="listview-title mt-1">Память</div>
            <ul
                className="listview image-listview text inset"
                style={{ marginLeft: '0', marginRight: '0' }}
            >
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Данные</div>
                            <span className="text-muted">
                                <FileSize>{dataSize}</FileSize>
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Приложение</div>
                            <span className="text-muted">
                                <FileSize>{appSize}</FileSize>
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Итого</div>
                            <span className="text-muted">
                                <FileSize>{totalUsage}</FileSize>
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </>
    );
};
