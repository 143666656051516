import { Action } from '@flatom/core';
import { put } from 'redux-saga/effects';

import { updateAccountGrip } from 'src/atoms/account-grips/account-grips.actions';
import { DayDate } from 'src/domain/date.types';
import { dayDateToMonth } from 'src/models/common/date.utils';
import { addTransaction, addTransactionSuccess } from 'src/models/transaction/transaction.actions';
import { Transaction } from 'src/models/transaction/transaction.class';
import { ITransactionForm } from 'src/models/transaction/transaction.types';

import { sagaLauncher } from '../saga-launcher';
import { AccountUtils } from '../utils/account.saga';
import { MonthUtils } from '../utils/month.saga';

sagaLauncher.onAction(addTransaction, addTransactionSaga);

export function* addTransactionSaga(action: Action<ITransactionForm>) {
    // console.log('*** addTransactionSaga started');
    const payload: ITransactionForm = action.payload;
    const account = yield* AccountUtils.select(payload.account);
    const month = yield* MonthUtils.get(account, dayDateToMonth(payload.date as DayDate));

    // console.log('addTransactionSaga', month.id, month.prevVersions);

    let tx = Transaction.create(payload.type, payload.amount, 'RUB');

    if (payload.title) tx = tx.setTitle(payload.title);
    if (payload.category) tx = tx.setCategory(payload.category);
    if (payload.qrCode) tx = tx.setQrCode(payload.qrCode);

    const day = month.getDay(payload.date as DayDate).upsertTransaction(tx);

    const updatedMonth = month.updateDay(day);

    // console.log('updatedMonth', updatedMonth.id, updatedMonth.prevVersions);

    const value = yield* AccountUtils.update(account, updatedMonth);

    // console.log('*** addTransactionSaga complete');
    yield put(updateAccountGrip(value));
    yield put(
        addTransactionSuccess({
            ...payload,
            id: tx.id,
        }),
    );

    return value;
}
