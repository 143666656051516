import { baseUrl } from './config';
import { addQueryString } from './helper';

export const categories = {
    list: () => `${baseUrl}/categories`,
    show: (categoryId = ':categoryId') => `${baseUrl}/categories/${categoryId}`,
    view: (categoryId = ':categoryId') => `${baseUrl}/categories/${categoryId}/view`,
    edit: (categoryId = ':categoryId') => `${baseUrl}/categories/${categoryId}/edit`,
    create: (parent?: string) => `${baseUrl}/categories/create` + addQueryString({ parent }),
};
