import React, { useCallback } from 'react';

import { useHistory, useParams } from 'react-router';

import { Header } from 'src/components/Header';
import { useViewAtom } from 'src/libs/flatom/view-atom';
import { AccountID } from 'src/models/account-dto/account.types';
import { AccountGrip } from 'src/models/account-grip/grip.class';
import { paths } from 'src/paths';
import { LoaderBlock } from 'src/ui-kit/LoaderBlock';
import { Main } from 'src/ui-kit/Main';
import { AccountWidget } from 'src/widgets/AccountWidget';
import { GripQuickDetails } from 'src/widgets/GripQuickDetails';
import { LastMonthsList } from 'src/widgets/LastMonthsList';
import { NavBar } from 'src/widgets/NavBar';
import { SwipeItemWidget, SwipeWidget } from 'src/widgets/SwipeWidget';

import { CardID, ICard } from '../model';

import { ViewCardPageAtom } from './view-card.atom';

const KEY = 'current-account';

export const ViewCardPage = () => {
    const history = useHistory();
    const params = useParams<{ account: AccountID }>();

    const props = useViewAtom(ViewCardPageAtom, params);

    const changeCardHandler = useCallback(
        (id: CardID) => {
            localStorage.setItem(KEY, id);
            history.replace(paths.account.view(id));
        },
        [history],
    );

    return (
        <ViewCardPageComponent
            {...props}
            onChangeCard={changeCardHandler}
        />
    );
};

interface IProps {
    loading: boolean;
    cardID: CardID;
    card?: ICard;
    grip?: AccountGrip;
    cards: ICard[];

    onChangeCard(cardID: CardID): void;
}

export const ViewCardPageComponent = ({ cards, loading, card, grip, cardID, onChangeCard }: IProps) => {
    if (loading) {
        return (
            <>
                <Header title="Карты" />
                <Main>
                    <LoaderBlock />
                </Main>
            </>
        );
    }

    return (
        <>
            <Header title="Карты" />
            <Main>
                <SwipeWidget
                    current={cardID}
                    showButtons
                    onChange={(value) => onChangeCard(value as CardID)}
                >
                    {cards.map((card) => (
                        <SwipeItemWidget key={card.id}>
                            <AccountWidget card={card} />
                        </SwipeItemWidget>
                    ))}
                    {/*<SwipeItemWidget key="create">*/}
                    {/*    <CreateAccountWidget/>*/}
                    {/*</SwipeItemWidget>*/}
                </SwipeWidget>
                <GripQuickDetails grip={grip} />
                {/*<QuickCategories grip={current} />*/}
                <LastMonthsList grip={grip} />
            </Main>
            <NavBar account={cardID} />
        </>
    );
};
