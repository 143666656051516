import { declareAtom } from '@flatom/core';

import { declareMetaAtom } from 'src/libs/flatom/meta-atom';
import { Money } from 'src/models/money';

import { CardID, ICard, ICardCacheDto, ICardDto, ICardState } from './card.types';

export const CardAtom = declareAtom<ICardState>('cards', {
    id: '' as CardID,
    name: '',
    balance: Money.create(0, 'RUB'),
})({
    setCard(state, dto: ICardDto) {
        if (dto.id !== state.id) return state;

        return {
            ...state,
            id: dto.id as CardID,
            name: dto.name,
        };
    },
    setCache(state, dto: ICardCacheDto) {
        if (dto.id !== state.id) return state;

        return {
            ...state,
            balance: Money.from(dto.balance),
        };
    },
});

export const CardsAtom = declareMetaAtom(CardAtom)({
    setCards(metaState, dtos: ICardDto[]) {
        const newMetaState = { ...metaState };
        let isChanged = false;

        dtos.forEach((dto) => {
            const key = dto.id;
            const oldState = newMetaState[key] || { ...CardAtom(undefined, { type: '' }), id: dto.id };
            const state = CardAtom(oldState, CardAtom.a.setCard(dto));

            if (state === oldState) return;

            newMetaState[key] = state;
            isChanged = true;
        });

        return isChanged ? newMetaState : metaState;
    },
    setCardsCache(metaState, dtos: ICardCacheDto[]) {
        const newMetaState = { ...metaState };
        let isChanged = false;

        dtos.forEach((dto) => {
            const key = dto.id;
            const oldState = newMetaState[key] || { ...CardAtom(undefined, { type: '' }), id: dto.id };
            const state = CardAtom(oldState, CardAtom.a.setCache(dto));

            if (state === oldState) return;

            newMetaState[key] = state;
            isChanged = true;
        });

        return isChanged ? newMetaState : metaState;
    },
});

export const CardListAtom = declareAtom<ICard[]>(
    'cards/list',
    [],
)((on) =>
    on(CardsAtom, (_, cards: Record<string, ICardState>) => {
        return Object.values(cards).sort((cardA, cardB) =>
            cardA.name > cardB.name ? 1 : cardA.name < cardB.name ? -1 : 0,
        );
    }),
);
