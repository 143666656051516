import { IPeriodSummary, ISummary } from 'src/models/common/common.types';
import { Money } from 'src/models/money';

import { ITransaction, TransactionType } from './transaction.types';

export const EMPTY_SUMMARY: ISummary = {
    income: Money.create(0, 'RUB'),
    expense: Money.create(0, 'RUB'),
    balance: Money.create(0, 'RUB'),
};

export function calculateSummary(transactions: ITransaction[]): ISummary {
    const [expense, income] = transactions.reduce(
        ([expense, income], tx) => {
            switch (tx.type) {
                case TransactionType.Removed:
                    return [expense, income];
                case TransactionType.Income:
                    return [expense, income.add(tx.amount)];
                case TransactionType.Expense:
                    return [expense.add(tx.amount), income];
                default:
                    throw new Error(`Can't process type "${TransactionType[tx.type]}"`);
            }
        },
        [Money.create(0, 'RUB'), Money.create(0, 'RUB')],
    );

    const balance = income.sub(expense);

    return {
        balance,
        expense,
        income,
    };
}

export function calculateExtendSummary(transactions: ITransaction[]): IPeriodSummary {
    const balanceOnStart = Money.create(0, 'RUB');

    const summary = calculateSummary(transactions);

    return {
        ...summary,
        balanceOnStart,
        balanceOnEnd: balanceOnStart.add(summary.balance),
    };
}

export function addSummary(base: ISummary, add: ISummary): ISummary {
    const balance = base.balance.add(add.balance);
    const expense = base.expense.add(add.expense);
    const income = base.income.add(add.income);

    if (!income.sub(expense).equal(balance)) {
        if (!checkSummary(base)) throw new Error('"Base" summary invalid');
        if (!checkSummary(add)) throw new Error('"Add" summary invalid');
        throw new Error('Incorrect balance');
    }

    return {
        balance,
        expense,
        income,
    };
}

export function addExtendSummary(base: IPeriodSummary, add: ISummary): IPeriodSummary {
    const summary = addSummary(base, add);
    const balanceOnStart = base.balanceOnStart;
    const balanceOnEnd = base.balanceOnEnd.add(add.balance);

    if (!summary.income.sub(summary.expense).equal(summary.balance)) {
        if (!checkExtendSummary(base)) throw new Error('"Base" summary invalid');
        if (!checkSummary(add)) throw new Error('"Add" summary invalid');
        throw new Error('Incorrect balance');
    }

    return {
        ...summary,
        balanceOnStart,
        balanceOnEnd,
    };
}

export function checkSummary(summary: ISummary): boolean {
    const balance = summary.income.sub(summary.expense);

    return balance.equal(summary.balance);
}

export function checkExtendSummary(summary: IPeriodSummary): boolean {
    if (!checkSummary(summary)) return false;
    const balance = summary.income.sub(summary.expense);
    const balanceOnEnd = summary.balanceOnStart.add(balance);

    return balanceOnEnd.equal(summary.balanceOnEnd);
}
