import { declareEffect } from '@flatom/core';

import { ICardForm } from '../pages/card-form.component';

import { CardsAtom } from './card.atom';
import { cardsCacheServiceToken } from './cards-cache.service';
import { cardsServiceToken } from './cards.service';

const NS = 'card-effects';

export const loadCards = declareEffect(NS + ':load', async ({ dispatch, getService }) => {
    const service = getService(cardsServiceToken);
    const allCardsDto = await service.getAll();

    dispatch(CardsAtom.a.setCards(allCardsDto));

    const cacheService = getService(cardsCacheServiceToken);
    const allCardsCacheDto = await cacheService.getAll();

    dispatch(CardsAtom.a.setCardsCache(allCardsCacheDto));
});

export const createCard = declareEffect<ICardForm>(NS + ':create', async ({ getService, dispatch }, payload) => {
    const service = getService(cardsServiceToken);
    const card = await service.create(payload);

    await dispatch(loadCards());

    return card.id;
});
