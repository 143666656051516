import { format } from 'date-fns';

import { CategoryID, CategoryMap } from 'src/categories';
import { ITransactionGrip } from 'src/models/abstract-grip/grip.types';

export interface IDisplayedTransaction extends ITransactionGrip {
    first: string;
    second: string;
    dateString: string;
    category: { id: CategoryID; name: string; icon: string };
}

export function makeTxList(
    list: ITransactionGrip[],
    reverse = false,
    categories: CategoryMap = new Map(),
): IDisplayedTransaction[] {
    const result = list.map((item) => {
        const category = categories.get(item.categoryId as CategoryID) || { id: null, name: '---', icon: 'default' };

        return {
            ...item,
            first: category.name,
            second: item.title,
            dateString: item.date ? format(new Date(item.date), 'PP') : '',
            category,
        };
    });

    if (reverse) result.reverse();

    return result;
}
