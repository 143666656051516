import { AccountID } from 'src/domain/account.types';
import { MonthDate } from 'src/domain/date.types';
import { IAccount } from 'src/models/account-dto/account.types';
import { Money } from 'src/models/money';
import { IMonth } from 'src/models/month/month-legacy.types';

import { IGrip, IMonthGripBrief } from './grip.types';
import { AbstractMonthGrip } from './month-grip.class';

/**
 * TODO: Grip must know about categories in months from IMonthBrief
 */
export abstract class AbstractGrip implements IGrip {
    id: AccountID;
    name: string;
    firstMonthDate: MonthDate = null;
    lastMonthDate: MonthDate = null;
    months: IMonthGripBrief[] = [];

    balance: Money = Money.create(0, 'RUB');
    expense: Money = Money.create(0, 'RUB');
    income: Money = Money.create(0, 'RUB');

    protected constructor(accounts: IAccount[]) {}

    public abstract makeMonth(month: IMonth): AbstractMonthGrip;
}
