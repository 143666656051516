import { chooseAccountGrip } from 'src/atoms/account-grips/account-grips.actions';
import {
    chooseAccount,
    loadAccounts,
    loadAccountsFailed,
    loadAccountsSuccess,
    saveAccount,
    saveAccountFailed,
    saveAccountSuccess,
} from 'src/atoms/accounts/accounts.actions';
import { AccountDTO } from 'src/models/account-dto/account.class';

import { addActionHandler, db } from '../db';
import { AccountScheme } from '../schemes';

const CURRENT_ACCOUNT_KEY = 'currentAccount';

addActionHandler(chooseAccount, (payload) => {
    localStorage.setItem(CURRENT_ACCOUNT_KEY, payload);
});

addActionHandler(loadAccounts, (_, store) => {
    db.transaction(AccountScheme)
        .getAll()
        .then(
            (result) => {
                if (!result.length) return console.warn('No accounts exists');

                const accounts = result.map(AccountDTO.fromJSON);

                const currentID = localStorage.getItem(CURRENT_ACCOUNT_KEY);

                store.dispatch(
                    loadAccountsSuccess({
                        accounts,
                    }),
                );

                store.dispatch(chooseAccountGrip(currentID));
            },
            (error) => store.dispatch(loadAccountsFailed(error)),
        );
});

addActionHandler(saveAccount, (account, store) => {
    db.transaction(AccountScheme)
        .update(account.toJSON() as any)
        .then(
            (result) => store.dispatch(saveAccountSuccess(account.id)),
            (error) => store.dispatch(saveAccountFailed({ id: account.id, error })),
        );
});
