import React from 'react';

import styled from 'styled-components';

interface IProps {
    icon: React.FunctionComponent;
    title?: string;
    className?: string;
    size?: string;
    color?: string;
    onClick?: (event?: React.MouseEventHandler<any>) => void;
}

const IconWrapper = styled.div<{ color?: string; size?: string }>`
    display: inline-block;
    width: ${(props) => props.size || '1em'};
    height: ${(props) => props.size || '1em'};
    contain: strict;
    fill: currentcolor;
    box-sizing: content-box !important;

    svg {
        display: block;
        height: 100%;
        width: 100%;
        * {
            stroke: ${getColor('color', '--black')};
            fill: ${getColor('color', '--black')};
        }
    }
`;

const iconSizes = {
    big: '4em',
    normal: '2em',
};

export const Icon = ({ icon, title, className, size, color, onClick }: IProps & any) => {
    const SelectedIcon = icon;

    if (size in iconSizes) size = iconSizes[size];

    return (
        <IconWrapper
            size={size}
            color={color}
            // @ts-ignore не хочу фиксить, все равно буду использовать css modules
            onClick={onClick}
            className={className}
            title={title}
        >
            <SelectedIcon />
        </IconWrapper>
    );
};

function getColor<T extends Record<string, any>>(field: keyof T, defaultColor: string) {
    return (props: T) => color2css(props[field] as any, defaultColor);
}

function color2css(color: string, defaultColor: string) {
    color = color || defaultColor;
    if (color.substring(0, 2) === '--') return `var(${color})`;
    else return color;
}
