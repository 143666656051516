import { TimeStamp } from 'src/libs/time';
import { Money } from 'src/models/money';
import { SuiteID } from 'src/suite/types/suite.types';
import { DTO } from 'src/utils/dto';
import { ObjectIDHelper } from 'src/utils/object-id/object-id.helper';

import { BillID, BillStatus, IBill, BillOperationType, IProverkaChekaResponse } from '../types';

import { decodeQrCode } from './qrcode';

export function createBillByQrCode(
    id: BillID,
    uri: string,
): { bill: IBill; dto: DTO<IBill> } | { bill: undefined; dto: undefined } {
    const qrCode = decodeQrCode(uri);

    if (!qrCode) {
        return {
            bill: undefined,
            dto: undefined,
        };
    }

    const amount = Money.create(qrCode.amount, 'RUB');

    const bill: IBill = {
        id,
        status: BillStatus.Created,
        suiteId: ObjectIDHelper.null as SuiteID,
        raw: uri,
        rawQrCode: uri,
        normalized: qrCode.normalized,
        normalizedQrCode: qrCode.normalized,

        type: qrCode.type,
        operationType: qrCode.type,
        ts: qrCode.timestamp as TimeStamp,
        dateTime: qrCode.timestamp as TimeStamp,
        amount,
        totalSum: amount,
        details: null,
        items: [],
    };

    const dto: DTO<IBill> = {
        ...bill,
        amount: amount.toJSON(),
        totalSum: amount.toJSON(),
        items: [],
        details: {
            ...bill.details,
            cashTotalSum: '',
            ecashTotalSum: '',
            nds20: '',
            nds10: '',
            nds0: '',
            noNds: '',
        },
    };

    return { dto, bill };
}

export function parseProverkaChekaCom(rawData: IProverkaChekaResponse): Pick<IBill, 'items' | 'details'> {
    const data: Record<string, any> = rawData.data.json;

    const details: IBill['details'] = {
        orgName: normalizeString(data.user),
        orgINN: normalizeString(data.userInn),
        retailPlace: normalizeString(data.retailPlace),
        retailPlaceAddress: normalizeString(data.retailPlaceAddress || data.metadata.address),
        operator: normalizeString(data.operator),
        shiftNumber: data.shiftNumber,
        requestNumber: data.requestNumber,

        cashTotalSum: Money.create(data.cashTotalSum / 100, 'RUB'),
        ecashTotalSum: Money.create(data.ecashTotalSum / 100, 'RUB'),
        nds20: data.nds18 ? Money.create(data.nds18 / 100, 'RUB') : undefined,
        nds10: data.nds10 || data.nds ? Money.create((data.nds10 || data.nds) / 100, 'RUB') : undefined,
        nds0: data.nds0 ? Money.create(data.nds0 / 100, 'RUB') : undefined,
        noNds: data.ndsNo ? Money.create(data.ndsNo / 100, 'RUB') : undefined,

        kktRegId: normalizeString(data.kktRegId),
        fiscalDriveNumber: normalizeString(data.fiscalDriveNumber),
        fiscalDocumentNumber: data.fiscalDocumentNumber,
        fiscalSign: data.fiscalSign,
    };

    if (!details.nds20) delete details.nds20;
    if (!details.nds10) delete details.nds10;
    if (!details.nds0) delete details.nds0;
    if (!details.noNds) delete details.noNds;

    return {
        items: data.items.map((item) => ({
            name: normalizeString(item.name),
            price: Money.create(item.price / 100, 'RUB'),
            quantity: item.quantity,
            sum: Money.create(item.sum / 100, 'RUB'),
        })),
        details,
    };
}

function normalizeString(str: string): string {
    if (!str) {
        return '';
    }

    return str
        .trim()
        .replace(/\s{5,}/g, '\n')
        .replace(/\s\s+/g, ' ')
        .replace(/,+/g, ',')
        .replace(/,$/, '');
}

export const operationTypeTitle: { [key in BillOperationType]: string } = {
    [BillOperationType.Приход]: 'Приход',
    [BillOperationType.ВозвратПрихода]: 'Возврат прихода',
    [BillOperationType.Расход]: 'Расход',
    [BillOperationType.ВозвратРасхода]: 'Возврат расхода',
};

export const billStatusTitle: { [key in BillStatus]: string } = {
    [BillStatus.Created]: 'Создан',
    [BillStatus.Pending]: 'Идет запрос данных',
    [BillStatus.Found]: 'Данные получены',
    [BillStatus.NotFound]: 'Нет данных по чеку',
    [BillStatus.InvalidData]: 'Некорректные данные по чеку',
    [BillStatus.FailedToFetch]: 'Не удалось получить данные',
};
