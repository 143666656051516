// eslint-disable-next-line
export type Type<T> = any;

export interface Encoder<T> {
    (obj: T): any;
}
export interface Decoder<T> {
    (value: unknown): T;
}

export const EncoderSymbol = Symbol('Packable encoder');
export const DecoderSymbol = Symbol('Packable decoder');

export interface IPacker<T> {
    [DecoderSymbol]: Decoder<T>;
    [EncoderSymbol]: Encoder<T>;
}

export type Fields<T> = { [key in keyof T]: T[key] };

export type FieldTypePrimitive = StringConstructor | NumberConstructor | BooleanConstructor;

export type FieldTypeArray<T> = Array<T>;
export type FieldRule<T> = IPacker<T> | Type<T> | FieldTypePrimitive | Array<FieldTypeArray<T>>;

export type FieldsRules<T> = {
    [key in keyof T]: FieldRule<T[key]>;
};
