import { declareEffect } from '@flatom/core';

import { loadCards } from 'src/cards/model/cards.effects';
import { loadCategories } from 'src/categories/model/categories.effects';

const NS = 'common-effects';

export const refreshAll = declareEffect(NS + ':refreshAll', ({ dispatch }) => {
    dispatch(loadCards());
    dispatch(loadCategories({ checkDefault: true }));
});
