import { ICardDto } from 'src/cards/model/card.types';
import { ICardsService } from 'src/cards/model/cards.service';
import { AccountScheme, db } from 'src/db';
import { UUID } from 'src/domain/common.types';
import { AccountDTO } from 'src/models/account-dto/account.class';

export class CardsService implements ICardsService {
    getAll(): Promise<ICardDto[]> {
        return db
            .transaction(AccountScheme)
            .getAll()
            .then((result) => {
                if (!result.length) {
                    console.warn('No accounts exists');
                }

                return result.map(AccountDTO.fromJSON).map(
                    (data): ICardDto => ({
                        id: data.id,
                        name: data.name,
                    }),
                );
            });
    }

    async create(card: Omit<ICardDto, 'id'>): Promise<ICardDto> {
        const account = AccountDTO.create(card.name);
        const accountId: UUID = db.transaction(AccountScheme).add(account.toJSON()) as any;

        return {
            id: accountId,
            name: card.name,
        };
    }
}
