import React, { ReactNode, useCallback } from 'react';

import { useHistory } from 'react-router';

import { Icon } from 'src/ui-kit/Icon';
import { Icons } from 'src/ui-kit/Icons';

interface IParams {
    title: string;
    back?: boolean | number;
    href?: string;
    children?: ReactNode;
}

export const Header = ({ title, back, href, children }: IParams) => {
    const history = useHistory();

    const onClick = useCallback(() => {
        if (back === true) {
            if (href) {
                return history.replace(href);
            } else {
                return history.goBack();
            }
        }

        if (typeof back === 'number') {
            history.go(-back);
        }

        if (href) {
            const unsubscribe = history.listen(() => {
                unsubscribe();
                history.replace(href);
            });
        }
    }, [history, back, href]);

    return (
        <div
            className="appHeader"
            data-qa-component="app-header"
        >
            <div className="appHeader appHeader_container">
                {back && (
                    <div className="left">
                        <span
                            onClick={onClick}
                            className="headerButton goBack"
                        >
                            <Icon
                                icon={Icons.chevronBackOutline}
                                className="md hydrated"
                            />
                        </span>
                    </div>
                )}
                <div
                    className="pageTitle"
                    data-qa-element="app-header_title"
                >
                    {title}
                </div>
                <div className="right">{children}</div>
            </div>
        </div>
    );
};
