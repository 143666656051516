import { BillID } from 'src/bills';
import { DayDate } from 'src/domain/date.types';
import { IAccount } from 'src/models/account-dto/account.types';
import { UUID } from 'src/models/common/common.types';
import { Money } from 'src/models/money';
import { ITransaction, TransactionType } from 'src/models/transaction/transaction.types';

import { ITransactionGrip } from './grip.types';

export class TransactionGrip implements ITransactionGrip {
    id: UUID;
    account: IAccount;
    amount: Money;
    categoryId: UUID;
    changeAmount: Money;
    createdAt: Date;
    date: DayDate;
    sourceTxs: ITransaction[];
    title: string | '';
    qrCode: BillID | '';
    type: TransactionType;
    updatedAt: Date;

    constructor(data: ITransactionGrip) {
        Object.assign(this, data);
    }
}
