import { createToken } from '@flatom/core';

import { ICardCacheDto } from './card.types';

export const cardsCacheServiceToken = createToken<ICardsCacheService>('CardCacheService');

export interface ICardsCacheService {
    getAll(): Promise<ICardCacheDto[]>;

    upsert(cache: ICardCacheDto): Promise<ICardCacheDto>;
}
