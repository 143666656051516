import { AbstractDayGrip } from 'src/models/abstract-grip/day-grip.class';
import { ITransactionGrip } from 'src/models/abstract-grip/grip.types';
import { IAccount } from 'src/models/account-dto/account.types';
import { IDay } from 'src/models/day/day.class';
import { Money } from 'src/models/money';
import { TransactionType } from 'src/models/transaction/transaction.types';

export class AccountDayGrip extends AbstractDayGrip {
    constructor(balanceOnStart: Money, day: IDay, account: IAccount) {
        const transactions: ITransactionGrip[] = day.transactions
            .map((tx) => {
                const grip: ITransactionGrip = {
                    id: tx.id,
                    account,
                    amount: tx.amount,
                    categoryId: tx.category,
                    changeAmount: tx.amount,
                    title: tx.title,
                    type: tx.type,
                    createdAt: tx.createdAt ? new Date(tx.createdAt) : null,
                    updatedAt: tx.updatedAt ? new Date(tx.updatedAt) : null,
                    date: day.date,
                    sourceTxs: [tx],
                    qrCode: tx.qrCode,
                };

                switch (tx.type) {
                    case TransactionType.Removed:
                        return null;
                    case TransactionType.Income:
                        return grip;
                    case TransactionType.Expense:
                        return {
                            ...grip,
                            changeAmount: tx.amount.negative(),
                        };
                    default:
                        throw new Error(`Unsupported type "${TransactionType[tx.type]}"`);
                }
            })
            .filter((item) => !!item);

        super(day.date, balanceOnStart, transactions);
    }
}
