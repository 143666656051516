import { select, put, take } from 'redux-saga/effects';

import { AccountGrips, IAccountGripsState } from 'src/atoms/account-grips/account-grips.atom';
import { saveAccount, saveAccountSuccess, saveAccountFailed } from 'src/atoms/accounts/accounts.actions';
import { Accounts, IAccountsState } from 'src/atoms/accounts/accounts.atom';
import { AccountDTO } from 'src/models/account-dto/account.class';
import { isVersionOfMonth, RequiredMonthsError } from 'src/models/account-dto/chain.utils';
import { AccountGrip } from 'src/models/account-grip/grip.class';
import { UUID } from 'src/models/common/common.types';
import { MonthLegacy } from 'src/models/month/month-legacy.class';

import { SagaUtils } from '../helpers/helpers';
import { SagaPacker } from '../saga-launcher';

import { MonthUtils } from './month.saga';

export const AccountUtils = {
    select: SagaPacker.call(selectAccountSaga),
    getGrip: SagaPacker.call(getAccountGripSaga),
    update: SagaPacker.call(updateAccountSaga),
    save: SagaPacker.call(saveAccountSaga),
};

/**
 * Select account from Store
 * @param id
 */
function* selectAccountSaga(id: UUID) {
    const accounts: IAccountsState = yield select((getState) => getState(Accounts));
    const account: AccountDTO = accounts.accounts.get(id);

    if (!account) throw new Error(`Account "${id}" not found`);

    return account;
}

/**
 * Get account grip from Store
 * @param id
 */
function* getAccountGripSaga(id: UUID) {
    const accounts: IAccountGripsState = yield select((getState) => getState(AccountGrips));
    const account: AccountGrip = accounts.accounts.get(id);

    if (!account) throw new Error(`AccountGrip "${id}" not found`);

    return account;
}

/**
 * Обновляет месяц в аккаунте, при необходимости правит оставшуюся цепочку, сохраняет
 *
 * @param account
 * @param month
 */
function* updateAccountSaga(account: AccountDTO, month: MonthLegacy) {
    // console.log('*** updateAccountSaga');
    // console.log(account.months.map((item) => item.month));
    // console.log(month.month, month.id);
    let currentMonthIndex = account.months.findIndex((item) => item.month === month.month);
    const prevMonthIndex = account.months.findIndex((item) => item.month < month.month);

    if (!account.head) {
        yield* MonthUtils.save([month]);
        const accountToUpdate = account.updateHead(month);

        yield* AccountUtils.save(accountToUpdate);

        return accountToUpdate;
    }

    const toIndex = currentMonthIndex === -1 ? prevMonthIndex : currentMonthIndex;

    const monthsIds: UUID[] = account.months.slice(0, toIndex + 1).map((item) => item.id);

    const additionalMonths: MonthLegacy[] = [month];

    // console.log(monthsIds);

    if (monthsIds.length) additionalMonths.push(...(yield* MonthUtils.getByIds(monthsIds)));

    const timestamp = yield* SagaUtils.getTimestamp();

    // console.log({
    //     currentMonthIndex,
    //     prevMonthIndex,
    //     toIndex,
    //     additions: additionalMonths.map((item) => [item.month + ' ' + item.id]),
    //     months: account.months.slice(0, toIndex + 1).map((item) => item.month),
    // });

    const monthsToUpdate = [month];

    // console.log('additional');
    // console.log(additionalMonths.map((item) => `${item.month} ${item.id}`));
    // console.log('chain');
    // console.log(account.months.map((item) => `${item.month} ${item.id}`));

    // console.log('prevMonthIndex', prevMonthIndex, account.months[prevMonthIndex]?.month);

    if (currentMonthIndex === -1 && prevMonthIndex === -1) throw new Error('Unexpection');

    if (currentMonthIndex === -1 && prevMonthIndex !== -1) {
        currentMonthIndex = prevMonthIndex;
    } else {
        const existsMonth = account.months[currentMonthIndex];

        if (existsMonth.month !== month.month) throw new Error('somethings wrong');
        if (!isVersionOfMonth(month, existsMonth)) throw new Error('somethings wrong');
    }

    // console.log('currentMonthIndex', currentMonthIndex, account.months[currentMonthIndex]?.month);

    if (currentMonthIndex !== -1) {
        let previousMonth: MonthLegacy = month;

        for (let index = currentMonthIndex - 1; index >= 0; index--) {
            // console.log('index', index, account.months[index].month);
            const currentMonthId = account.months[index].id;
            const currentMonth = additionalMonths.find((item) => item.id === currentMonthId);

            if (!currentMonth) throw new RequiredMonthsError(currentMonthId);
            previousMonth = currentMonth.updatePrevMonths([previousMonth], timestamp);
            additionalMonths.push(previousMonth);
            monthsToUpdate.push(previousMonth);
        }

        // console.log(
        //     'additionalMonths',
        //     additionalMonths.map((item) => [item.month + ' ' + item.id]),
        // );

        const accountToUpdate = account.updateHead(previousMonth, additionalMonths);

        yield* MonthUtils.save(monthsToUpdate);
        yield* AccountUtils.save(accountToUpdate);

        return accountToUpdate;
    }

    throw new Error('may be first');
}

export function* saveAccountSaga(account: AccountDTO) {
    yield put(saveAccount(account));

    for (;;) {
        const action = yield take([saveAccountSuccess, saveAccountFailed]);

        if (action.type === saveAccountSuccess.getType()) {
            if (action.payload === account.id) return;
        } else {
            if (action.payload.id === account.id) throw action.payload.error;
        }
    }
}
