import React, { useReducer } from 'react';

import { useAtom } from '@flatom/react';

import { Client } from 'src/atoms/client/client.atom';
import { update } from 'src/serviceWorker';

export const VersionPart = () => {
    const client = useAtom(Client);
    const [showSHA, toggleSHA] = useReducer((show) => !show, false);

    return (
        <>
            <div className="listview-title mt-1">Приложение</div>
            <ul
                className="listview image-listview text inset"
                style={{ marginLeft: '0', marginRight: '0' }}
            >
                <li>
                    <div
                        className="item"
                        onClick={toggleSHA}
                    >
                        <div className="in">
                            <div>Версия</div>
                            <span className="text-muted">
                                {client.version}
                                {showSHA && ' / ' + client.hexVersion}
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Дата обновления</div>
                            <span className="text-muted">{client.releaseDate.toLocaleDateString()}</span>
                        </div>
                    </div>
                </li>
                <li onClick={update}>
                    <div className="action item">
                        <div className="in">
                            <div>Обновить приложение</div>
                        </div>
                    </div>
                </li>
            </ul>
        </>
    );
};
