import React, { ReactNode } from 'react';

import block from 'bem-css-modules';

import styles from './Section.module.scss';

interface IProps {
    id?: string;
    full?: boolean;
    title?: string;
    children: ReactNode;
}

const b = block(styles);

export const Section = ({ id, full, title, children }: IProps) => {
    return (
        <section
            className={b({ full })}
            id={id}
        >
            {title && <h3 className={b('title', { full })}>{title}</h3>}
            {children}
        </section>
    );
};
