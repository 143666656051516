import { ObjectID } from './object-id.types';

/**
 * Special null value
 */
const nullObjectID = '00000-00000-0000-0000' as ObjectID;

const objectIDRegexp = /^[0-9a-f]{5}-[0-9a-f]{5}-[0-9a-f]{4}-[0-9a-f]{4}$/;

export const ObjectIDHelper = Object.freeze({
    null: nullObjectID,
    make,
    check,
    isNull,
    isNotNull: (uuid: string) => !isNull(uuid),
});

const timezoneOffset = new Date().getTimezoneOffset() * 60;

function make<T extends ObjectID>(timestamp: number, processId: number, increment: number, version = 0): T {
    let [seconds, ms] = (timestamp / 1000 - timezoneOffset).toString(16).split('.');

    seconds = hexLength(seconds, 8);
    ms = (ms + '00').substr(0, 2);

    if (version < 0 || version > 15) throw new Error(`Invalid version ${version}, allow only 0-15 version numbers`);

    const id = hexLength((processId + increment).toString(16), 7);

    const part1 = seconds.substr(0, 5);
    const part2 = seconds.substr(5) + ms;
    const part3 = version.toString(16).substr(0, 1) + id.substr(0, 3);
    const part4 = id.substr(3);

    return (part1 + '-' + part2 + '-' + part3 + '-' + part4) as T;
}

function check(objectID: string): objectID is ObjectID {
    if (objectID.length !== 21) {
        throw new ObjectIDError(objectID, `length must be 21 but this string has ${objectID.length}`);
    }

    if (!objectIDRegexp.test(objectID)) {
        throw new ObjectIDError(objectID, 'contain invalid symbols or has bad pattern');
    }

    return true;
}

function isNull(objectID: string): boolean {
    return !objectID || objectID === nullObjectID;
}

class ObjectIDError extends Error {
    constructor(public readonly objectID: string, public readonly error: string) {
        super(`Invalid ObjectID "${objectID}": ${error}`);
    }
}

function hexLength(hex: string, length: number): string {
    if (hex.length === length) {
        return hex;
    }

    if (hex.length > length) {
        return hex.substr(hex.length - length);
    }

    while (hex.length < length) {
        hex = '0' + hex;
    }

    return hex;
}
