export type Uuid<T extends string = string> = string & { __brand: 'UUID'; __type: T };

const nullUuid = '00000000-0000-0000-0000-000000000000' as Uuid;

const uuidRegexp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export const UUID = Object.freeze({
    null: nullUuid,
    create,
    check,
    isNull,
    isNotNull: (uuid: string) => !isNull(uuid),
});

function create<T extends Uuid>(value: string): T {
    value = value.toLowerCase();

    // TODO remote "default" fallback
    if (!value || value === 'default') {
        return nullUuid as T;
    }

    if (check(value)) return value as T;
}

function check(uuid: string): uuid is Uuid {
    if (uuid.length !== 36) throw new UUIDError(uuid, `length must be 36 but this string has ${uuid.length}`);

    return uuidRegexp.test(uuid);
}

function isNull(uuid: string): boolean {
    return !uuid || uuid === nullUuid;
}

class UUIDError extends Error {
    constructor(public readonly uuid: string, public readonly error: string) {
        super(`Invalid UUID "${uuid}": ${error}`);
    }
}
