import { declareAtom } from '@flatom/core';
import { Map } from 'immutable';

import { updateMonthGripSuccess, updateMonthGripsSuccess } from './month-grips.actions';
import { IMonthGripsState } from './month-grips.types';

export const MonthGrips = declareAtom<IMonthGripsState>(
    ['grips/month'],
    Map(),
)((on) => ({
    update: [
        on(updateMonthGripSuccess, (state, month) => {
            return state.set(month.id, month);
        }),
        on(updateMonthGripsSuccess, (state, months) => {
            return months.reduce((acc, month) => acc.set(month.id, month), state);
        }),
    ],
}));
