import React, { useEffect } from 'react';

import { FormControl } from '@flatom/forms';
import { FlatomForm } from '@flatom/forms-react';

import { IRootCategory } from 'src/categories/types';
import { rootCategoriesToOptionList } from 'src/categories/utils/categories';
import { IAccount } from 'src/models/account-dto/account.types';
import { ITransactionForm, TransactionType } from 'src/models/transaction/transaction.types';
import { Button } from 'src/ui-kit/Button';
import { DateField } from 'src/ui-kit/inputs/DateField';
import { MoneyField } from 'src/ui-kit/inputs/MoneyField';
import { IOptions, SelectField } from 'src/ui-kit/inputs/SelectField';
import { TextField } from 'src/ui-kit/inputs/TextField';

import { IFormData } from './AddTransaction.form';

interface IProps {
    form: FormControl<IFormData>;
    accounts: IAccount[];
    categories: IRootCategory[];
    value: ITransactionForm;
    onSubmit: (value: ITransactionForm) => void;
}

export const AddTransactionForm = (props: Partial<IProps>) => {
    useEffect(() => {
        props.form.init();

        return () => props.form.destroy();
    }, []);

    const categories = rootCategoriesToOptionList(props.categories);
    const accounts: IOptions = props.accounts.map((acc) => ({ id: acc.id, label: acc.name, value: acc.id }));
    const typeOptions: IOptions<TransactionType> = [
        {
            id: TransactionType[TransactionType.Expense],
            label: 'Расход',
            value: TransactionType.Expense,
        },
        {
            id: TransactionType[TransactionType.Income],
            label: 'Доход',
            value: TransactionType.Income,
        },
    ];

    const onSubmit = (value: IFormData) => {
        props.onSubmit?.({
            amount: value.amount.amount,
            type: value.type,
            category: value.category,
            title: value.title,
            account: value.account,
            date: value.date,
            qrCode: value.qrCode,
        });
    };

    const onSubmitInvalid = (_: IFormData, form: FormControl) => {
        form.get('amount').markAsTouched();
    };

    return (
        <FlatomForm
            form={props.form}
            onSubmit={onSubmit}
            onSubmitInvalid={onSubmitInvalid}
        >
            <MoneyField
                name="amount"
                label="Сумма"
                hint="Размер платежа"
            />
            <SelectField
                name="category"
                label="Категория"
                hint="Выберите категорию платежа"
                options={categories}
            />
            <SelectField
                name="type"
                label="Тип"
                hint="Выберите тип платежа"
                options={typeOptions}
            />
            <TextField
                name="title"
                label="Название"
                hint="Кратко о назначении платежа"
            />
            <DateField
                name="date"
                label="Дата"
                hint="Когда был совершен платеж"
            />
            <SelectField
                name="account"
                label="Счет"
                hint="Выберите счет платежа"
                options={accounts}
            />
            <Button
                type="submit"
                disabled={props.form.invalid}
            >
                Сохранить
            </Button>
        </FlatomForm>
    );
};
