import React from 'react';

export const LoaderBlock = () => (
    <div className="loader">
        <div className="loader__text">
            <span>З</span>
            <span>А</span>
            <span>Г</span>
            <span>Р</span>
            <span>У</span>
            <span>З</span>
            <span>К</span>
            <span>А</span>
        </div>
    </div>
);
