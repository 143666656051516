/**
 * This comment will be used as the summary for the "thing2" module.
 *
 * @packageDocumentation
 * @module app-root
 * @preferred
 */

import React from 'react';

import { StoreProvider } from '@flatom/react';
import ReactDOM from 'react-dom';

import 'src/styles/index.scss';
import { App } from 'src/App';
import * as serviceWorker from 'src/serviceWorker';
import { store } from 'src/store';

ReactDOM.render(
    <React.StrictMode>
        <StoreProvider value={store}>
            <App />
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

window['_initBackupRestore'] = function () {
    const el = document.createElement('script');

    el.src = '/backup-restore.js';
    document.head.append(el);
};
