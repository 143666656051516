import React from 'react';

import { Link } from 'src/paths';

import styles from './HeaderAction.module.scss';
import { Icon } from './Icon';

interface IProps {
    link?: string;
    onClick?: () => void;
    icon?: React.FunctionComponent;
    title?: string;
}

export const HeaderAction = ({ title, icon, link, onClick }: IProps) => {
    if (link)
        return (
            <Link
                to={link}
                className={styles.headerAction}
            >
                <Icon
                    icon={icon}
                    onClick={onClick}
                />
            </Link>
        );
    else
        return (
            <div
                onClick={onClick}
                className={styles.headerAction}
            >
                <Icon icon={icon} />
            </div>
        );
};
