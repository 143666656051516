/**
 * @packageDocumentation
 * @module app-root
 */

import React from 'react';

import { useAction } from '@flatom/react';
import { Redirect, Route, Switch, Router } from 'react-router-dom';

import 'src/sagas';
import { startListenOnlineStatus } from 'src/atoms/client/client.service';
import { refreshAll } from 'src/common/domain/effects';
import { initIndexedDB, useDBReady } from 'src/db';
import { DBStatus } from 'src/libs/indexed-db';
import { paths } from 'src/paths';
import { routes } from 'src/routes';
import { sagaLauncher } from 'src/sagas';
import { store, history } from 'src/store';
import { DialogContainer } from 'src/ui-kit/dialog';

import styles from './App.module.scss';

export const App = () => {
    const refreshAllHandler = useAction(() => {
        console.log('*** refreshAllHandler');

        initIndexedDB(store);

        console.log('*** refreshAllHandler complete');

        return refreshAll();
    }, []);
    const status = useDBReady(refreshAllHandler, (error) => {
        alert('error');
        console.log(error);
    });

    if (status !== DBStatus.Ready) {
        return <div>Status: {DBStatus[status]}</div>;
    }

    return (
        <div className={styles.App}>
            <Router history={history}>
                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            {...route}
                            key={index}
                        />
                    ))}
                    <Redirect to={paths.home()} />
                </Switch>
            </Router>
            <DialogContainer />
        </div>
    );
};

startListenOnlineStatus(store);
sagaLauncher.start(store);
