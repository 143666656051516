import React from 'react';

import { useAction } from '@flatom/react';
import { useHistory } from 'react-router';

import { Header } from 'src/components/Header';
import { paths } from 'src/paths';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';

import { createCard } from '../model/cards.effects';

import { CardForm, ICardForm } from './card-form.component';

export const AddCardPage = () => {
    const history = useHistory();

    const data: ICardForm = {
        name: 'New card',
        bank: 'The best bank',
        cardNumber: '',
    };

    const createCardHandler = useAction(createCard);

    const submitHandler = (data: ICardForm) => {
        createCardHandler(data).then((cardId) => history.replace(paths.account.view(cardId)));
    };

    function validate(data: ICardForm) {
        const errors: Partial<{ [key in keyof ICardForm]: string }> = {};

        if (!data.name) errors.name = 'Required';

        return errors;
    }

    return (
        <>
            <Header
                title="Add card or wallet"
                back
            />
            <Main>
                <CardForm
                    value={data}
                    onSubmit={submitHandler}
                    validate={validate}
                />
            </Main>
            <NavBar active="menu" />
        </>
    );
};
