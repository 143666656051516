import React from 'react';

import NumberFormat from 'react-number-format';

import { BaseInput, IBaseInputProps } from './BaseInput';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        // @ts-ignore something wrong with library's types
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            prefix="$"
            thousandSeparator=" "
            isNumericString
        />
    );
}

type IProps = IBaseInputProps;

export const MoneyField = (props: IProps) => (
    <BaseInput {...props}>
        {({ input, meta }) => (
            <NumberFormatCustom
                {...input}
                className="form-control"
                type="text"
                placeholder="Amount"
            />
        )}
    </BaseInput>
);
