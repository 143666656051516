import { Action, ActionCreator, Atom, PayloadAction, PayloadActionCreator } from '@flatom/core';
import { Subscription } from '@flatom/core/dist/common';
import { DispatchSubscription } from '@flatom/core/dist/store.types';

export function waitForActions(
    subscribe: (cb: DispatchSubscription) => Subscription,
    resolveActions: (ActionCreator | PayloadActionCreator<any>)[],
    rejectActions: (ActionCreator | PayloadActionCreator<any>)[] = [],
): Promise<Action> {
    const resolveTypes = resolveActions.map((action) => action.type);
    const rejectTypes = rejectActions.map((action) => action.type);

    return new Promise((resolve, reject) => {
        const subscription = subscribe((action) => {
            if (resolveTypes.includes(action.type)) {
                resolve(action);
                subscription();
            }

            if (rejectTypes.includes(action.type)) {
                reject(action);
                subscription();
            }
        });
    });
}

export function setAtomState<T>(atom: Atom<T>, payload: T): PayloadAction<T> {
    return {
        type: atom as any,
        payload,
    };
}
