import { db } from 'src/db';

import { ICategoriesService } from '../model/categories.service';
import { CategoryID, ICategoryDTO } from '../types';

import { CategoriesScheme } from './categories.scheme';

export class CategoriesService implements ICategoriesService {
    getAll(): Promise<ICategoryDTO[]> {
        return db.transaction(CategoriesScheme).getAll();
    }

    async insert(dto: ICategoryDTO): Promise<CategoryID> {
        const id = await db.transaction(CategoriesScheme).add(dto);

        return id as CategoryID;
    }

    async update(dto: ICategoryDTO): Promise<CategoryID> {
        const id = await db.transaction(CategoriesScheme).update(dto);

        return id as CategoryID;
    }
}
