import React from 'react';

import { useAction, useAtom } from '@flatom/react';

import { DialogAtom } from 'src/dialog/model/dialog.atom';

import { Backdrop } from './Backdrop';

export const DialogContainer = () => {
    const { show, title, text, buttons } = useAtom(DialogAtom);
    const actionHandler = useAction(DialogAtom.a.action);
    const actionFactory = (action: string) => () => actionHandler(action);

    if (!show) return null;

    return (
        <div
            className="modal fade dialogbox show"
            id="DialogIconedDanger"
            data-backdrop="static"
            tabIndex={-1}
            role="dialog"
            style={{ display: 'block', zIndex: 100000 }}
        >
            <div
                className="modal-dialog"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-icon text-danger">{/*<ion-icon name="close-circle"></ion-icon>*/}</div>
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                    </div>
                    <div className="modal-body">{text}</div>
                    <div className="modal-footer">
                        {buttons.map(({ label, name }) => (
                            <a
                                key={name}
                                className="btn-inline"
                                data-dismiss="modal"
                                onClick={actionFactory(name)}
                            >
                                <div className="btn">{label}</div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            <Backdrop />
        </div>
    );
};
