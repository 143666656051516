import React, { useState } from 'react';

import { useAtom } from '@flatom/react';
import { useHistory } from 'react-router';

import { ICategoryForm } from 'src/categories/types';
import { Header } from 'src/components/Header';
import { TransactionType } from 'src/domain/transaction.types';
import { useQueryParams } from 'src/hooks/router';
import { paths } from 'src/paths';
import { store } from 'src/store';
import { Card } from 'src/ui-kit/Card';
import { HeaderAction } from 'src/ui-kit/HeaderAction';
import { Icons } from 'src/ui-kit/Icons';
import { LoaderBlock } from 'src/ui-kit/LoaderBlock';
import { Main } from 'src/ui-kit/Main';
import { Section } from 'src/ui-kit/Section';
import { NavBar } from 'src/widgets/NavBar';

import { CategoryForm } from '../components/Form';
import { CategoriesAtom } from '../model/categories.atom';
import { addCategory } from '../model/categories.effects';

interface IQueryParams {
    parent?: string;
}

export const CategoriesCreatePage = () => {
    const history = useHistory();
    const query = useQueryParams<IQueryParams>();

    const { loading, list: categories } = useAtom(CategoriesAtom);

    const [data, setData] = useState({
        name: '',
        icon: 'default',
        parent: query.parent || null,
        defaultTxType: TransactionType.Expense,
    } as ICategoryForm);

    const save = async (dataToSave: ICategoryForm) => {
        const result = await store.dispatch(addCategory(dataToSave));

        history.replace(paths.categories.show(result.id));
    };

    const submitHandler = (formData: ICategoryForm) => {
        setData(formData);
        save(formData);
    };

    const saveHandler = () => save(data);

    function validate(data: ICategoryForm) {
        const errors: Partial<{ [key in keyof ICategoryForm]: string }> = {};

        if (data.name.length < 3) {
            errors.name = 'Invalid name';
        }

        return errors;
    }

    const title = 'Создание категории';

    if (loading) {
        return (
            <>
                <Header
                    title={title}
                    back={2}
                >
                    <HeaderAction
                        onClick={saveHandler}
                        icon={Icons.save}
                    />
                </Header>
                <LoaderBlock />
                <NavBar />
            </>
        );
    }

    return (
        <>
            <Header
                title={title}
                back={2}
            >
                <HeaderAction
                    onClick={saveHandler}
                    icon={Icons.save}
                />
            </Header>
            <Main>
                <Section full>
                    <Card>
                        <CategoryForm
                            value={data}
                            onSubmit={submitHandler}
                            validate={validate}
                            categories={categories}
                        />
                    </Card>
                </Section>
            </Main>
            <NavBar />
        </>
    );
};
