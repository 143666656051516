import { RouteProps } from 'react-router';

import { ViewBillPage } from 'src/bills';
import { paths } from 'src/paths';

export const billsRoutes: RouteProps[] = [
    {
        path: paths.bills.view(),
        component: ViewBillPage,
    },
];
