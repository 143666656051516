import React, { ChangeEvent } from 'react';

import { useField } from '@flatom/forms-react';

import { BaseField, IBaseFieldProps } from './BaseField';

export type IOption<T = any> =
    | {
          id: string;
          value: T;
          label: string;
      }
    | {
          id?: string;
          value: string;
          label: string;
      };

export type IOptions<T = any> = IOption<T>[];

export const SelectField = (props: IBaseFieldProps & { options: IOptions }) => {
    const { name, options } = props;
    const field = useField(name);
    const list = options.map((item) => {
        if (typeof item.id === 'string') {
            return item;
        }

        if (typeof item.value === 'string') {
            return { ...item, id: item.value };
        }

        throw new Error('ID or value have to be a string');
    });

    const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const option = list.find((item) => item.id === value);

        field.handleChange(option ? option.value : null);
    };

    const id = list.find((item) => item.value === field.value)?.id;

    return (
        <BaseField {...props}>
            <select
                id={field.path}
                name={field.path}
                value={id}
                onChange={onChange}
                onFocus={field.handleFocus}
                onBlur={field.handleBlur}
                disabled={!field.enabled}
            >
                {list.map(({ id, label }) => (
                    <option
                        key={id}
                        value={id}
                    >
                        {label}
                    </option>
                ))}
            </select>
        </BaseField>
    );
};
