import { useEffect, useState } from 'react';

import QrCodeScanner from 'qr-scanner';

export interface ICamera {
    id: string;
    label: string;
}

export interface IUseCameraStatus {
    hasCamera: boolean;
    list: ICamera[];
}

export function useCameraStatus(): IUseCameraStatus {
    const [list, setList] = useState<ICamera[]>([]);

    useEffect(() => {
        QrCodeScanner.listCameras().then(setList);
    }, []);

    return {
        hasCamera: list.length > 0,
        list: list,
    };
}
