import React from 'react';

import { ICategory } from 'src/categories/types';

import { IBaseInputProps } from './BaseInput';
import { SelectField } from './SelectField';

interface ICategoryFieldProps extends IBaseInputProps {
    categories: ICategory[];
}

export const CategoryField = ({ categories, ...props }: ICategoryFieldProps) => {
    const options = categories.map((cat) => ({
        value: cat.id,
        label: cat.name,
    }));

    return (
        <SelectField
            {...props}
            options={options}
        />
    );
};
