import formatDistance from 'date-fns/formatDistance';
import { ru } from 'date-fns/locale';

import { TimeStamp } from 'src/libs/time';

export function relativeDateTime(timestamp: TimeStamp, baseTime?: TimeStamp | Date): string {
    baseTime = (typeof baseTime === 'number' ? new Date(baseTime * 1000) : baseTime) || new Date();

    if (!timestamp) {
        return '';
    }

    const dateTime = formatDistance(new Date(timestamp * 1000), baseTime, { locale: ru, addSuffix: true });

    return dateTime;
}
