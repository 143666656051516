import React from 'react';

import { CardID } from 'src/cards';
import { AccountID } from 'src/domain/account.types';
import { DayDate, MonthDate } from 'src/domain/date.types';
import { paths, NavLink } from 'src/paths';
import { Icon } from 'src/ui-kit/Icon';
import { Icons } from 'src/ui-kit/Icons';

interface INavLink {
    name: string;
    path: string;
    exact?: boolean;
    icon: React.FunctionComponent;
    label: string;
}

const baseLinks: INavLink[] = [
    {
        name: 'wallets',
        path: paths.home(),
        exact: true,
        icon: Icons.walletOutline,
        label: 'Карты',
    },
    // {
    //     path: paths.home(),
    //     exact: true,
    //     icon: Icons.cardOutline,
    //     label: 'Wallets',
    // },
    {
        name: 'new-tx',
        path: paths.transactions.add(),
        icon: Icons.add,
        label: 'Добавить',
    },
    {
        name: 'categories',
        path: paths.categories.list(),
        icon: Icons.listOutline,
        label: 'Категории',
    },
    {
        name: 'menu',
        path: paths.menu(),
        icon: Icons.ellipsisHorizontalOutline,
        label: 'Меню',
    },
];

interface IProps {
    active?: 'wallets' | 'new-tx' | 'categories' | 'menu';
    account?: CardID | AccountID;
    scanQr?: boolean;
    newTxDate?: DayDate | MonthDate;
}

export const NavBar = ({ newTxDate, account, scanQr, active }: IProps) => {
    let links = baseLinks;

    if (newTxDate)
        links = updateLink(links, 'new-tx', (link) => ({
            ...link,
            path: paths.transactions.add({ date: newTxDate, account }),
        }));
    else if (account)
        links = updateLink(links, 'new-tx', (link) => ({
            ...link,
            path: paths.transactions.add({ account }),
        }));

    if (account)
        links = updateLink(links, 'wallets', (link) => ({
            ...link,
            path: paths.account.view(account),
        }));

    if (scanQr)
        links = updateLink(links, 'new-tx', (link) => ({
            ...link,
            icon: Icons.qrCode,
            label: 'Сканировать',
            path: paths.transactions.scan({ account }),
        }));

    return (
        <div
            className="appBottomMenu"
            data-qa-component="app-navbar"
        >
            <div className="appBottomMenu appBottomMenu_container">
                {links.map((link) => (
                    <NavLink
                        key={link.path}
                        to={link.path}
                        exact={link.exact}
                        className={active === link.name ? 'item active' : 'item'}
                        data-qa-element="app-navbar_link"
                    >
                        <div className="col">
                            <Icon
                                icon={link.icon}
                                size="normal"
                            />
                            <strong>{link.label}</strong>
                        </div>
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

function updateLink(links: INavLink[], name: string, fn: (link: INavLink) => INavLink): INavLink[] {
    return links.map((link) => (link.name === name ? fn(link) : link));
}
