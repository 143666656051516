import { AddCircleOutline } from '@arswarog/react-ionicons/AddCircleOutline';
import { AddOutline } from '@arswarog/react-ionicons/AddOutline';
import { ArrowForwardOutline } from '@arswarog/react-ionicons/ArrowForwardOutline';
import { ArrowUpCircleOutline } from '@arswarog/react-ionicons/ArrowUpCircleOutline';
import { CalendarOutline } from '@arswarog/react-ionicons/CalendarOutline';
import { CameraReverseOutline } from '@arswarog/react-ionicons/CameraReverseOutline';
import { CashOutline } from '@arswarog/react-ionicons/CashOutline';
import { ChevronBackOutline } from '@arswarog/react-ionicons/ChevronBackOutline';
import { CloseCircle } from '@arswarog/react-ionicons/CloseCircle';
import { CloseOutline } from '@arswarog/react-ionicons/CloseOutline';
import { CogOutline } from '@arswarog/react-ionicons/CogOutline';
import { EllipsisHorizontalOutline } from '@arswarog/react-ionicons/EllipsisHorizontalOutline';
import { FlashlightOutline } from '@arswarog/react-ionicons/FlashlightOutline';
import { ImageOutline } from '@arswarog/react-ionicons/ImageOutline';
import { ListOutline } from '@arswarog/react-ionicons/ListOutline';
import { NotificationsOutline } from '@arswarog/react-ionicons/NotificationsOutline';
import { PencilOutline } from '@arswarog/react-ionicons/PencilOutline';
import { QrCode } from '@arswarog/react-ionicons/QrCode';
import { RemoveCircleOutline } from '@arswarog/react-ionicons/RemoveCircleOutline';
import { SaveOutline } from '@arswarog/react-ionicons/SaveOutline';
import { SettingsOutline } from '@arswarog/react-ionicons/SettingsOutline';
import { TrashOutline } from '@arswarog/react-ionicons/TrashOutline';
import { WalletOutline } from '@arswarog/react-ionicons/WalletOutline';

export const Icons = {
    // system
    close: CloseOutline,

    // quick details
    income: AddCircleOutline,
    expense: RemoveCircleOutline,
    total: CashOutline,
    planned: CalendarOutline,

    // crud
    cog: CogOutline,
    edit: PencilOutline,
    remove: TrashOutline,
    add: AddCircleOutline,
    save: SaveOutline,
    clear: CloseCircle,

    // qr-scanner
    flashlight: FlashlightOutline,
    cameraReverse: CameraReverseOutline,
    image: ImageOutline,

    // other
    notificationsOutline: NotificationsOutline,
    chevronBackOutline: ChevronBackOutline,
    arrowUpCircleOutline: ArrowUpCircleOutline,
    settingsOutline: SettingsOutline,
    addOutline: AddOutline,
    arrowForwardOutline: ArrowForwardOutline,
    closeOutline: CloseOutline,
    listOutline: ListOutline,
    walletOutline: WalletOutline,
    ellipsisHorizontalOutline: EllipsisHorizontalOutline,
    qrCode: QrCode,
};
