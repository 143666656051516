import { db } from 'src/db';
import { DTO } from 'src/utils/dto';

import { IBillsService } from '../model';
import { BillID, IBill, IProverkaChekaResponse } from '../types';
import { encodeBillDto } from '../utils';

import { BillsScheme } from './bills.scheme';

export class BillsService implements IBillsService {
    public getById(id: BillID): Promise<DTO<IBill>> {
        return db.transaction(BillsScheme).get(id);
    }

    public getByNormalizedData(data: string): Promise<DTO<IBill>> {
        return db.transaction(BillsScheme).get(data, 'normalized');
    }

    public insert(dto: DTO<IBill>): Promise<BillID> {
        return db.transaction(BillsScheme).add(dto) as Promise<BillID>;
    }

    public async saveBill(bill: IBill): Promise<void> {
        await db.transaction(BillsScheme).update(encodeBillDto(bill));
    }

    public fetchBillData(qrCode: string, token: string): Promise<IProverkaChekaResponse> {
        return fetch('https://proverkacheka.com/api/v1/check/get', {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: new URLSearchParams({
                token,
                qrraw: qrCode,
            }),
        }).then((res) => res.json());
    }
}
