import { createToken } from '@flatom/core';

import { ICardDto } from './card.types';

export const cardsServiceToken = createToken<ICardsService>('CardsService');

export interface ICardsService {
    getAll(): Promise<ICardDto[]>;

    create(card: Omit<ICardDto, 'id'>): Promise<ICardDto>;
}
