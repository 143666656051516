import { useAtom } from '@flatom/react';

import { AccountGrips } from 'src/atoms/account-grips/account-grips.atom';
import { UUID } from 'src/models/common/common.types';

export function useCurrentAccountId() {
    return useAtom(AccountGrips)?.currentID;
}

export function useCurrentAccount() {
    return useAtom(AccountGrips)?.current;
}

export function useAccount(accountId: UUID) {
    return useAtom(AccountGrips, ({ accounts }) => accounts.get(accountId), [accountId]);
}
