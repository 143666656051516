import React from 'react';

import { CategoryMap } from 'src/categories/types';
import { CategoryIcon } from 'src/components/CategoryIcon';
import { MoneyView } from 'src/components/MoneyView';
import { ITransactionGrip } from 'src/models/abstract-grip/grip.types';
import { Link, paths } from 'src/paths';

import styles from './TxList.module.scss';
import { makeTxList } from './TxList.utils';

interface IProps {
    list: ITransactionGrip[];
    reverse?: boolean;
    categories: CategoryMap;
}

export const TxList = React.memo(({ list, reverse, categories }: IProps) => {
    const txList = makeTxList(list, reverse, categories);

    return (
        <ul className="listview image-listview inset">
            {txList.map((tx, index) => (
                <li
                    key={tx.id}
                    id={'tx-' + tx.id}
                    data-qa-type="widgets/tx-list"
                >
                    <Link
                        to={paths.transactions.view(tx.account.id, tx.date, tx.id)}
                        className="item"
                        data-qa-type="widgets/tx-list-item"
                        data-qa-amount={tx.changeAmount.amount}
                    >
                        <div className="icon-box">
                            <CategoryIcon icon={tx.category.icon} />
                        </div>
                        <div className="in">
                            <div>
                                <span data-qa-type="widgets/tx-list-item/title">
                                    {tx.first ? tx.first : <span className={styles.empty}>Без заголовка</span>}
                                </span>
                                <footer>
                                    {tx.second ? tx.second : <span className={styles.empty}>Без заголовка</span>}
                                </footer>
                            </div>
                            <span>
                                <MoneyView
                                    money={tx.changeAmount}
                                    sign
                                />
                            </span>
                        </div>
                        {/*<CategoryIcon category={tx.category}/>*/}
                        {/*<img src="assets/img/sample/avatar/avatar1.jpg" alt="image" className="image"/>*/}
                        {/*<div className="in">*/}
                        {/*    <div>*/}
                        {/*        {tx.first*/}
                        {/*            ? tx.first*/}
                        {/*            : <span className={styles.empty}>Без заголовка</span>*/}
                        {/*        }*/}
                        {/*        <footer>{tx.second*/}
                        {/*            ? tx.second*/}
                        {/*            : <span className={styles.empty}>Без заголовка</span>*/}
                        {/*        }</footer>*/}
                        {/*    </div>*/}
                        {/*    <span className="text-muted">*/}
                        {/*        <MoneyView money={tx.amount}/>*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                    </Link>
                </li>
            ))}
        </ul>
    );
});
