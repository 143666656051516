import { declareAtom } from '@flatom/core';

import { TimeStamp } from 'src/libs/time';
import { Money } from 'src/models/money';
import { SuiteID } from 'src/suite';

import { BillID, BillOperationType, BillStatus, IBill } from '../types';

const defaultBill: IBill = {
    id: '' as BillID,
    status: BillStatus.FailedToFetch,
    suiteId: '' as SuiteID,
    raw: '',
    rawQrCode: '',
    normalized: '',
    normalizedQrCode: '',

    type: BillOperationType.Расход,
    operationType: BillOperationType.Расход,
    ts: 0 as TimeStamp,
    dateTime: 0 as TimeStamp,
    amount: Money.create(0, 'RUB'),
    totalSum: Money.create(0, 'RUB'),

    details: undefined,
    items: [],
};

export const BillAtom = declareAtom<IBill>(
    'bill',
    defaultBill,
)({
    setBill(_, bill: IBill) {
        return bill;
    },
    notFound(state, _: void) {
        return {
            ...state,
            status: BillStatus.NotFound,
        };
    },
});
