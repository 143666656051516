import { TransactionType } from 'src/domain/transaction.types';

import { ICategoryDTO } from '../types';

export const defaultCategories: ICategoryDTO[] = [
    {
        id: '8ab98385-be7f-4fde-a95e-5a8f65d21ff5',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Еда',
        icon: 'default',
    },
    {
        id: '33f2f484-3f43-489f-87c4-eb70e067b11d',
        isInitial: true,
        parent: '8ab98385-be7f-4fde-a95e-5a8f65d21ff5',
        defaultTxType: TransactionType.Expense,
        name: 'Супермаркет',
        icon: 'default',
    },
    {
        id: '6c8a7d03-8ead-4d74-ae08-b53c33379f0f',
        isInitial: true,
        parent: '8ab98385-be7f-4fde-a95e-5a8f65d21ff5',
        defaultTxType: TransactionType.Expense,
        name: 'Ресторан',
        icon: 'default',
    },
    {
        id: '9ae64500-e2d7-406c-97b9-7d3f9f335f8e',
        isInitial: true,
        parent: '8ab98385-be7f-4fde-a95e-5a8f65d21ff5',
        defaultTxType: TransactionType.Expense,
        name: 'Ежедневное',
        icon: 'default',
    },
    {
        id: 'e5791e64-131e-4e18-a0ac-fef603903b68',
        isInitial: true,
        parent: '8ab98385-be7f-4fde-a95e-5a8f65d21ff5',
        defaultTxType: TransactionType.Expense,
        name: 'Вкусняшки',
        icon: 'default',
    },
    {
        id: 'df53b9ad-3b37-4be5-97bd-d0cfd59f26cf',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Развлечения',
        icon: 'default',
    },
    {
        id: '13e21f6b-1889-4411-b105-c0fc458139d9',
        isInitial: true,
        parent: 'df53b9ad-3b37-4be5-97bd-d0cfd59f26cf',
        defaultTxType: TransactionType.Expense,
        name: 'Кино и театр',
        icon: 'default',
    },
    {
        id: 'e891e5e2-c44d-4441-81bc-0831f0395c01',
        isInitial: true,
        parent: 'df53b9ad-3b37-4be5-97bd-d0cfd59f26cf',
        defaultTxType: TransactionType.Expense,
        name: 'Бар',
        icon: 'default',
    },
    {
        id: 'b6d27b02-1e75-4b26-b547-760841b9a519',
        isInitial: true,
        parent: 'df53b9ad-3b37-4be5-97bd-d0cfd59f26cf',
        defaultTxType: TransactionType.Expense,
        name: 'Концерт',
        icon: 'default',
    },
    {
        id: 'e0adb90e-75cd-439c-ba3a-fa4aa8683a61',
        isInitial: true,
        parent: 'df53b9ad-3b37-4be5-97bd-d0cfd59f26cf',
        defaultTxType: TransactionType.Expense,
        name: 'Игры',
        icon: 'default',
    },
    {
        id: '5c767710-9c33-4d12-bfca-35957589b949',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Автомобиль',
        icon: 'default',
    },
    {
        id: '1af5564b-8cc9-4cba-a4e3-8735a3fada93',
        isInitial: true,
        parent: '5c767710-9c33-4d12-bfca-35957589b949',
        defaultTxType: TransactionType.Expense,
        name: 'Топливо',
        icon: 'default',
    },
    {
        id: '0ddd6e2f-7b35-4199-9031-791aa313fa41',
        isInitial: true,
        parent: '5c767710-9c33-4d12-bfca-35957589b949',
        defaultTxType: TransactionType.Expense,
        name: 'Ремонт',
        icon: 'default',
    },
    {
        id: '052d504d-acbf-4e8c-aa1a-709bc62b98dc',
        isInitial: true,
        parent: '5c767710-9c33-4d12-bfca-35957589b949',
        defaultTxType: TransactionType.Expense,
        name: 'Автомойка',
        icon: 'default',
    },
    {
        id: '129f73e7-d292-4c30-bb9f-2c26e4801943',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Дом',
        icon: 'default',
    },
    {
        id: '202c1278-944a-48b2-81b2-e8748122877f',
        isInitial: true,
        parent: '129f73e7-d292-4c30-bb9f-2c26e4801943',
        defaultTxType: TransactionType.Expense,
        name: 'Электричество',
        icon: 'default',
    },
    {
        id: 'd1e2ba61-0ddd-4777-91a0-0b9db54a6fd1',
        isInitial: true,
        parent: '129f73e7-d292-4c30-bb9f-2c26e4801943',
        defaultTxType: TransactionType.Expense,
        name: 'Оплата аренды',
        icon: 'default',
    },
    {
        id: '08989a11-7c49-43d0-b653-c08b3cf4fa5b',
        isInitial: true,
        parent: '129f73e7-d292-4c30-bb9f-2c26e4801943',
        defaultTxType: TransactionType.Expense,
        name: 'Отопление',
        icon: 'default',
    },
    {
        id: 'b6b9ffa3-32f8-46ab-a016-9430902f740d',
        isInitial: true,
        parent: '129f73e7-d292-4c30-bb9f-2c26e4801943',
        defaultTxType: TransactionType.Expense,
        name: 'Ремонт',
        icon: 'default',
    },
    {
        id: '2de769fa-0022-497e-895f-7f0ef0d8a226',
        isInitial: true,
        parent: '129f73e7-d292-4c30-bb9f-2c26e4801943',
        defaultTxType: TransactionType.Expense,
        name: 'Бытовая химия',
        icon: 'default',
    },
    {
        id: '3fb2bc6c-3e68-42b3-ad01-6fb62a913069',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Одежда и обувь',
        icon: 'default',
    },
    {
        id: 'dda2d7f9-7588-41fc-bebc-4243fc4a2bc2',
        isInitial: true,
        parent: '3fb2bc6c-3e68-42b3-ad01-6fb62a913069',
        defaultTxType: TransactionType.Expense,
        name: 'Одежда',
        icon: 'default',
    },
    {
        id: '0b924ca8-f082-4576-ab44-95036b2ed0e2',
        isInitial: true,
        parent: '3fb2bc6c-3e68-42b3-ad01-6fb62a913069',
        defaultTxType: TransactionType.Expense,
        name: 'Обувь',
        icon: 'default',
    },
    {
        id: '3f7cbada-22d5-4976-83f0-81dea1d07be8',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Электроника',
        icon: 'default',
    },
    {
        id: '70d433e9-45f6-4245-afb2-c7e0f3d4a59f',
        isInitial: true,
        parent: '3f7cbada-22d5-4976-83f0-81dea1d07be8',
        defaultTxType: TransactionType.Expense,
        name: 'Компьютер',
        icon: 'default',
    },
    {
        id: '8e22d7d5-ff17-43d3-ad92-dc9d082d54fb',
        isInitial: true,
        parent: '3f7cbada-22d5-4976-83f0-81dea1d07be8',
        defaultTxType: TransactionType.Expense,
        name: 'Телефон',
        icon: 'default',
    },
    {
        id: '59bd0485-924a-4eda-886a-fa8c7d1f152c',
        isInitial: true,
        parent: '3f7cbada-22d5-4976-83f0-81dea1d07be8',
        defaultTxType: TransactionType.Expense,
        name: 'Планшет',
        icon: 'default',
    },
    {
        id: 'f7156d82-2ab8-4733-8749-e1d28da5bc77',
        isInitial: true,
        parent: '3f7cbada-22d5-4976-83f0-81dea1d07be8',
        defaultTxType: TransactionType.Expense,
        name: 'Игры',
        icon: 'default',
    },
    {
        id: '4f9d962e-d54c-4856-86d0-3d5f1ab564c8',
        isInitial: true,
        parent: '3f7cbada-22d5-4976-83f0-81dea1d07be8',
        defaultTxType: TransactionType.Expense,
        name: 'Камера',
        icon: 'default',
    },
    {
        id: '24214795-4bf0-4565-bdb3-3abd5c7340b1',
        isInitial: true,
        parent: '3f7cbada-22d5-4976-83f0-81dea1d07be8',
        defaultTxType: TransactionType.Expense,
        name: 'Телевизор',
        icon: 'default',
    },
    {
        id: '2b566916-496b-421e-8c86-d193218652b0',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Красота и здоровье',
        icon: 'default',
    },
    {
        id: 'd1d90f60-544f-4188-b771-efb1961801ff',
        isInitial: true,
        parent: '2b566916-496b-421e-8c86-d193218652b0',
        defaultTxType: TransactionType.Expense,
        name: 'Косметика',
        icon: 'default',
    },
    {
        id: 'ab64c9e4-3e87-4b19-b6a6-1f10bac6b8d6',
        isInitial: true,
        parent: '2b566916-496b-421e-8c86-d193218652b0',
        defaultTxType: TransactionType.Expense,
        name: 'Салон красоты',
        icon: 'default',
    },
    {
        id: '07b827ea-0ceb-439e-947e-f13c5df7685d',
        isInitial: true,
        parent: '2b566916-496b-421e-8c86-d193218652b0',
        defaultTxType: TransactionType.Expense,
        name: 'Медикаменты',
        icon: 'default',
    },
    {
        id: '9fee5ede-17ce-49fb-b707-fab876b18828',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Дети',
        icon: 'default',
    },
    {
        id: '7eea81a5-d78f-4f33-b047-e6f2298c47f8',
        isInitial: true,
        parent: '9fee5ede-17ce-49fb-b707-fab876b18828',
        defaultTxType: TransactionType.Expense,
        name: 'Игрушки',
        icon: 'default',
    },
    {
        id: 'e1a85134-ea03-424d-8be9-0b6229431127',
        isInitial: true,
        parent: '9fee5ede-17ce-49fb-b707-fab876b18828',
        defaultTxType: TransactionType.Expense,
        name: 'Одежда',
        icon: 'default',
    },
    {
        id: '7fad55e7-69a9-482a-a57c-d4a16cff7e81',
        isInitial: true,
        parent: '9fee5ede-17ce-49fb-b707-fab876b18828',
        defaultTxType: TransactionType.Expense,
        name: 'Обучение',
        icon: 'default',
    },
    {
        id: 'f66ff2a5-03ad-48ae-a89d-d98ba667fb85',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Питомцы',
        icon: 'default',
    },
    {
        id: 'c9bd13ab-9662-4fed-8011-193691c58368',
        isInitial: true,
        parent: 'f66ff2a5-03ad-48ae-a89d-d98ba667fb85',
        defaultTxType: TransactionType.Expense,
        name: 'Корм',
        icon: 'default',
    },
    {
        id: 'cf02b71c-b83d-43b2-9011-b23b2862b770',
        isInitial: true,
        parent: 'f66ff2a5-03ad-48ae-a89d-d98ba667fb85',
        defaultTxType: TransactionType.Expense,
        name: 'Игрушки',
        icon: 'default',
    },
    {
        id: '3b438751-8093-4025-8b49-694cb150aae3',
        isInitial: true,
        parent: 'f66ff2a5-03ad-48ae-a89d-d98ba667fb85',
        defaultTxType: TransactionType.Expense,
        name: 'Медикаменты',
        icon: 'default',
    },
    {
        id: '845e7c5a-a0c0-4955-9f5d-4d42aad99803',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Работа',
        icon: 'default',
    },
    {
        id: 'a03b4d99-a3b4-4d3b-beef-26331a18891d',
        isInitial: true,
        parent: '845e7c5a-a0c0-4955-9f5d-4d42aad99803',
        defaultTxType: TransactionType.Income,
        name: 'Зарплата',
        icon: 'default',
    },
    {
        id: '09bec873-fbb1-4d72-b5c0-ae094267db9d',
        isInitial: true,
        parent: '845e7c5a-a0c0-4955-9f5d-4d42aad99803',
        defaultTxType: TransactionType.Income,
        name: 'Премия',
        icon: 'default',
    },
    {
        id: 'd814bba2-c209-4d82-a2a9-53c77f9b06e6',
        isInitial: true,
        parent: '845e7c5a-a0c0-4955-9f5d-4d42aad99803',
        defaultTxType: TransactionType.Expense,
        name: 'Транспорт',
        icon: 'default',
    },
    {
        id: '21cc2211-289a-49aa-b638-acdbfbf7f8c9',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Транспорт',
        icon: 'default',
    },
    {
        id: '86d9b4fc-9936-4aba-8967-0cb0713307d1',
        isInitial: true,
        parent: '21cc2211-289a-49aa-b638-acdbfbf7f8c9',
        defaultTxType: TransactionType.Expense,
        name: 'Каршеринг',
        icon: 'default',
    },
    {
        id: '35bc9997-5e95-4c52-8cb0-55fb1cd05c6d',
        isInitial: true,
        parent: '21cc2211-289a-49aa-b638-acdbfbf7f8c9',
        defaultTxType: TransactionType.Expense,
        name: 'Такси',
        icon: 'default',
    },
    {
        id: '3e348d22-14b8-4ee7-a709-4a164695f498',
        isInitial: true,
        parent: '21cc2211-289a-49aa-b638-acdbfbf7f8c9',
        defaultTxType: TransactionType.Expense,
        name: 'Общественный транспорт',
        icon: 'default',
    },
    {
        id: '81a9a17f-607a-4c3f-a2a5-c701e7a211f7',
        isInitial: true,
        parent: '21cc2211-289a-49aa-b638-acdbfbf7f8c9',
        defaultTxType: TransactionType.Expense,
        name: 'Дальние поездки',
        icon: 'default',
    },
    {
        id: '47625cb8-6656-45cd-90d8-d55341ca8b72',
        isInitial: true,
        parent: '21cc2211-289a-49aa-b638-acdbfbf7f8c9',
        defaultTxType: TransactionType.Expense,
        name: 'Самолет',
        icon: 'default',
    },
    {
        id: '887919e2-2c70-417b-b1e6-17068631b2d3',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Интернет и телефон',
        icon: 'default',
    },
    {
        id: '872d0dbe-f09b-4445-9cc2-632e47de4974',
        isInitial: true,
        parent: '887919e2-2c70-417b-b1e6-17068631b2d3',
        defaultTxType: TransactionType.Expense,
        name: 'Домашний интернет',
        icon: 'default',
    },
    {
        id: 'e992dd7d-a7dd-4029-ae57-880feffadc04',
        isInitial: true,
        parent: '887919e2-2c70-417b-b1e6-17068631b2d3',
        defaultTxType: TransactionType.Expense,
        name: 'Мобильный интернет',
        icon: 'default',
    },
    {
        id: '8faa7cb8-b849-471e-94cf-58bbea8105e1',
        isInitial: true,
        parent: '887919e2-2c70-417b-b1e6-17068631b2d3',
        defaultTxType: TransactionType.Expense,
        name: 'Мобильный телефон',
        icon: 'default',
    },
    {
        id: '0e00a51d-3f13-4d84-a019-fc12e2192dc5',
        isInitial: true,
        parent: '887919e2-2c70-417b-b1e6-17068631b2d3',
        defaultTxType: TransactionType.Expense,
        name: 'Подписки',
        icon: 'default',
    },
    {
        id: '10c42929-dfc2-4fed-858b-38b55adf50d8',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Банк',
        icon: 'default',
    },
    {
        id: '36de097d-5cdb-4a6c-b8ce-2f0133beac07',
        isInitial: true,
        parent: '10c42929-dfc2-4fed-858b-38b55adf50d8',
        defaultTxType: TransactionType.Expense,
        name: 'Обслуживание',
        icon: 'default',
    },
    {
        id: '1296d699-dbb0-485c-9e2c-68c0b5002595',
        isInitial: true,
        parent: '10c42929-dfc2-4fed-858b-38b55adf50d8',
        defaultTxType: TransactionType.Expense,
        name: 'Кредит',
        icon: 'default',
    },
    {
        id: 'f2b38e86-ba4c-411f-8d7d-1aa64d56a34a',
        isInitial: true,
        parent: '10c42929-dfc2-4fed-858b-38b55adf50d8',
        defaultTxType: TransactionType.Expense,
        name: 'Ипотека',
        icon: 'default',
    },
    {
        id: '67242cb6-100c-4db3-829b-519e9fa685dc',
        isInitial: true,
        parent: '10c42929-dfc2-4fed-858b-38b55adf50d8',
        defaultTxType: TransactionType.Expense,
        name: 'Инвестирование',
        icon: 'default',
    },
    {
        id: 'd62cf77b-adac-4906-ac1a-0265d641cf27',
        isInitial: true,
        parent: '10c42929-dfc2-4fed-858b-38b55adf50d8',
        defaultTxType: TransactionType.Income,
        name: 'Проценты по вкладу',
        icon: 'default',
    },
    {
        id: '7918729f-554c-4873-8487-16b9a4246659',
        isInitial: true,
        parent: null,
        defaultTxType: TransactionType.Expense,
        name: 'Хобби',
        icon: 'default',
    },
];
