import React from 'react';

import { useAtom } from '@flatom/react';

import { CategoryIcon } from 'src/components/CategoryIcon';
import { Header } from 'src/components/Header';
import { Link, paths } from 'src/paths';
import { Icon } from 'src/ui-kit/Icon';
import { Icons } from 'src/ui-kit/Icons';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';

import { CategoriesAtom } from '../model/categories.atom';

export const CategoriesListPage = () => {
    const title = 'Категории';

    const { loading, tree } = useAtom(CategoriesAtom);

    if (loading)
        return (
            <>
                <Header
                    title={title}
                    back
                >
                    <Link to="/">
                        <Icon icon={Icons.addOutline} />
                    </Link>
                </Header>
                <Main>Loading</Main>
            </>
        );

    return (
        <>
            <Header
                title={title}
                back
            >
                <Link to={paths.categories.create()}>
                    <Icon icon={Icons.addOutline} />
                </Link>
            </Header>
            <Main>
                <ul className="listview image-listview inset mt-2">
                    {tree.map((category) => (
                        <li key={category.id}>
                            <Link
                                to={paths.categories.show(category.id)}
                                className="item"
                            >
                                <div className="icon-box">
                                    <CategoryIcon icon={category.icon} />
                                </div>
                                <div className="in">
                                    <div>{category.name}</div>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </Main>
            <NavBar />
        </>
    );
};
