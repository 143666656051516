import { createToken } from '@flatom/core';

export interface IStorageState {
    quota?: number;
    totalUsage?: number;
    appSize?: number;
    dataSize?: number;
}

export interface IStorageEstimate extends StorageEstimate {
    usageDetails?: {
        caches?: number;
        indexedDB?: number;
    };
}

export interface IStorageService {
    getEstimate(): Promise<IStorageEstimate>;
}

export const storageToken = createToken<IStorageService>('storageService');
