import { declareAtom } from '@flatom/core';
import { assoc } from 'sprout-data';

import { BillAtom } from '../../model/bill.atom';
import { BillID, IBill } from '../../types';

export interface IParams {
    billId: BillID;
}

export interface IViewBillPageState {
    props: Partial<IParams>;
    loading: boolean;
    bill?: IBill;
}

export const ViewBillPageAtom = declareAtom<IViewBillPageState>('bills/pages/view', {
    props: {},
    loading: true,
    bill: undefined,
})(
    {
        init(state, payload: { billId: BillID }) {
            state = assoc(state, 'props', payload);

            return state;
        },
        propsChange(state, payload: { billId: BillID }) {
            state = assoc(state, 'props', payload);

            return state;
        },
        destroy(state, _: void) {
            return state;
        },
    },
    (on) => [
        on(BillAtom, (state, bill) => {
            state = assoc(state, ['loading'], !bill);

            return assoc(state, ['bill'], bill);
        }),
    ],
);
