import React from 'react';

import { useField } from '@flatom/forms-react';
import NumberFormat from 'react-number-format';

import { Money } from 'src/models/money';

import { BaseField, IBaseFieldProps } from './BaseField';

export const MoneyField = (props: IBaseFieldProps) => {
    const { name } = props;
    const field = useField<Money>(name);
    const money = field.value as Money;

    const onChange = (stringValue: string) => {
        const value = Money.create(stringValue || null, 'RUB');

        !value.equal(field.value) && field.handleChange(value);
    };

    let value = '';

    if (!money.isEmpty) {
        value = money.hasFractional() ? money.amount : money.getEntire();
    }

    return (
        <BaseField {...props}>
            {/*@ts-ignore something wrong with library's types*/}
            <NumberFormat
                id={field.path}
                name={field.path}
                value={value}
                onValueChange={(values) => onChange(values.value)}
                onFocus={field.handleFocus}
                onBlur={field.handleBlur}
                disabled={!field.enabled}
                suffix={' ' + money.getSymbol()}
                thousandSeparator=" "
                decimalScale={money.currency.precision}
                allowNegative={false}
            />
        </BaseField>
    );
};
