import { declareSchema } from 'src/libs/indexed-db/scheme';

import { ICategoryDTO } from '../types';

export const CategoriesScheme = declareSchema<ICategoryDTO>(
    'categories',
    {
        keyPath: 'id',
    },
    {
        async v20220208_2002(objectStore) {
            objectStore.clear();
        },
    },
);
