import React, { ReactNode } from 'react';

import { IGrip } from 'src/models/abstract-grip/grip.types';
import { AccountGrip } from 'src/models/account-grip/grip.class';
import { Money } from 'src/models/money';
import { Icons } from 'src/ui-kit/Icons';

import { BarColor, IQuickDetailsItem, StatBox } from './StatBox';

interface IProps {
    grip: AccountGrip;
}

export const GripQuickDetails = ({ grip }: IProps) => {
    if (!grip)
        return (
            <div
                className="alert alert-warning mt-2"
                role="alert"
            >
                Grip not specified
            </div>
        );

    const { income, expense, planed, total } = getQuickDetails(grip);

    const stats: ReactNode[][] = [
        [
            <StatBox
                title="Доход"
                icon={Icons.income}
                {...income}
            />,
            <StatBox
                title="Расход"
                icon={Icons.expense}
                {...expense}
            />,
        ],
        [
            <StatBox
                title="Регулярные"
                icon={Icons.planned}
                {...planed}
            />,
            <StatBox
                title="Итог"
                icon={Icons.total}
                {...total}
            />,
        ],
    ];

    return (
        <div className="section">
            {stats.map((row, index) => (
                <div
                    key={index}
                    className="row mt-2 justify-content-center"
                >
                    {row.map((cell, cellIndex) => (
                        <div
                            key={cellIndex}
                            className="col-6"
                        >
                            {cell}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

function getQuickDetails(grip: IGrip): {
    income: IQuickDetailsItem;
    expense: IQuickDetailsItem;
    planed: IQuickDetailsItem;
    total: IQuickDetailsItem;
} {
    const month = grip.months[0];

    if (!month)
        return {
            income: {
                amount: null,
                progress: null,
                color: BarColor.Neutral,
            },
            expense: {
                amount: null,
                progress: null,
                color: BarColor.Neutral,
            },
            planed: {
                amount: Money.create(0, 'RUB'),
                progress: null,
                color: BarColor.Neutral,
            },
            total: {
                amount: null,
                progress: null,
                color: BarColor.Neutral,
            },
        };

    return {
        income: {
            amount: month.income,
            progress: null,
            color: BarColor.Neutral,
        },
        expense: {
            amount: month.expense,
            progress: null,
            color: BarColor.Neutral,
        },
        planed: {
            amount: Money.create(0, 'RUB'),
            progress: null,
            color: BarColor.Neutral,
        },
        total: {
            amount: month.balance,
            progress: null,
            color: BarColor.Neutral,
        },
    };
}
