import { declareEffect } from '@flatom/core';

import { waitForActions } from 'src/libs/flatom';

import { DialogAtom } from './dialog.atom';
import { IConfirmDialog, INotifyDialog } from './dialog.types';

const NS = 'dialogEffects';

export const openNotification = declareEffect<INotifyDialog>(
    NS + ':openNotification',
    async ({ dispatch, subscribe }, payload) => {
        await dispatch(DialogAtom.a.notify(payload));
        await waitForActions(subscribe, [DialogAtom.a.action]);

        return dispatch(DialogAtom.a.close());
    },
);

export const openConfirm = declareEffect<IConfirmDialog>(
    NS + ':openConfirm',
    async ({ dispatch, subscribe }, payload) => {
        await dispatch(DialogAtom.a.confirm(payload));
        const action = await waitForActions(subscribe, [DialogAtom.a.action]);

        await dispatch(DialogAtom.a.close());

        return action.payload === 'yes';
    },
);
