import React, { useCallback, useEffect } from 'react';

import { useAction, useAtom } from '@flatom/react';
import { useHistory, useRouteMatch } from 'react-router';

import { AccountGrips } from 'src/atoms/account-grips/account-grips.atom';
import { MonthGrips } from 'src/atoms/month-grips/month-grips.atom';
import { loadMonths } from 'src/atoms/months/months.actions';
import { CategoriesAtom } from 'src/categories/model/categories.atom';
import { Header } from 'src/components/Header';
import { MonthDate } from 'src/domain/date.types';
import { useScrollTo } from 'src/hooks/scrollTo';
import { IMonthGripBrief } from 'src/models/abstract-grip/grip.types';
import { AccountGrip } from 'src/models/account-grip/grip.class';
import { UUID } from 'src/models/common/common.types';
import { dayDateToMonth } from 'src/models/common/date.utils';
import { paths } from 'src/paths';
import { Main } from 'src/ui-kit/Main';
import { MonthTxList } from 'src/widgets/MonthTxList';
import { MonthViewHeadWidget } from 'src/widgets/MonthViewWidget';
import { NavBar } from 'src/widgets/NavBar';
import { SwipeItemWidget, SwipeWidget } from 'src/widgets/SwipeWidget';

interface IParams {
    account: string;
    month?: MonthDate;
    txId?: UUID;
}

export const AccountMonthsPage = () => {
    // prepare
    const { params } = useRouteMatch<IParams>();
    const account = useAtom(AccountGrips, ({ accounts }) => accounts.get(params.account), [params.account]);

    // create months list
    const monthsList = [...(account ? account.months : [])];

    monthsList.reverse();

    // check before render
    if (!account) return <div>No account</div>;

    if (!account.months.length) return <div>No months in this account</div>;

    return (
        <AccountMonthsPageDisplay
            account={account}
            date={params.month || account.lastMonthDate}
            focusedTx={params.txId}
        />
    );
};

interface IPropsDisplay {
    account: AccountGrip;
    date: MonthDate;
    focusedTx: UUID;
}

export const AccountMonthsPageDisplay = ({ account, date, focusedTx }: IPropsDisplay) => {
    // prepare
    const months = useAtom(MonthGrips);
    const history = useHistory();
    const month = dayDateToMonth(date);
    const { map: categories } = useAtom(CategoriesAtom);

    // create months list
    const monthsList = [...(account ? account.months : [])];

    monthsList.reverse();

    // create handlers
    const changeMonth = useCallback(
        (newMonthNum) => {
            history.replace(paths.account.month(account.id, newMonthNum));
        },
        [account, history],
    );

    const loadMonth = useAction<string>((id) => (id ? loadMonths([id]) : null), []);

    // get months
    let monthIndex = account.months.findIndex((item) => item.month === month);

    if (monthIndex === -1) monthIndex = 0;
    const monthBrief: IMonthGripBrief = account.months[monthIndex];
    const prevMonth = account.months[monthIndex + 1];
    const nextMonth = account.months[monthIndex - 1];

    // load months
    useEffect(() => {
        if (monthBrief && !months.has(monthBrief.id)) loadMonth(monthBrief.id);
        if (prevMonth && !months.has(prevMonth.id)) loadMonth(prevMonth.id);
        if (nextMonth && !months.has(nextMonth.id)) loadMonth(nextMonth.id);
    }, [prevMonth, nextMonth, monthBrief, loadMonth, months]);
    const currentMonth = months.get(monthBrief?.id);

    useScrollTo('tx-', focusedTx, currentMonth);
    useScrollTo('day-', date, currentMonth);

    // render
    return (
        <>
            <Header
                back
                title={`Карта ${account.name}`}
            />
            <Main>
                <SwipeWidget
                    onChange={changeMonth}
                    current={dayDateToMonth(monthBrief.month)}
                    showButtons
                >
                    {monthsList.map((item) => (
                        <SwipeItemWidget key={item.month}>
                            <MonthViewHeadWidget
                                months={months}
                                brief={item}
                            />
                        </SwipeItemWidget>
                    ))}
                </SwipeWidget>
                <MonthTxList
                    month={currentMonth}
                    categories={categories}
                    reverse
                />
            </Main>
            <NavBar
                newTxDate={date}
                account={account.id}
            />
        </>
    );
};
