import { RouteProps } from 'react-router';

import { AddCardPage, CurrentPage, ViewCardPage } from 'src/cards';
import { AccountMonthsPage } from 'src/pages/AccountMonthsPage';
import { account } from 'src/paths/accounts';

export const accountsRoutes: RouteProps[] = [
    {
        path: account.current(),
        exact: true,
        component: CurrentPage,
    },
    {
        path: account.create(),
        exact: true,
        component: AddCardPage,
    },
    {
        path: account.view(),
        exact: true,
        component: ViewCardPage,
    },
    {
        path: account.monthFocusTx(),
        component: AccountMonthsPage,
    },
    {
        path: account.month(),
        component: AccountMonthsPage,
    },
    {
        path: account.monthsList(),
        component: AccountMonthsPage,
    },
];
