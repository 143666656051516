import { DayDate, MonthDate } from 'src/domain/date.types';
import { TransactionID } from 'src/domain/transaction.dto';

import { baseUrl } from './config';

export const account = {
    current: () => `${baseUrl}/accounts`,
    create: () => `${baseUrl}/accounts/create`,
    view: (account = ':account') => `${baseUrl}/accounts/${account}`,
    monthsList: (account = ':account') => `${baseUrl}/accounts/${account}/months`,
    month: (account = ':account', month: MonthDate | DayDate = ':month' as MonthDate) =>
        `${baseUrl}/accounts/${account}/months/${month}`,
    monthFocusTx: (
        account = ':account',
        month: MonthDate = ':month' as MonthDate,
        tx: TransactionID = ':txId' as TransactionID,
    ) => `${baseUrl}/accounts/${account}/months/${month}!tx-${tx}`,
};
