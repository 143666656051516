import { TransactionType } from 'src/domain/transaction.types';

import { CategoryID, CategoryMap, ICategory, IRootCategory } from '../types';

export interface ICategoriesState {
    loading: boolean;
    list: ICategory[];
    tree: IRootCategory[];
    map: CategoryMap;
}

export const defaultCategory: ICategory = Object.freeze({
    id: '00000000-0000-0000-0000-000000000000' as CategoryID,
    isInitial: true,
    parent: null,
    defaultTxType: TransactionType.Expense,
    name: '- Не уточнено -',
    icon: 'default',
});
