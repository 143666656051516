import { DayDate } from 'src/domain/date.types';
import { Packable, PackableClass } from 'src/libs/packable/decorator';
import { Packer } from 'src/libs/packable/packable';
import { EmptySummary, ISummary, summaryPacker } from 'src/models/common/common.types';
import { parseDayDate } from 'src/models/common/date.utils';
import { Transaction } from 'src/models/transaction/transaction.class';
import { ITransaction } from 'src/models/transaction/transaction.types';
import { calculateSummary } from 'src/models/transaction/transactions.utils';

export interface IDay {
    date: DayDate;
    summary: ISummary;
    transactions: ITransaction[];
}

@PackableClass((data) => new Day(data))
export class Day implements IDay {
    @Packable(String) public readonly date: DayDate = '' as DayDate;
    @Packable(summaryPacker) public readonly summary: ISummary = EmptySummary;
    @Packable([Transaction]) public readonly transactions: Transaction[] = [];
    public readonly dateTime: Date = new Date();

    protected constructor(data: Partial<Day>) {
        Object.assign(this, data);
        this.dateTime = parseDayDate(this.date);
    }

    public static create(date: DayDate): Day {
        return new Day({ date });
    }

    public static fromJSON(value: any): Day {
        return Packer.get(Day).decode(value);
    }

    public toJSON(): any {
        return Packer.get(Day).encode(this);
    }

    public upsertTransaction(tx: ITransaction): Day {
        let found = false;
        const transactions: ITransaction[] = this.transactions.map((item) =>
            item.id === tx.id ? ((found = true), tx) : item,
        );

        if (!found) transactions.push(tx);

        const summary: ISummary = calculateSummary(transactions);

        return new Day({
            ...this,
            summary,
            transactions,
        });
    }
}
