import { RouteProps } from 'react-router';

import { LicensesPage } from 'src/pages/LicensesPage';
import { MainMenu } from 'src/pages/main-menu/MainMenu';
import { paths } from 'src/paths';

import { accountsRoutes } from './accounts';
import { billsRoutes } from './bills';
import { categoriesRoutes } from './categories';
import { transactionRoutes } from './transactions';

export const routes: RouteProps[] = [
    ...accountsRoutes,
    ...transactionRoutes,
    ...categoriesRoutes,
    ...billsRoutes,
    {
        path: paths.menu(),
        component: MainMenu,
    },
    {
        path: paths.licenses(),
        component: LicensesPage,
    },
];
