// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IScheme<T extends Record<string, any> = Record<string, unknown>> {
    autoIncrement: boolean;
    keyPath: string | string[] | null;
    collection: string;
    version: number; // TODO rename to version
    migrations: MigrationWithVersion[];
}

export interface RootMigration {
    (db: IDBDatabase, transaction: IDBTransaction): Promise<void> | void;
}
export interface Migration {
    (objectStore: IDBObjectStore, transaction: IDBTransaction, db: IDBDatabase): Promise<void> | void;
}
export interface MigrationWithVersion extends Migration {
    version: number;
    collection: string;
}

export interface Migrations {
    [key: string]: Migration;
}

export function declareSchema<T extends Record<string, any>>(
    collection: string,
    options: IDBObjectStoreParameters,
    migrations: Migrations = {},
): IScheme<T> {
    let latestVersion = 1;

    const timeline: MigrationWithVersion[] = Object.keys(migrations).map((key) => {
        const migration = migrations[key] as MigrationWithVersion;
        const version = getDateTimeFromMigrationNam(collection, key);

        migration.version = version;
        migration.collection = collection;
        if (latestVersion < version) latestVersion = version;

        return migration;
    });

    return {
        collection,
        keyPath: options.keyPath || null,
        autoIncrement: !!options.autoIncrement,
        version: latestVersion,
        migrations: timeline,
    };
}

const nameRegexp = /^v(\d{8})_(\d{4}|\d{6})$/;

function getDateTimeFromMigrationNam(collection: string, name: string): number {
    const result = nameRegexp.exec(name);

    if (!result) throw new InvalidMigrationNameError(collection, name);

    const date = +result[1];

    if (!date) throw new InvalidMigrationNameError(collection, name);
    const time = result[2].length === 6 ? +result[2] : +result[2] * 100;

    return date * 10 ** 6 + time;
}

export class InvalidMigrationNameError extends Error {
    constructor(collection: string, name: string) {
        super(`Invalid migration name "${name}" in collection "${collection}"`);
    }
}
