import { format } from 'date-fns';

import { DayDate, MonthDate } from 'src/domain/date.types';

export function dateToDayDate(date: Date): DayDate {
    return format(date, 'yyyy-MM-dd') as DayDate;
}

export function dateToMonthDate(date: Date): DayDate {
    return format(date, 'yyyy-MM') as DayDate;
}

export function parseMonthDate(value: MonthDate): Date {
    const match: RegExpMatchArray | null = value.match(/^(\d{4})-(\d{2})$/);

    if (!match) throw new Error(`Invalid MonthDate "${value}"`);

    const year = +match[1];
    const month = +match[2] - 1;
    const date = new Date(year, month, 1, 12, 0, 0);

    if (date.getFullYear() !== year) throw new Error(`Invalid year in "${value}"`);
    if (date.getMonth() !== month) throw new Error(`Invalid month in "${value}"`);

    return date;
}

export function parseDayDate(value: DayDate): Date {
    const match: RegExpMatchArray | null = value.match(/^(\d{4})-(\d{2})-(\d{2})$/);

    if (!match) throw new Error(`Invalid DayDate "${value}"`);

    const year = +match[1];
    const month = +match[2] - 1;
    const day = +match[3];
    const date = new Date(year, month, day, 12, 0, 0);

    if (date.getFullYear() !== year) throw new Error(`Invalid year in "${value}"`);
    if (date.getMonth() !== month) throw new Error(`Invalid month in "${value}"`);
    if (date.getDate() !== day) throw new Error(`Invalid date in "${value}"`);

    return date;
}

export function getDayFromDayDate(date: DayDate): number {
    const match: RegExpMatchArray | null = date.match(/^(\d{4})-(\d{2})-(\d{2})$/);

    if (!match) throw new Error(`Invalid DayDate "${date}"`);

    return +match[3];
}

export function dayDateToMonth(day: DayDate | MonthDate): MonthDate {
    return day.split('-', 2).join('-') as MonthDate;
}

export function isDayDate(date?: string): date is DayDate {
    return date && /^(\d{4})-(\d{2})-(\d{2})$/.test(date);
}

export function isMonthDate(date?: string): date is MonthDate {
    return date && /^(\d{4})-(\d{2})$/.test(date);
}

export function splitDayDate(date: DayDate | MonthDate) {
    if (!date) {
        throw new Error('Date is empty');
    }

    const match = date.match(/^(\d{4})-(\d{2})(-(\d{2}))?$/);

    if (!match) {
        throw new Error(`Invalid date "${date}"`);
    }

    return [+match[1], +match[2], match[4] ? +match[4] : 0];
}

export function isMonthEqual(date1?: DayDate | MonthDate, date2?: DayDate | MonthDate): boolean {
    const [d1, m1] = splitDayDate(date1);
    const [d2, m2] = splitDayDate(date2);

    return d1 === d2 && m1 === m2;
}
