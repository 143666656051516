import { getDayDate } from 'src/models/dates';
import { Money } from 'src/models/money';
import { ITransactionForm, TransactionType } from 'src/models/transaction/transaction.types';

export function buildTransaction(params: URLSearchParams): ITransactionForm {
    const tx: ITransactionForm = {
        title: params.get('title') || '',
        type: TransactionType.Expense,
        amount: Money.create(params.get('amount') || null, 'RUB') as any,
        date: params.get('date') || getDayDate(),
        account: params.get('account') || '',
        category: params.get('category') || '00000000-0000-0000-0000-000000000000',
        qrCode: params.get('qrCode') ? decodeURIComponent(params.get('qrCode')) : '',
    };

    if (params.get('type') && params.get('type') in TransactionType) {
        tx.type = +params.get('type') as any;
    }

    return tx;
}
