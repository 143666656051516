import { MonthDate } from 'src/domain/date.types';
import { UUID } from 'src/models/common/common.types';
import { Money } from 'src/models/money';

import { AbstractDayGrip } from './day-grip.class';
import { IMonthGrip } from './grip.types';

export abstract class AbstractMonthGrip implements IMonthGrip {
    id: UUID;
    readonly balance: Money;
    readonly balanceOnEnd: Money;
    readonly balanceOnStart: Money;
    readonly days: AbstractDayGrip[];
    readonly expense: Money;
    readonly income: Money;
    readonly month: MonthDate;

    [Symbol.toStringTag] = 'AbstractMonthGrip';

    constructor(month: MonthDate, balanceOnStart: Money, days: AbstractDayGrip[]) {
        this.month = month;
        if (days.length)
            days.reduce((last, next) => {
                if (last.date >= next.date) throw new Error('Days must be sorted');

                return next;
            });
        this.days = days;

        // balance
        this.balanceOnStart = balanceOnStart;
        this.balanceOnEnd = this.days.reduce((lastSum, day) => {
            const sum = lastSum.add(day.balance);

            if (!day.balanceOnStart.equal(lastSum))
                throw new Error(
                    `Start balance on day ${day.date} must be ${lastSum} but received ${day.balanceOnStart}`,
                );
            if (!day.balanceOnEnd.equal(sum))
                throw new Error(`End balance on day ${day.date} must be ${sum} but received ${day.balanceOnEnd}`);
            if (!day.balance.equal(day.balanceOnEnd.sub(day.balanceOnStart)))
                throw new Error(
                    `Balance on day ${day.date} must be ${day.balanceOnEnd.sub(day.balanceOnStart)} but received ${
                        day.balance
                    }`,
                );

            return sum;
        }, this.balanceOnStart);
        this.balance = this.balanceOnEnd.sub(this.balanceOnStart);

        this.income = this.days.reduce((sum, tx) => sum.add(tx.income), Money.create(0, 'RUB'));
        this.expense = this.days.reduce((sum, tx) => sum.add(tx.expense), Money.create(0, 'RUB'));
    }
}
