import { ICardCacheDto } from 'src/cards/model/card.types';
import { ICardsCacheService } from 'src/cards/model/cards-cache.service';
import { AccountScheme, db } from 'src/db';
import { AccountDTO } from 'src/models/account-dto/account.class';

export class CardsCacheService implements ICardsCacheService {
    getAll(): Promise<ICardCacheDto[]> {
        return db
            .transaction(AccountScheme)
            .getAll()
            .then((result) => {
                if (!result.length) {
                    console.warn('No accounts exists');
                }

                return result.map(AccountDTO.fromJSON).map(
                    (data): ICardCacheDto => ({
                        id: data.id,
                        balance: data.balance.toJSON(),
                    }),
                );
            });
    }

    async upsert(cache: ICardCacheDto): Promise<ICardCacheDto> {
        return cache;
    }
}
