import React, { ChangeEvent, createRef } from 'react';

import { useAction, useAtom } from '@flatom/react';

import { Backup } from 'src/atoms/backup/backup.atom';
import { restoreFromFile, runBackup } from 'src/atoms/backup/backup.effects';
import { BackupStatus } from 'src/atoms/backup/backup.types';
import { FileSize } from 'src/components/FileSize';

export const BackupPart = () => {
    const { fileContent, fileName, fileSize, status } = useAtom(Backup);

    const inputFileRef = createRef<HTMLInputElement>();

    const handleRunBackup = useAction(runBackup);
    const handleLoaded = useAction(Backup.a.loaded);
    const handleRestore = useAction((e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files.item(0);

        if (!file) {
            return;
        }

        return restoreFromFile(file);
    });

    const handleClickRestore = () => inputFileRef.current.click();

    return (
        <>
            <div className="listview-title mt-1">Резервные копии</div>
            <ul
                className="listview image-listview text inset"
                style={{ marginLeft: '0', marginRight: '0' }}
            >
                <li>
                    <div className="action item">
                        {status === BackupStatus.None && (
                            <div
                                className="in"
                                onClick={() => handleRunBackup()}
                            >
                                <div>
                                    <a>Создать резервную копию</a>
                                </div>
                            </div>
                        )}
                        {status === BackupStatus.Creating && (
                            <div className="in">
                                <div>
                                    <i>Сборка резервной копии...</i>
                                </div>
                            </div>
                        )}
                        {status === BackupStatus.Ready && (
                            <div
                                className="in"
                                onClick={() => handleLoaded()}
                            >
                                <div>
                                    <a
                                        href={fileContent}
                                        download={fileName}
                                    >
                                        Скачать резервную копию
                                    </a>
                                </div>
                                <span className="text-muted">
                                    <FileSize>{fileSize}</FileSize>
                                </span>
                            </div>
                        )}
                        {status === BackupStatus.Error && (
                            <div
                                className="in"
                                onClick={() => handleRunBackup()}
                            >
                                <div>Ошибка при создании резервной копии</div>
                            </div>
                        )}
                    </div>
                </li>
                <li>
                    <div className="action item">
                        <div
                            className="in"
                            onClick={handleClickRestore}
                        >
                            <div>Восстановить из резервной копии</div>
                        </div>
                    </div>
                </li>
            </ul>
            <input
                type="file"
                ref={inputFileRef}
                onChange={handleRestore}
                style={{ display: 'none' }}
            />
        </>
    );
};
