import { declareFormSchema, validators } from '@flatom/forms';

import { CardID } from 'src/cards/model/card.types';
import { CategoryID } from 'src/categories';
import { DayDate } from 'src/domain/date.types';
import { TransactionType } from 'src/domain/transaction.types';
import { getDayDate } from 'src/models/dates';
import { Money } from 'src/models/money';
import { moneyValidator } from 'src/utils/validators/money';

export interface IFormData {
    amount: Money;
    type: TransactionType;
    category: CategoryID;
    title: string;
    account: CardID;
    date: DayDate;
    qrCode: string;
}

export const addTransactionFormSchema = declareFormSchema<IFormData>({
    id: 'add-tx',
    nullable: true,
    controls: {
        amount: {
            defaultValue: Money.empty,
            validators: [validators.required(), moneyValidator()],
        },
        type: {
            defaultValue: TransactionType.Expense,
            validators: [validators.required()],
        },
        category: {
            defaultValue: '' as CategoryID,
            validators: [validators.required()],
        },
        title: {
            defaultValue: '',
            validators: [],
        },
        account: {
            defaultValue: '' as CardID,
            validators: [validators.required()],
        },
        date: {
            defaultValue: getDayDate(new Date()),
            validators: [validators.required()],
        },
        qrCode: {
            defaultValue: '',
            validators: [],
        },
    },
});
