import { ObjectIDHelper } from './object-id.helper';
import { IObjectIdService, ObjectID } from './object-id.types';

export class ObjectIdService implements IObjectIdService {
    public readonly processId: number;
    public index = 0;

    constructor() {
        this.processId = Math.floor(Math.random() * 2 ** (7 * 4));
    }

    generate<T extends ObjectID = ObjectID>(ts?: number, index?: number): T {
        index = index === void 0 ? ++this.index : index;
        ts = ts || new Date().getTime();

        return ObjectIDHelper.make(ts, this.processId, index);
    }
}
