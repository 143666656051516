import { AbstractMonthGrip } from 'src/models/abstract-grip/month-grip.class';
import { IAccount } from 'src/models/account-dto/account.types';
import { Money } from 'src/models/money';
import { IMonth } from 'src/models/month/month-legacy.types';

import { AccountDayGrip } from './day-grip.class';

export class AccountMonthGrip extends AbstractMonthGrip {
    constructor(balanceOnStart: Money, month: IMonth, account: IAccount) {
        const { days, balanceOnEnd } = month.days.reduce(
            ({ days, balanceOnEnd }, day) => {
                const grip = new AccountDayGrip(balanceOnEnd, day, account);

                if (!grip.transactions.length)
                    return {
                        days,
                        balanceOnEnd,
                    };
                days.push(grip);

                return {
                    days,
                    balanceOnEnd: grip.balanceOnEnd,
                };
            },
            {
                days: [],
                balanceOnEnd: balanceOnStart,
            },
        );

        super(month.month, balanceOnStart, days);
        this.id = month.id;

        if (!this.balanceOnEnd.equal(balanceOnEnd))
            throw new Error(`Wrong balance on end: expected ${balanceOnEnd} but received ${this.balanceOnEnd}`);
    }
}
