import { createToken } from '@flatom/core';

export enum BackupStatus {
    None,
    Creating,
    Ready,
    Error,
}

export interface IBackupState {
    status: BackupStatus;
    fileContent: string;
    fileName: string;
    fileSize: number;
}

export interface IBackupService {
    createBackupFileContent(): Promise<Uint8Array>;
    restoreBackupFromJson(data: Uint8Array): Promise<void>;
}

export const backupToken = createToken<IBackupService>('backupService');
