import React, { useState } from 'react';

import { Form } from 'react-final-form';

import { TextField } from 'src/components/inputs/TextField';

export interface ICardForm {
    name: string;
    cardNumber: string;
    bank: string;
}

interface IProps {
    value: ICardForm;
    onSubmit: (value: ICardForm) => void;
    validate: (value: ICardForm) => Partial<{ [key in keyof ICardForm]: string }>;
}

export const CardForm = ({ onSubmit, value, validate }: IProps) => {
    const [cardNumberPlaceholder] = useState(Math.floor(Math.random() * 9000 + 1000).toString());

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={value}
            validate={validate}
            render={({ handleSubmit, invalid }) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        name="name"
                        label="Title"
                        placeholder="The best card"
                    />
                    <TextField
                        name="cardNumber"
                        label="Last 4 numbers of card"
                        placeholder={cardNumberPlaceholder}
                    />
                    <TextField
                        name="bank"
                        label="Bank"
                    />
                    <button
                        type="submit"
                        disabled={invalid}
                        className="btn btn-primary rounded mr-1"
                    >
                        Save
                    </button>
                </form>
            )}
        />
    );
};
