import React from 'react';

import { IAccount } from 'src/models/account-dto/account.types';

import { IBaseInputProps } from './BaseInput';
import { SelectField } from './SelectField';

interface IAccountFieldProps extends IBaseInputProps {
    accounts: IAccount[];
}

export const AccountField = ({ accounts, ...props }: IAccountFieldProps) => {
    const options = accounts.map((acc) => ({
        value: acc.id,
        label: acc.name,
    }));

    return (
        <SelectField
            {...props}
            options={options}
        />
    );
};
