import { Action } from '@flatom/core';
import { put, fork } from 'redux-saga/effects';

import {
    updateAccountGrip,
    updateAccountGrips,
    updateAccountGripSuccess,
} from 'src/atoms/account-grips/account-grips.actions';
import { AccountDTO } from 'src/models/account-dto/account.class';
import { AccountGrip } from 'src/models/account-grip/grip.class';

import { sagaLauncher } from '../saga-launcher';

sagaLauncher.onAction(updateAccountGrips, updateAccountGripsSaga);
sagaLauncher.onAction(updateAccountGrip, updateAccountGripSaga);

export function* updateAccountGripsSaga(action: Action<AccountDTO[]>) {
    console.log('*** updateAccountGripsSaga started');
    const accounts = action.payload;

    for (const account of accounts) yield fork(updateAccountGripSaga, updateAccountGrip(account));
}

export function* updateAccountGripSaga(action: Action<AccountDTO>) {
    const account = action.payload;

    console.log(`*** updateAccountGripSaga started for account ${account.name}`);

    const grip = new AccountGrip(account);

    yield put(updateAccountGripSuccess(grip));
}
