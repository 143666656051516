import React, { ReactNode } from 'react';

import block from 'bem-css-modules';

import styles from './Button.module.scss';

export type HTMLButtonType = 'button' | 'reset' | 'submit';

interface IProps {
    secondary?: boolean;
    type?: HTMLButtonType;
    disabled?: boolean;
    children: ReactNode;
    onClick?: () => void;
}

const b = block(styles, 'button');

export const Button = ({ secondary, disabled, children, type = 'button', onClick }: IProps) => {
    return (
        <button
            className={b({ secondary, disabled })}
            type={type}
            onClick={onClick}
        >
            {children}
        </button>
    );
};
