import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import QrScanner from 'qr-scanner';

export type ImageSource = HTMLCanvasElement | HTMLVideoElement | ImageBitmap | HTMLImageElement | File | URL | string;
export interface UseQrScanner {
    ready: boolean;
    // scanner
    start: () => void;
    pause: () => void;
    stop: () => void;
    // scan image
    scanImage: (imageOrFileOrUrl: ImageSource) => Promise<string>;
    // flash
    hasFlash: boolean;
    toggleFlash: () => void;
    isFlashOn: boolean;
    // camera
    hasManyCameras: boolean;
    toggleCamera: () => void;
}

export function useQrScanner(
    videoElement: MutableRefObject<HTMLVideoElement>,
    onQrCodeFound: (code: string) => void,
    onNoCodeFound: () => void,
    onError?: (error: string) => void,
): UseQrScanner {
    const [currentCamera, setCurrentCamera] = useState(0);
    const [list, setList] = useState([]);
    const [hasFlash, setHasFlash] = useState(false);
    const [flash, setFlash] = useState(false);
    const [ready, setReady] = useState(false);

    const lastScannedCode = useRef<string>('');
    const qrScanner = useRef<QrScanner>();

    const initHandler = useCallback(() => {
        if (!videoElement.current) return;

        qrScanner.current = init();
    }, [videoElement, onQrCodeFound, onNoCodeFound]);
    const start = useCallback(() => {
        if (ready) {
            return;
        }

        if (!qrScanner.current) {
            initHandler();
        }

        qrScanner.current?.start().then(
            () => setReady(true),
            (error) => onError?.(error),
        );
    }, [ready, qrScanner]);
    const pause = useCallback(() => qrScanner.current?.pause(), [qrScanner]);
    const scanImage = useCallback(
        (imageOrFileOrUrl: ImageSource) =>
            pause()
                .then(
                    () =>
                        new Promise<string>((resolve, reject) => {
                            if (!imageOrFileOrUrl) {
                                reject();
                            }

                            return QrScanner.scanImage(imageOrFileOrUrl).then(resolve, () => reject());
                        }),
                )
                .finally(() => start()),
        [qrScanner],
    );

    useEffect(() => () => qrScanner.current?.destroy(), [qrScanner, videoElement, onQrCodeFound, onNoCodeFound]);

    return {
        ready,
        // scanner
        scanImage,
        start,
        pause,
        stop: () => void 0,
        // flash
        toggleFlash,
        isFlashOn: qrScanner.current?.isFlashOn() || false,
        hasFlash,
        // camera
        hasManyCameras: list.length > 1,
        toggleCamera,
    };

    function toggleFlash() {
        setFlash(!flash);
        qrScanner.current.toggleFlash().then(() => setFlash(qrScanner.current?.isFlashOn()));
    }

    function toggleCamera() {
        let next = currentCamera + 1;

        if (next >= list.length) {
            next = 0;
        }

        qrScanner.current.setCamera(list[next].id);
        setCurrentCamera(next);
    }

    function init() {
        const scanner = new QrScanner(
            videoElement.current,
            ({ data }) => {
                if (lastScannedCode.current !== data) {
                    lastScannedCode.current = data;
                    onQrCodeFound(data);
                }
            },
            {
                onDecodeError: (error) => {
                    if (lastScannedCode.current) {
                        onNoCodeFound();
                        lastScannedCode.current = '';
                    }
                },
                preferredCamera: 'environment',
                maxScansPerSecond: 10,
                highlightScanRegion: true,
                highlightCodeOutline: true,
            },
        );

        scanner.hasFlash().then(setHasFlash);

        QrScanner.listCameras(true).then(setList);

        return scanner;
    }
}
