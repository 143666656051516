import React from 'react';

import { useAtom } from '@flatom/react';

import { CategoriesAtom } from 'src/categories/model/categories.atom';
import { Header } from 'src/components/Header';
import { MonthDate } from 'src/domain/date.types';
import { TransactionGrip } from 'src/models/abstract-grip/transaction-grip.class';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';
import { TxList } from 'src/widgets/TxList';

import styles from './TransactionsPage.module.scss';

interface IProps {
    month: MonthDate;
    transactions: TransactionGrip[];
}

export const TransactionsPage = ({ month, transactions }: IProps) => {
    transactions = transactions || [];
    const { map: categories } = useAtom(CategoriesAtom);

    return (
        <div className={styles.page}>
            <Header title={`Transactions of ${month}`} />
            <Main>
                <div className={styles.filter}>
                    <button className={styles.active}>Все</button>
                    <button>Траты</button>
                    <button>Приход</button>
                </div>
                <TxList
                    list={transactions}
                    categories={categories}
                />
            </Main>
            <NavBar />
        </div>
    );
};
