import React from 'react';

import { Form } from 'react-final-form';

import { ICategory, ICategoryForm } from 'src/categories/types';
import { SelectField } from 'src/components/inputs/SelectField';
import { TextField } from 'src/components/inputs/TextField';
import { TxTypeField } from 'src/components/inputs/TxTypeField';
import { TransactionType } from 'src/domain/transaction.types';

interface IProps {
    value: ICategoryForm;
    categories: ICategory[];
    onSubmit: (value: ICategoryForm) => void;
    onCancel?: () => void;
    validate: (value: ICategoryForm) => Partial<{ [key in keyof ICategoryForm]: string }>;
}

const categoryIcons = [
    'default',
    'essentials-01',
    'essentials-02',
    'essentials-03',
    'essentials-04',
    'essentials-05',
    'essentials-06',
    'essentials-07',
    'essentials-08',
    'essentials-09',
];

export const CategoryForm = ({ onSubmit, value, validate, categories }: IProps) => {
    const icons = categoryIcons.map((item) => ({ value: item, label: item }));
    const parents = categories
        .filter((item) => !item.parent)
        .filter((item) => item.id !== value.id)
        .map((item) => ({ value: item.id, label: item.name }));

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={value}
            validate={validate}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        name="name"
                        label="Название"
                        placeholder="Название категории"
                    />
                    <TxTypeField
                        name="defaultTxType"
                        label=""
                        types={[TransactionType.Income, TransactionType.Expense]}
                    />
                    <SelectField
                        name="icon"
                        label="Иконка"
                        options={icons}
                    />
                    <SelectField
                        name="parent"
                        label="Категория"
                        options={parents}
                    />
                    <button
                        type="submit"
                        className="btn btn-primary rounded mr-1"
                    >
                        Сохранить
                    </button>
                </form>
            )}
        />
    );
};
