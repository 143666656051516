import React from 'react';

import { CategoryMap } from 'src/categories/types';
import { IMonthGrip } from 'src/models/abstract-grip/grip.types';
import { formatDateDayMonth } from 'src/models/dates';
import { Section } from 'src/ui-kit/Section';

import { TxList } from './TxList';

interface IProps {
    month: IMonthGrip;
    reverse?: boolean;
    categories: CategoryMap;
}

export const MonthTxList = React.memo(({ month, reverse, categories }: IProps) => {
    if (!month) {
        return <div>No data</div>;
    }

    const days = [...month.days];

    if (reverse) days.reverse();

    return (
        <>
            {days.map((day) => (
                <Section
                    key={day.date}
                    full
                    title={formatDateDayMonth(day.date)}
                    id={'day-' + day.date}
                >
                    <TxList
                        list={day.transactions}
                        reverse={reverse}
                        categories={categories}
                    />
                </Section>
            ))}
        </>
    );
});
