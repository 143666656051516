import React, { ChangeEvent, ReactNode, useEffect, useRef } from 'react';

import block from 'bem-css-modules';
import { useHistory } from 'react-router';

import { useQrScanner } from 'src/hooks/qrScanner';
import { Icon } from 'src/ui-kit/Icon';
import { Icons } from 'src/ui-kit/Icons';

import styles from './QrScanner.module.scss';

interface IProps {
    onSuccess?: (code: string) => void;
    onSearch?: () => void;
    onFailed?: () => void;
    onNoCodeFound?: () => void;
    children?: ReactNode;
}

const b = block(styles, 'qr-scanner');

export const QrScanner = ({ onSuccess, onSearch, onNoCodeFound, onFailed, children }: IProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const qrScanner = useQrScanner(videoRef, onSuccess, onSearch, () => onFailed());
    const history = useHistory();

    useEffect(() => {
        qrScanner.start();

        return () => qrScanner.stop();
    }, [qrScanner]);

    const exitHandler = () => history.goBack();

    const uploadHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];

        if (file) {
            qrScanner.scanImage(file).then(
                (value) => onSuccess(value),
                () => onNoCodeFound(),
            );
        }
    };

    const SelectImageBtn = (
        <label className={b('button')}>
            <Icon
                icon={Icons.image}
                color="--light-gray"
                size="32px"
                title="Загрузить из файла"
            />
            <input
                type="file"
                onChange={uploadHandler}
                data-qa-type="file-input"
                style={{ display: 'none' }}
            />
        </label>
    );
    const ExitBtn = (
        <div
            className={b('button')}
            onClick={exitHandler}
        >
            <Icon
                icon={Icons.close}
                color="--light-gray"
                size="32px"
            />
        </div>
    );
    const ToggleFlash = (
        <div
            className={b('button')}
            onClick={qrScanner.toggleFlash}
        >
            <Icon
                icon={Icons.flashlight}
                color={qrScanner.isFlashOn ? '--white' : '--light-gray'}
                size="32px"
            />
        </div>
    );
    const ToggleCamera = (
        <div
            className={b('button')}
            onClick={qrScanner.toggleCamera}
        >
            <Icon
                icon={Icons.cameraReverse}
                color="--light-gray"
                size="32px"
            />
        </div>
    );

    return (
        <div className={b()}>
            <div className={b('video-container')}>
                <video
                    ref={videoRef}
                    className={b('video')}
                />
            </div>
            <div className={b('hud')}>
                <div className={b('button-row')}>
                    {SelectImageBtn}
                    <div className={b('delimiter')} />
                    {ExitBtn}
                </div>
                <div className={b('center-space-row')} />
                <div className={b('message')}>{children}</div>
                <div className={b('bottom-space-row')} />
                <div className={b('button-row')}>
                    {qrScanner.hasFlash && ToggleFlash}
                    <div className={b('delimiter')} />
                    {qrScanner.hasManyCameras && ToggleCamera}
                </div>
            </div>
        </div>
    );
};
