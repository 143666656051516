import { ReactNode, useState } from 'react';

export const Showable = ({ show, children }: { show: boolean; children: ReactNode }) => {
    const [content, setContent] = useState<ReactNode>(null);
    const [showed, setShowed] = useState(false);

    if (showed === show) {
        return <>{children}</>;
    }

    if (!showed) {
        setContent(children);
        setShowed(show);
    }

    if (showed) {
        setTimeout(() => {
            setContent('');
            setShowed(show);
        }, 500);
    }

    return <>{content}</>;
};
