import { declareAtom } from '@flatom/core';

import { BackupStatus, IBackupState } from './backup.types';

export const Backup = declareAtom<IBackupState>(['backup'], {
    status: BackupStatus.None,
    fileContent: '',
    fileName: '',
    fileSize: 0,
})({
    runBackup: (_, date: string) => ({
        status: BackupStatus.Creating,
        fileContent: '',
        fileName: `4wallet-backup-${date}.4wa`,
        fileSize: 0,
    }),
    backupReady: (state, data: { content: string; size: number }) => ({
        ...state,
        status: BackupStatus.Ready,
        fileContent: data.content,
        fileSize: data.size,
    }),
    backupFailed: (state, _: void) => ({
        ...state,
        status: BackupStatus.Error,
        fileContent: '',
    }),
    loaded: (state, _: void) => ({
        status: BackupStatus.None,
        fileContent: '',
        fileName: '',
        fileSize: 0,
    }),
});
