export enum TransactionType {
    Removed,
    Income,
    Expense,
    Adjust,
    AdjustTo,
    Rebate,
}

export const transactionTypeNameRu: { [key in TransactionType]: string } = {
    [TransactionType.Removed]: 'удалено',
    [TransactionType.Income]: 'Пополнение',
    [TransactionType.Expense]: 'Расход',
    [TransactionType.Adjust]: 'Корректировка',
    [TransactionType.AdjustTo]: 'Корректировка',
    [TransactionType.Rebate]: 'Возврат',
};
