import React from 'react';

import { MoneyView } from 'src/components/MoneyView';
import { IGrip } from 'src/models/abstract-grip/grip.types';
import { formatDateMonthYear } from 'src/models/dates';
import { Link, paths } from 'src/paths';

interface IProps {
    grip: IGrip;
}

export const LastMonthsList = ({ grip }: IProps) => {
    if (!grip)
        return (
            <div
                className="alert alert-outline-dark mt-2"
                role="alert"
            >
                No data
            </div>
        );

    const months = grip.months.slice(0, 6);

    if (!months.length) return null;

    return (
        <ul
            className="listview link-listview inset mt-2"
            style={{
                margin: '0',
            }}
        >
            {months.map((month) => (
                <li key={month.month}>
                    <Link
                        to={paths.account.month(grip.id, month.month)}
                        className="item"
                    >
                        <span className="capitalize">{formatDateMonthYear(month.month)}</span>
                        <span className="text">
                            <MoneyView
                                money={month.balance}
                                sign
                            />
                        </span>
                    </Link>
                </li>
            ))}
        </ul>
    );
};
