import React from 'react';

import { useAction, useAtom } from '@flatom/react';
import block from 'bem-css-modules';
import { useHistory } from 'react-router';

import { noCameras, noCodeFound } from 'src/bills/model';
import { Header } from 'src/components/Header';
import { Main } from 'src/ui-kit/Main';
import { NavBar } from 'src/widgets/NavBar';
import { QrScanner } from 'src/widgets/QrScanner';

import { processQrCode, ScanQrPageAtom } from './ScanQrPage.atom';
import styles from './ScanQrPage.module.scss';

const b = block(styles, 'scan-qr-page');

export const TransactionScanQrPage = () => {
    useAtom(ScanQrPageAtom);
    const history = useHistory();

    const processQrCodeHandler = useAction(processQrCode);
    const noCodeFoundHandler = useAction(ScanQrPageAtom.a.noCode);
    const noCodeFoundOnImageHandler = useAction(noCodeFound);
    const noCamerasActionHandler = useAction(noCameras);

    const noCamerasHandler = () => {
        noCamerasActionHandler();
        history.goBack();
    };

    return (
        <>
            <Header
                title="Сканирование чека"
                back
            />
            <Main fullWidth>
                <div className={b()}>
                    <div className={b('scanner')}>
                        <QrScanner
                            onSuccess={processQrCodeHandler}
                            onSearch={noCodeFoundHandler}
                            onFailed={noCamerasHandler}
                            onNoCodeFound={noCodeFoundOnImageHandler}
                        />
                    </div>
                </div>
            </Main>
            <NavBar active="new-tx" />
        </>
    );
};
