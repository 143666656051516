import { SelfPackableClass } from 'src/libs/packable/decorator';
import { Packer } from 'src/libs/packable/packable';

import { CurrencyMismatchError } from './errors';
import { ICurrency } from './types';

const DEFAULT_CURRENCY = 'RUB';

export const currencies: { [key: string]: ICurrency } = {
    RUB: { code: 'RUB', symbol: '₽', precision: 2 },
    USD: { code: 'USD', symbol: '$', precision: 2 },
    BTC: { code: 'BTC', symbol: '₿', precision: 8 },
};

@SelfPackableClass((target) => new Packer(target.fromJSON, target.toJSON))
export class Money {
    public static empty: Money;

    public readonly amount: string = '';

    private constructor(public readonly subunits: number | null, public readonly currency: ICurrency) {
        if (Number.isFinite(subunits)) {
            this.amount = (subunits / 10 ** currency.precision).toFixed(currency.precision);
        } else {
            this.amount = '';
            this.subunits = null;
        }
    }

    public static isEmpty(value: Money | undefined | null): boolean {
        return !value || value.subunits === null;
    }

    public static from(value: Money | string | null): Money {
        if (!value) {
            return Money.empty;
        }

        if (typeof value === 'string') {
            return Money.fromJSON(value);
        }

        if (value instanceof Money) {
            return value.clone();
        }

        throw new Error('Can not convert invalid data to Money');
    }

    public static fromJSON(data: any): Money {
        if (!data) {
            return Money.empty;
        }

        if (
            typeof data === 'object' &&
            typeof data.subunits === 'number' &&
            typeof data.currency === 'object' &&
            typeof data.currency.code === 'string'
        )
            return new Money(data.subunits, data.currency);

        if (typeof data !== 'string') throw new Error(`Amount "${data}" must be a string`);

        const parts = data.trim().split(' ');

        if (!parts || parts.length !== 2) throw new Error(`Money "${data}" must have valid format`);

        const amountText = parts[0];
        const currencyName = parts[1].toUpperCase();

        const currency = currencies[currencyName];

        if (!currency) throw new Error(`Unsupported currency "${currencyName}"`);

        const units = +amountText;

        if (Number.isNaN(units)) throw new Error(`Invalid amount "${amountText}" when parse "${data}"`);

        const subunits = Math.round(units * 10 ** currency.precision);

        return new Money(subunits, currency);
    }

    public static toJSON(money: Money): any {
        return money.toJSON();
    }

    public static create(amount: string | number | null, currencyName: string): Money {
        currencyName = currencyName.toUpperCase();

        const currency = currencies[currencyName];

        if (!currency) throw new Error(`Unsupported currency "${currencyName}"`);

        if (amount === null || amount === '') {
            return new Money(null, currency);
        }

        if (typeof amount === 'string') {
            if (!amount || Number.isNaN(+amount))
                throw new Error(`Amount must be valid number but received "${amount}"`);

            amount = +amount;
        }

        const units = +amount;

        if (Number.isNaN(units)) throw new Error(`Invalid amount "${amount}"`);

        const subunits = Math.round(units * 10 ** currency.precision);

        return new Money(subunits, currency);
    }

    public clone(): Money {
        return new Money(this.subunits, this.currency);
    }

    public toString() {
        if (this.subunits) {
            return this.getEntire() + '.' + this.getFractional() + ' ' + this.currency.symbol;
        }

        if (this.subunits === null) {
            return '';
        }

        return '0 ' + this.currency.symbol;
    }

    public toJSON() {
        if (this.subunits) {
            return this.amount + ' ' + this.currency.code;
        }

        if (this.subunits === null) {
            return '';
        }

        return '0 ' + this.currency.code;
    }

    public get isEmpty(): boolean {
        return this.subunits === null;
    }

    public add(money: Money): Money {
        this.checkCurrencyCompatibility(money);

        return new Money(this.subunits + money.subunits, this.currency);
    }

    public sub(money: Money): Money {
        this.checkCurrencyCompatibility(money);

        return new Money(this.subunits - money.subunits, this.currency);
    }

    public equal(money: Money): boolean {
        if (this.currency.code !== money.currency.code) return false;

        return this.subunits === money.subunits;
    }

    public isMoreThen(money: Money): boolean {
        this.checkCurrencyCompatibility(money);

        return this.subunits > money.subunits;
    }

    public isLessThen(money: Money): boolean {
        this.checkCurrencyCompatibility(money);

        return this.subunits < money.subunits;
    }

    public getEntire(withSign = false): string {
        if (this.isEmpty) {
            return '';
        }

        const isNegative = this.subunits < 0;
        let str = Math.floor(Math.abs(this.subunits / 10 ** this.currency.precision)).toString();

        const len = str.length;

        for (let i = len - Math.floor(len / 3) * 3; i > 0 && i < len; i += 4) {
            str = str.substr(0, i) + ' ' + str.substr(i);
        }

        if (isNegative) return '-' + str;

        return withSign ? '+' + str : str;
    }

    public getFractional(): string {
        if (this.isEmpty) {
            return '';
        }

        return (this.subunits / 10 ** this.currency.precision)
            .toFixed(this.currency.precision)
            .substr(-this.currency.precision);
    }

    public getFractionalNumber(): number {
        if (this.isEmpty) {
            return 0;
        }

        const precision = 10 ** this.currency.precision;
        const entire = Math.floor(Math.abs(this.subunits / precision)) * precision;

        return this.subunits - entire;
    }

    public hasFractional(): boolean {
        return !!this.getFractionalNumber();
    }

    public getSymbol(): string {
        if (this.isEmpty) {
            return '';
        }

        return this.currency.symbol;
    }

    public negative(): Money {
        // TODO rename
        return new Money(-this.subunits, this.currency);
    }

    public isPositive() {
        return this.subunits > 0;
    }

    public isNegative() {
        return this.subunits < 0;
    }

    private checkCurrencyCompatibility(money: Money): void {
        if (this.currency.code !== money.currency.code) {
            throw new CurrencyMismatchError(this.currency.code, money.currency.code);
        }
    }
}

Money.empty = Money.create(null, DEFAULT_CURRENCY);
