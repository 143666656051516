import { declareAtom } from '@flatom/core';
import { Map } from 'immutable';
import { assoc } from 'sprout-data';

import { AccountGrips } from 'src/atoms/account-grips/account-grips.atom';
import { CardListAtom } from 'src/cards/model/card.atom';
import { CardID, ICard } from 'src/cards/model/card.types';
import { AccountGrip } from 'src/models/account-grip/grip.class';
import { UUID } from 'src/models/common/common.types';

export interface IViewCardPageState {
    props: {
        account?: string;
    };
    loading: boolean;
    cardID: CardID;
    card?: ICard;
    cards: ICard[];
    grip?: AccountGrip;
    grips: Map<UUID, AccountGrip>;
}

export const ViewCardPageAtom = declareAtom<IViewCardPageState>('cards/pages/view', {
    props: {},
    loading: true,
    cardID: undefined,
    card: undefined,
    cards: [],
    grip: undefined,
    grips: Map(),
})(
    {
        init(state, payload: { account: string }) {
            state = assoc(state, 'props', payload);
            state = assoc(state, 'cardID', state.props.account as CardID);

            return calculate(state);
        },
        propsChange(state, payload: { account: string }) {
            state = assoc(state, 'props', payload);
            state = assoc(state, 'cardID', state.props.account as CardID);

            return calculate(state);
        },
        destroy(state, _: void) {
            return state;
        },
    },
    (on) => [
        on(CardListAtom, (state, cards) => {
            state = assoc(state, 'cards', cards);

            return calculate(state);
        }),
        on(AccountGrips, (state, grips) => {
            state = assoc(state, 'grips', grips.accounts);

            return calculate(state);
        }),
    ],
);

function calculate(state: IViewCardPageState): IViewCardPageState {
    const cardID = state.cardID;

    state = assoc(state, 'grip', state.grips.get(cardID));
    state = assoc(
        state,
        'card',
        state.cards.find((item) => item.id === cardID),
    );

    return assoc(state, 'loading', !state.card);
}
