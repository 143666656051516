import { declareAtom } from '@flatom/core';

import { IStorageEstimate, IStorageState } from './storage.types';

export const Storage = declareAtom<IStorageState>(
    ['storage'],
    {},
)({
    setEstimate(state, { quota, usage, usageDetails }: IStorageEstimate) {
        state = {
            quota: quota,
            totalUsage: usage,
            appSize: usageDetails?.caches,
            dataSize: usageDetails?.indexedDB,
        };

        return state;
    },
});
